import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";

import { Data, Toolbar, SingleSelectFilter } from "react-data-grid-addons";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import numeral from "numeral";

// material-ui components
import { selectDataByType, onLoadData, onSave, onAction, onReloadData } from "redux/reducers/data";

import TextFilter from "./filters/TextFilter";
import OwnerFilter from "./filters/OwnerFilter";

import NumericFilter from "./filters/NumericFilter";
import DateTimeFilter from "./filters/DateTimeFilter";
import DateFilter from "./filters/DateFilter";
import BooleanFilter from "./filters/BooleanFilter";

import SelectFilter from "./filters/SelectFilter";
import CsvSelectFilter from "./filters/CsvSelectFilter";

import { onFilter, selectFilterByKey, changeGroup, selectGroups } from "redux/reducers/table";
import List from "@material-ui/icons/List";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ChildFormatter from "./formatters/ChildFormatter";
import LinkFormatter from "./formatters/LinkFormatter";
import EditFormatter from "./formatters/EditFormatter";
import FileFormatter from "./formatters/FileFormatter";
import DateTimeFormatter from "./formatters/DateTimeFormatter";
import FileListFormatter from "./formatters/FileListFormatter";
import BooleanFormatter from "./formatters/BooleanFormatter";
import ConsecutivoFormatter from "./formatters/ConsecutivoFormatter";
import ClaveFormatter from "./formatters/ClaveFormatter";
import OwnerFormatter from "./formatters/OwnerFormatter";
import SelectFormatter from "./formatters/SelectFormatter";
import TextFormatter from "./formatters/TextFormatter";
import IdFormatter from "./formatters/IdFormatter";
import CsvSelectFormatter from "./formatters/CsvSelectFormatter";
import PercentFormatter from "./formatters/PercentFormatter";

import NumberFormatter from "./formatters/NumberFormatter";
import moment from "moment";

import lightGreen from "@material-ui/core/colors/indigo";
import Badge from "@material-ui/core/Badge";

import EmptyRowsView from "./Empty";
import AutoCompleteFormatter from "./formatters/AutoCompleteFormatter";
import RowRenderer from "./TableRowRenderer";

var EditorBase = ReactDataGrid.editors.EditorBase;

const filters = {
  TextFilter: TextFilter,
  OwnerFilter: OwnerFilter,
  NumericFilter: NumericFilter,
  DateFilter: DateFilter,
  DateTimeFilter: DateTimeFilter,
  SelectFilter: SelectFilter,
  BooleanFilter: BooleanFilter,
  CsvSelectFilter: CsvSelectFilter
};

const _formatters = {
  AutoCompleteFormatter: AutoCompleteFormatter,
  ChildFormatter: ChildFormatter,
  FileFormatter: FileFormatter,
  BooleanFormatter: BooleanFormatter,
  NumberFormatter: NumberFormatter,
  DateTimeFormatter: DateTimeFormatter,
  ConsecutivoFormatter: ConsecutivoFormatter,
  ClaveFormatter: ClaveFormatter,
  LinkFormatter: LinkFormatter,
  EditFormatter: EditFormatter,
  FileListFormatter: FileListFormatter,
  SelectFormatter: SelectFormatter,
  TextFormatter: TextFormatter,
  IdFormatter: IdFormatter,
  OwnerFormatter: OwnerFormatter,
  CsvSelectFormatter: CsvSelectFormatter,
  PercentFormatter: PercentFormatter
};

const editors = {};

const styles = {
  ...tooltip,

  selectFormControl: {
    marginTop: 6
  }
};

const mapStateToProps = (store, props) => {
  return {};
};

class SmartTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexes: [],
      filters: {},
      columns: [],
      vista: {},
      expandedRows: [],
      rows: [],
      groupBy: null,
      width: 0
    };
  }

  get formatters() {
    return { ..._formatters, ...(this.props.customFormatters || {}) };
  }

  componentDidMount() {
    this.setState({
      rows: this.props.dataRows ? this.props.dataRows.slice(0) : []
    });
    this.refs.grid.onToggleFilter();
    this.setGroup(this.props);
    let { metadata } = this.props;
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;
    this.setState({ width: x });
    const columns = this.props.metadata.table.fields.map((key, index) => {
      var column = {
        ...metadata.properties[key],
        name: metadata.properties[key].title,
        key: key
      };
      column.metadataType = this.props.metadataType;

      if (column.element == "autocomplete") column.formatterName = "AutoCompleteFormatter";
      if (column.element == "select") column.formatterName = "SelectFormatter";
      if (column.type == "number") column.formatterName = "NumberFormatter";
      if (column.type == "boolean") column.formatterName = "BooleanFormatter";
      if (column.key == "createdAt") column.formatterName = "DateTimeFormatter";

      if (index == 0) column.formatterName = "EditFormatter";
      else if (key == "consecutivo") column.formatterName = "ConsecutivoFormatter";
      else if (key == "clave") column.formatterName = "ClaveFormatter";
      else if (key == "ownerId") column.formatterName = "OwnerFormatter";

      if (column.key == "id") column.formatterName = "IdFormatter";

      if (column.formatter || column.formatterName) {
        var formatter = this.formatters[column.formatter] || this.formatters[column.formatterName] || null;
        if (formatter)
          column.formatter = formatter(
            column,
            this.reload,
            this.getRowAt,
            this.handleGridRowsUpdated,
            this.props
          );
      }

      if (column.filterable) {
        var defaultFilter = TextFilter;
        if (column.key == "ownerId") defaultFilter = OwnerFilter;
        if (column.key == "createdAt") defaultFilter = DateTimeFilter;
        if (column.type == "boolean") defaultFilter = BooleanFilter;
        if (column.element == "select") defaultFilter = SelectFilter;

        var filter = filters[column.filterRenderer] || defaultFilter;
        column.filterRenderer = filter(this.props.metadataType);
      }

      if (column.editor) {
        //var Editor = editors[column.editor];
        column.editor = <SimpleTextEditor column={column} />;
      }

      if (!column.width) column.width = 100;
      if (!column.formatter == "TextFormatter") column.width = 200;
      if (x < 1030 && column.key != "id") column.locked = false;
      if (column.key == "id") column.width = 60;

      column.events = {
        onClick: (ev, args) => {
          if (args.column.preventSelect) return;
          if (this.state.selectedIndexes.indexOf(args.rowIdx) == -1)
            this.onRowsSelected([{ rowIdx: args.rowIdx }]);
          else this.onRowsDeselected([{ rowIdx: args.rowIdx }]);
        }
      };

      return column;
    });

    this.setState({ columns });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.dataRows ? nextProps.dataRows.slice(0) : [],
      selectedIndexes: nextProps.selectedIndexes ? nextProps.selectedIndexes : []
    });
    this.setGroup(nextProps);
    if (this.state.selectedIndexes.length > 0 && nextProps.selectedIndexes && nextProps.selectedIndexes.length == 0)
      this.clearSelectedIndexes();
  }

  reload = () => {
    this.props.dispatch(onReloadData(this.props.metadataType));
  };

  setGroup(props) {
    let groupBy = [];
    if (!props.groups) return;
    props.groups.forEach(groupKey => {
      var columnKey = groupKey;

      if (columnKey.indexOf("__") == 0) columnKey = columnKey.replace("__", "");
      var column = props.metadata.properties[columnKey];
      if (column.element && column.element == "autocomplete") groupKey = "__" + groupKey;
      groupBy.push({ key: groupKey, name: column.title || column.key });
    });
    groupBy = !props.selectedAllItems ? groupBy : [];
    this.setState({ groupBy: groupBy });
  }

  getRows = () => {
    let rows = Data.Selectors.getRows(this.state);
    return rows;
  };

  getRowAt = index => {
    let rows = this.getRows();
    return rows[index];
  };

  getSize = () => {
    return this.getRows().length;
  };

  rowGetter = i => {
    return this.getRowAt(i);
  };

  onRowsSelected = rows => {
    var newRows = this.state.selectedIndexes.concat(rows.map(r => r.rowIdx));
    this.setState({ selectedIndexes: newRows });
    this.props.updateSelectedRows(newRows.map(rowIndex => this.rowGetter(rowIndex)));
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    var newRows = this.state.selectedIndexes.filter(i => rowIndexes.indexOf(i) === -1);
    this.setState({ selectedIndexes: newRows });
    this.props.updateSelectedRows(newRows.map(rowIndex => this.rowGetter(rowIndex)));
  };

  handleGridSort = (sortColumn, sortDirection) => {
    const compareNull = (a, b) => {
      if (a[sortColumn] == null || a[sortColumn].length == 0) return 1;
      if (b[sortColumn] == null || b[sortColumn].length == 0) return -1;
      if (a[sortColumn] == b[sortColumn]) return 0;
      return null;
    };

    const textComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    const numberComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return parseFloat(a[sortColumn]) > parseFloat(b[sortColumn]) ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return parseFloat(a[sortColumn]) < parseFloat(b[sortColumn]) ? 1 : -1;
      }
    };

    const dateComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return moment(a[sortColumn]).isAfter(moment(b[sortColumn])) ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return moment(a[sortColumn]).isBefore(moment(b[sortColumn])) ? 1 : -1;
      }
    };

    var comparer = textComparer;
    var column = this.props.metadata.properties[sortColumn.replace("__", "")];

    if (column.element == "autocomplete") comparer = textComparer;
    else if (column.type == "number") comparer = numberComparer;
    else if (column.type == "integer") comparer = numberComparer;
    else if (column.filterRenderer == "DateFilter") comparer = dateComparer;

    const rows = sortDirection === "NONE" ? this.props.dataRows.slice(0) : this.props.dataRows.sort(comparer);
    this.setState({ rows });
  };

  groupBy = column => {
    this.props.dispatch(changeGroup(this.props.metadataType, column.key));
  };

  handleFilterChange = filter => {
    this.props.updateSelectedRows([]);
    this.props.clearSelectedIndexes();

    if (filter.value == "GROUP") return this.groupBy(filter.column);

    var { vista } = this.state;
    this.props.dispatch(onFilter(this.props.metadataType, filter));
  };

  dispatchFilter = filters => {
    this.props.dispatch(
      onLoadData(
        this.props.metadataType,
        Object.keys(filters).map(field => {
          return filters[field];
        })
      )
    );
  };

  getValidFilterValues = columnId => {
    let values = this.state.rows.map(r => r[columnId]);
    return values.filter((item, i, a) => {
      return i === a.indexOf(item);
    });
  };

  handleGridRowsUpdated = e => {
    var column = this.props.metadata.properties[e.cellKey];
    var value = e.updated[e.cellKey];
    if (column.type == "number") value = parseFloat(value);
    else if (column.type == "integer") value = parseInt(value);

    var payload = { id: e.fromRowId, [e.cellKey]: value };
    this.props.dispatch(onSave(this.props.metadataType, payload)).catch(e => {
      console.log(e);
    });
  };

  clearSelectedIndexes = () => {
    this.setState({ selectedIndexes: [] });
  };

  onApprove = e => {
    var ids = this.state.selectedIndexes.map(idx => {
      return this.rowGetter(idx).id;
    });
    this.props.dispatch(onAction(this.props.metadataType, "approve", ids));
  };

  onRowExpandToggle = ({ columnGroupName, name, shouldExpand }) => {
    let expandedRows = Object.assign({}, this.state.expandedRows);
    expandedRows[columnGroupName] = Object.assign({}, expandedRows[columnGroupName]);
    expandedRows[columnGroupName][name] = { isExpanded: shouldExpand };
    this.setState({ expandedRows: expandedRows, selectedIndexes: [] });
  };

  render() {
    var metadata = this.props.metadata;

    if (!metadata || !metadata.properties) return null;

    const { classes } = this.props;
    var rowCount = this.getSize();

    return (
      <div>
        <div style={{ width: this.props.width || "calc(100%)" }}>
          <ReactDataGrid
            ref="grid"
            headerRowHeight={28}
            headerFiltersHeight={28}
            rowHeight={this.state.width > 1030 ? 25 : 45}
            onShowModal={this.props.onShowModal}
            enableCellSelect={true}
            columns={this.state.columns || []}
            rowGetter={this.getRowAt}
            rowsCount={this.getSize()}
            minHeight={this.props.height}
            getCellActions={this.props.getCellActions}
            onAddFilter={this.handleFilterChange}
            onClearFilters={this.onClearFilters}
            onGridSort={this.handleGridSort}
            emptyRowsView={EmptyRowsView}
            onRowExpandToggle={this.onRowExpandToggle}
            toolbar={<EmptyToolbar groupBy={this.state.groupBy} enableFilter={true} />}
            getValidFilterValues={this.getValidFilterValues}
            rowGroupRenderer={RowRenderer(this.props.dataRows, this.props.metadata, this.props.width)}
            rowSelection={{
              showCheckbox: false,
              enableShiftSelect: true,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                indexes: this.state.selectedIndexes
              }
            }}
            onGridRowsUpdated={this.handleGridRowsUpdated}
          />
        </div>
      </div>
    );
  }
}

class EmptyToolbar extends React.Component {
  componentDidMount() {
    this.props.onToggleFilter();
  }

  render() {
    return <div />;
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartTable));

class SimpleTextEditor extends EditorBase {
  onChange = e => {
    this.props.onBlur();
  };

  getValue() {
    let updated = {};
    var value = this.getInputNode().value;
    if (this.props.column.type == "boolean") value = value == 1;
    updated[this.props.column.key] = value;
    return updated;
  }

  render() {
    return (
      <input
        ref={node => (this.input = node)}
        type="date"
        onBlur={this.props.onBlur}
        className="form-control"
        defaultValue={this.props.value}
        onChange={this.onChange}
      />
    );
  }
}
