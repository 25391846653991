import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import Dinero from "dinero.js";

import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleTable from "components/Table/Table.jsx";
import Table from "views/base/table";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit } from "redux/reducers/table";

Dinero.globalLocale = "es-CR";
Dinero.defaultPrecision = 5;

const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  bold: {
    fontWeight: 800
  },
  textField: {
    fontSize: 11,
    minWidth: "50vw",
    marginBottom: 10
  }
});

class AccountImportModal extends React.Component {
  state = {
    view: "PASTE"
  };

  onImportarClick = e => {
    this.props
      .dispatch(
        onAction("account", "importFromPaste", {
          lines: this.state.lines
        })
      )
      .then(res => {
        this.props.onCloseModal();
      })
      .catch(res => {
        this.props.onCloseModal();
      });
  };

  handleReferenciaChange = e => {
    this.setState({ devolucionReferencia: e.currentTarget.value });
  };

  handleBoletaChange = e => {
    this.setState({ devolucionBoleta: e.currentTarget.value });
  };

  handleCantidadChange = e => {
    var value = e.currentTarget.value;

    //Split all the text into seperate lines on new lines and carriage return feeds
    var allTextLines = value.split(/\r\n|\n/);
    //Split per line on tabs and commas
    var headers = ["code", "name", "parentCode","balance"];
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(/\t/);

      if (data.length >= headers.length) {
        var row = {};
        for (var j = 0; j < headers.length; j++) {
          row[headers[j]] = data[j];
        }
        lines.push(row);
      }
    }
    this.setState({ view: "CONFIRM", cuentas: lines.length, lines: lines });
  };

  renderPaste() {
    if (this.state.view != "PASTE") return null;

    const { classes } = this.props;

    return (
      <div>
        <DialogContentText>Importar ( Pegue aqui las filas de excel) </DialogContentText>

        <TextField
          className={classes.textField}
          autoFocus
          multiline
          fullWidth
          onChange={this.handleCantidadChange}
          rows={10}
          label="Pegar filas aqui"
          type="text"
        />
      </div>
    );
  }

  renderConfirm() {
    if (this.state.view != "CONFIRM") return null;
    const { classes } = this.props;

    return (
      <div>
        <DialogContentText>Confirmar </DialogContentText>

        <TextField
          className={classes.textField}
          autoFocus
          fullWidth
          value={this.state.cuentas}
          label="Total de cuentas"
          type="text"
        />
        <DialogActions>
          <Button onClick={this.props.onCloseModal.bind(this)} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.onImportarClick.bind(this)} color="primary">
            Importar
          </Button>
        </DialogActions>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.renderConfirm()}
        {this.renderPaste()}
      </div>
    );
  }
}

export default withStyles(allStyles, { withTheme: true })(AccountImportModal);
