import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onReloadData, onSave, onAction } from "redux/reducers/data";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { changeLimit, selectLimit, selectSingleGroup } from "redux/reducers/table";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import numeral from "numeral";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import { simpleApi } from "redux/reducers/api";
import { alert } from "redux/reducers/header";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Pivot from "components/Pivot";

import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 300
    }
  }
};
export const styles = theme => ({
  reportHeader: {
    borderBottom: "2px solid #333",
    marginBottom: 12
  },
  root: {
    backgroundColor: "#fff"
  },
  main: {
    margin: theme.spacing(5),
    marginTop: 0,
    marginBottom: 0
  },
  toolbar: {
    margin: theme.spacing(4),
    backgroundColor: "#fff",
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing(1)
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  table: {
    marginTop: 30
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogRoot: {
    marginTop: 100
  },
  headerCell: {
    backgroundColor: "#333"
  },
  dialogTitle: {
    marginBottom: theme.spacing(1)
  },
  formControl: {
    width: "100%",
    marginBottom: 25
  },
  chip: {
    marginRight: 5
  }
});

class PrintView extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }

  state = {
    selectedItems: [],
    dialogPrint: false,
    printing: false,
    printView: false,
    dateGroup: "",
    printColumns: [],
    groupBy: [],
    sumFields: [],
    tableColumns: [],
    sortFields: [],
    results: [],
    filters: [],
    name: "",
    tableValues: [],
    showLines: true
  };

  componentDidMount() {
    this.loadReport(this.props);
  }

  componentWillReceiveProps(nextProps) {}

  loadReport(props) {
    var fields = [];
    var sums = [];
    var filters = [];
    var groups = [];
    var sort = [];
    var name = "";
    if (props.baseReport) {
      fields = props.baseReport.fields;
      filters = (props.filters || []).concat(props.baseReport.filters);
      groups = props.baseReport.groups;
      sums = props.baseReport.sums;
      sort = props.baseReport.sort;
      name = props.baseReport.name;
    } else {
      name = `Reporte ${props.metadata.title}`;
      fields = [];
      groups = [];
      sums = [];
      sort = [];
      filters = props.filters || [];
    }

    this.setState({
      name: name,
      printColumns: fields || [],
      groupBy: groups || [],
      sumFields: sums || [],
      sortFields: sort || [],
      filters: filters || []
    });
  }

  onClosePivot = e => {
    this.setState({ printView: false });
    this.props.onClosePivot();
  };

  toCsv = () => {
    var props = this.props;
    var objectToCSVRow = function(item, columns) {
      var dataArray = [];
      columns.forEach(columnKey => {
        var value = item[columnKey];
        if (!value && value != false) value = "";
        var innerValue = value.toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
        dataArray.push(result);
      });
      return dataArray.join(",");
    };

    let arrayOfObjects = this.state.tableValues;

    var csvContent = [];

    // headers
    csvContent.push(this.state.tableColumns.join(","));

    arrayOfObjects.forEach(item => {
      var newContent = objectToCSVRow(item, this.state.tableColumns);
      csvContent.push(newContent);
    });

    var fileName = this.props.metadata.key + ".csv";
    var data = csvContent.join("\r\n");
    var csvData = data;
    var blob = new Blob([csvData], {
      type: "application/csv;charset=utf-8;"
    });

    if (window.navigator.msSaveBlob) {
      // FOR IE BROWSER
      navigator.msSaveBlob(blob, fileName);
    } else {
      // FOR OTHER BROWSERS
      var link = document.createElement("a");
      var csvUrl = URL.createObjectURL(blob);
      link.href = csvUrl;
      link.style = "visibility:hidden";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  onPrint = e => {
    this.setState({ dialogPrint: true });
  };

  onPrintFromModal = e => {
    this.setState({ dialogPrint: false, printView: true, loading: true });
    this.props
      .dispatch(
        simpleApi(`${this.props.metadata.key}/groupBy`, {
          groupBy: this.state.groupBy,
          sumFields: this.state.sumFields,
          dateGroup: this.state.dateGroup,
          filters: this.state.filters
        })
      )
      .then(result => {
        var valueMap = {};
        var columns = [].concat(this.state.groupBy);

        var dateGroup = this.state.dateGroup;
        if (this.state.dateGroup.length > 2) {
          var dateFieldName = this.state.dateGroup.split("-")[1];
          result.forEach(groupedItem => {
            var groupName = [];
            var groupObject = {};
            this.state.groupBy.forEach(groupByName => {
              groupName.push(groupedItem[groupByName]);
              groupObject[groupByName] = groupedItem[groupByName] || "";
            });
            groupName = groupName.join("_");
            if (!valueMap[groupName]) valueMap[groupName] = groupObject;

            var dateValue = groupedItem[dateFieldName];
            valueMap[groupName][dateValue] = numeral(groupedItem[this.state.sumFields]).format("0,0.00");
            if (columns.indexOf(dateValue) == -1) columns.push(dateValue);
          });
        }
        function getSortMethod() {
          var _args = Array.prototype.slice.call(arguments);
          return function(a, b) {
            for (var x in _args) {
              var ax = a[_args[x].substring(1)];
              var bx = b[_args[x].substring(1)];
              var cx;

              ax = typeof ax == "string" ? ax.toLowerCase() : ax / 1;
              bx = typeof bx == "string" ? bx.toLowerCase() : bx / 1;

              if (_args[x].substring(0, 1) == "-") {
                cx = ax;
                ax = bx;
                bx = cx;
              }
              if (ax != bx) {
                return ax < bx ? -1 : 1;
              }
            }
          };
        }
        this.setState({
          loading: false,
          results: result,
          tableColumns: columns,
          tableValues: Object.values(valueMap).sort(
            getSortMethod.apply(null, this.state.groupBy.map(item => `+${item}`))
          )
        });
      });
  };

  onSaveFromModal = e => {
    this.setState({ loading: true });

    this.props
      .dispatch(
        simpleApi("reporte/create", {
          table: this.props.metadata.key,
          filters: this.state.filters,
          sums: this.state.sumFields,
          sort: this.state.sortFields,
          groups: this.state.groupBy,
          fields: this.state.printColumns,
          name: this.state.name
        })
      )
      .then(() => {
        this.props.dispatch(alert("Se guardo el reporte", "success"));
        this.props.dispatch(
          onLoadData(this.props.metadata.key, this.state.filters, {
            limitless: true,
            order: this.state.sortFields.join(",")
          })
        );
      })
      .then(() => {
        this.setState({ dialogPrint: false, printView: true, loading: false });
        return true;
      });
  };

  onPrintFromView = e => {
    //window.print();

    printDiv(this.table.current);
  };

  onFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      printColumns: value
    });
  };

  onNameChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      name: value
    });
  };

  onGroupFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      groupBy: value
    });
  };

  onDateFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      dateGroup: value
    });
  };

  onSumFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      sumFields: value
    });
  };

  onSortFieldChange = e => {
    var value = e.value || e.target.value;
    if (value.length > 1) value = value[value.length - 1];

    this.setState({
      sortFields: value
    });
  };

  onMostrarLineasChange = e => {
    this.setState({
      showLines: e.target.checked
    });
  };

  getGroupTableData() {
    var groups = {};
    var columnKeys = this.state.printColumns;
    var sumFields = this.state.sumFields;
    var totalFields = {};

    this.props.dataRows.forEach(row => {
      var groupKey = row[this.state.groupBy[0]];

      var group = groups[groupKey];
      if (!group) group = { rows: [], name: groupKey, sums: {} };
      columnKeys.forEach(columnKey => {
        if (sumFields.indexOf(columnKey) > -1) {
          if (!group.sums[columnKey]) group.sums[columnKey] = 0;
          group.sums[columnKey] += row[columnKey];
          if (!totalFields[columnKey]) totalFields[columnKey] = 0;
          totalFields[columnKey] += row[columnKey];
        }
      });
      group.rows.push(row);
      groups[groupKey] = group;
    });

    var rows = [];
    Object.keys(groups).forEach(groupKey => {
      var groupRow = groups[groupKey];
      rows.push(
        columnKeys.map((key, index) => {
          if (index == 0) return groupRow.name;
          else return "";
        })
      );

      groupRow.rows.map(row => {
        rows.push(
          columnKeys.map(key => {
            var column = this.props.metadata.properties[key];
            if (column.element == "autocomplete") key = "__" + key;

            if (column.type == "number") return numeral(row[key]).format("0,0.00");

            return row[key];
          })
        );
      });

      rows.push(
        columnKeys.map((key, index) => {
          if (groupRow.sums[key]) return numeral(groupRow.sums[key]).format("0,0.00");
          else return "";
        })
      );
    });

    rows.push(
      columnKeys.map((key, index) => {
        if (totalFields[key]) return numeral(totalFields[key]).format("0,0.00");
        else return "";
      })
    );

    return rows;
  }

  getTableData() {
    if (this.state.groupBy.length > 0) return this.getGroupTableData();

    return this.props.dataRows.map(row => {
      var keys = this.state.printColumns;
      return keys.map(key => {
        var column = this.props.metadata.properties[key];
        if (column.element == "autocomplete") key = "__" + key;
        return row[key];
      });
    });
  }

  renderPrintView() {
    const { classes } = this.props;

    if (!this.state.printView) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        fullScreen={true}
        open={this.state.printView}
        onClose={this.onClosePivot}
        aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography className={classes.grow} variant="title" color="inherit">
              Analisis {this.props.metadata.title}
            </Typography>
            <Button onClick={this.onClosePivot} color="inherit">
              Cancelar
            </Button>
            <Button onClick={this.onPrintFromView} color="inherit" autoFocus>
              Imprimir
            </Button>
            <Button onClick={this.toCsv} color="inherit" autoFocus>
              Excel
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent classes={{ root: classes.dialogRoot }}>
          <div ref={this.table}>
            <Grid container className={classes.reportHeader}>
              <Grid item xs="9">
                <Typography variant="subtitle1" gutterBottom>
                  {this.props.metadata.namespace} {this.props.metadata.title || this.props.metadata.key}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Preparado Por: {this.props.user.name}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Autorizado: {this.props.user.id}</div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                  <div>{this.props.user.account}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Fecha: {moment().format("DD MMM-YYYY")}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Hora: {moment().format("HH:MM")}</div>
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="overline" gutterBottom>
              Código Verificador: {parseInt(Math.random() * 100000)}
            </Typography>

            {this.state.loading ? <CircularProgress className={classes.progress} color="secondary" /> : null}

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.state.tableColumns.map(field => {
                    var title = field;
                    if (this.props.metadata.properties[field])
                      title = this.props.metadata.properties[field].title;
                    return <TableCell>{title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableValues.map(tableValue => {
                  return (
                    <TableRow key={Math.random()}>
                      {this.state.tableColumns.map(field => {
                        return <TableCell>{tableValue[field]}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderSelectColumsForPrint() {
    const { classes } = this.props;
    if (!this.props.dialogOpen) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        fullWidth
        maxWidth="md"
        open={this.props.dialogOpen}
        onClose={this.props.onClosePivot}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText className={classes.dialogTitle}>Analisis de Datos</DialogContentText>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Cuales campos agrupamos</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={this.state.groupBy}
                  onChange={this.onGroupFieldChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(item => (
                        <Chip key={item} label={item} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}>
                  {this.props.metadata.table.fields
                    .filter(item => {
                      var column = this.props.metadata.properties[item];

                      if (["createdAt", "updatedAt", "fecha"].indexOf(column.key) > -1) return false;
                      else if (
                        column.filterRenderer == "DateFilter" ||
                        column.filterRenderer == "DateTimeFilter"
                      )
                        return false;

                      return (
                        column.type != "number" &&
                        column.key != "id" &&
                        column.key != "consecutivo" &&
                        column.key != "name"
                      );
                    })
                    .map(item => {
                      var column = this.props.metadata.properties[item];

                      return (
                        <MenuItem key={item} value={item}>
                          {column.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Cual campo sumamos</InputLabel>
                <Select
                  fullWidth
                  value={this.state.sumFields}
                  onChange={this.onSumFieldChange}
                  input={<Input id="select-multiple-chip" />}
                  MenuProps={MenuProps}>
                  {this.props.metadata.table.fields
                    .filter(item => {
                      var column = this.props.metadata.properties[item];
                      return column.type == "number";
                    })
                    .map(item => {
                      var column = this.props.metadata.properties[item];

                      return (
                        <MenuItem key={item} value={item}>
                          {column.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Agrupamos por fecha?</InputLabel>
                <Select
                  fullWidth
                  value={this.state.dateGroup}
                  onChange={this.onDateFieldChange}
                  input={<Input id="select-multiple-chip" />}
                  MenuProps={MenuProps}>
                  <MenuItem value={""}>No</MenuItem>

                  {this.props.metadata.table.fields
                    .filter(item => {
                      var column = this.props.metadata.properties[item];
                      if (["createdAt", "updatedAt", "fecha"].indexOf(column.key) > -1) return true;
                      else if (
                        column.filterRenderer == "DateFilter" ||
                        column.filterRenderer == "DateTimeFilter"
                      )
                        return true;
                      return false;
                    })
                    .map(item => {
                      var column = this.props.metadata.properties[item];

                      return ["día", "semana", "mes", "año"].map(tipo => (
                        <MenuItem key={`${item}-${tipo}`} value={`${item}-${tipo}`}>
                          {column.title} por {tipo}
                        </MenuItem>
                      ));
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="caption">Filtros</Typography>
          {this.state.filters.map(filter => {
            return (
              <Chip
                key={filter[0]}
                label={`${filter[0].split(".")[0]} ${filter[1]} ${filter[2]}`}
                className={classes.chip}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.onPrintFromModal} color="primary">
            Preparar Analisis
          </Button>

          <Button onClick={this.props.onClosePivot} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, theme } = this.props;
    if (!this.props.metadata) return null;

    return (
      <div className={classes.root}>
        <div className={classes.main}>
          {this.renderSelectColumsForPrint()}
          {this.renderPrintView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    grouping: selectSingleGroup(store),
    user: store.user.user,
    account: store.user.account
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PrintView));

function printDiv(printElement) {
  var printWindow = window.open("", "PRINT");
  printWindow.document.write(document.documentElement.innerHTML);
  setTimeout(() => {
    // Needed for large documents
    printWindow.document.body.style.margin = "0 0";
    printWindow.document.body.innerHTML = printElement.outerHTML;
    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/
    printWindow.print();
    printWindow.close();
  }, 1000);
}
