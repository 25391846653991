const init = {
  social: [],
  options: { expanded: false, hasNew: false, open: false, tabIndex: 0 }
};

export function setOptions(options) {
  return {
    type: "CHANGE_OPTIONS",
    payload: {
      options: options
    }
  };
}

export function addToSocial(message) {
  return {
    type: "ADD_SOCIAL",
    payload: {
      message: message
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "ADD_SOCIAL": {
      var payload = action.payload;
      var result = {
        ...state,
        social: [...state.social, payload.message]
      };
      return result;
    }

    case "CHANGE_OPTIONS": {
      var payload = action.payload;
      var result = {
        ...state,
        options: { ...state.options, ...payload.options }
      };
      return result;
    }
  }

  return state;
};

export function selectSocial(state) {
  return state.feed.social;
}

export function selectOptions(state) {
  return state.feed.options;
}
