import Ajax from "../../helpers/ajax";
import Finance from "../../helpers/finance";

//import { setPrecioAndDescuento } from "../helpers/precios";
import moment from "moment";
import { alert } from "redux/reducers/header";

var init = {
  view: "HOME",
  pedidosById: {},
  recibosById: {},
  categoriasById: {},
  clientesById: {},
  productosById: {},
  grupoListsById: {},
  gruposById: {},
  departamentosById: [],
  productosByGrupoId: {},
  selectedClienteId: null,
  selectedPedidoId: null,
  selectedDocumentoId: null,
  selectedCategoriaId: null,
  selectedGrupoId: null,
  selectedReciboId: null
};

export function pullCatalogoFromServer() {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    try {
      var loadPromises = [
        Ajax.post(dispatch, "productoDepartamento/query", { limitless: true }),
        Ajax.post(dispatch, "productoCategoria/query", { limitless: true }),
        Ajax.post(dispatch, "productoGrupo/query", { limitless: true }),
        Ajax.post(dispatch, "producto/forCatalog", { limitless: true }),
        Ajax.post(dispatch, "productoGrupoList/query", { limitless: true })
      ];
      var promiseResponse = await Promise.all(loadPromises);

      function mapIt(index) {
        return promiseResponse[index].reduce(function(map, obj) {
          map[obj.id] = obj;
          return map;
        }, {});
      }

      var init = {
        departamentosById: mapIt(0),
        categoriasById: mapIt(1),
        gruposById: mapIt(2),
        grupoListsById: mapIt(4)
      };

      var gruposByProducto = {};
      var productosByGrupoId = {};
      promiseResponse[4].forEach(grupoList => {
        if (!productosByGrupoId[grupoList.productoGrupoId])
          productosByGrupoId[grupoList.productoGrupoId] = [];
        productosByGrupoId[grupoList.productoGrupoId].push(grupoList.productoId);

        if (!gruposByProducto[grupoList.productoId]) gruposByProducto[grupoList.productoId] = [];
        var grupo = init.gruposById[grupoList.productoGrupoId];
        if (grupo) {
          var categoria = init.categoriasById[grupo.productoCategoriaId];
          var name = grupo.name;
          if (categoria) name = `${categoria.name}-${grupo.name}`;
          gruposByProducto[grupoList.productoId].push(name);
        }
      });

      promiseResponse[3].forEach(producto => {
        if (gruposByProducto[producto.id]) {
          producto.grupoNames = gruposByProducto[producto.id].join(",");
        }
      });

      init.productosById = mapIt(3);
      init.productosByGrupoId = productosByGrupoId;
      console.log(init);

      dispatch({ type: "CATALOGO_UPDATE", payload: init });
    } catch (e) {
      console.log(e);
      //Handle Load Error
    }
  };
}

export function catalogoDestroy(type, body) {
  return function(dispatch, getState) {
    var state = getState();
    var upperName = type.charAt(0).toUpperCase() + type.slice(1);
    var url = `producto${upperName}/destroy`;
    var originalById = { ...state.catalogo[`${type}sById`] };
    var byId = { ...state.catalogo[`${type}sById`] };
    delete byId[body.id];
    dispatch({
      type: "CATALOGO_UPDATE",
      payload: {
        [`${type}sById`]: byId
      }
    });

    return Ajax.post(dispatch, url, { ids: [body.id] })
      .then(function(result) {
        //dispatch(alert("El cambio fue exitoso", "success", true));
        if (type == "grupoList") {
          let productosByGrupoId = {};
          Object.values(byId).forEach(grupoList => {
            if (!productosByGrupoId[grupoList.productoGrupoId])
              productosByGrupoId[grupoList.productoGrupoId] = [];
            productosByGrupoId[grupoList.productoGrupoId].push(grupoList.productoId);
          });
          dispatch({
            type: "CATALOGO_UPDATE",
            payload: {
              productosByGrupoId: productosByGrupoId
            }
          });
        }
        return result;
      })
      .catch(function(apiError) {
        console.log(apiError);

        dispatch({
          type: "CATALOGO_UPDATE",
          payload: {
            [`${type}sById`]: originalById
          }
        });
        dispatch(
          alert(
            "No se puede borrar. Revise que no tenga relaciones como categorias, grupos o productos asignados. "
          )
        );
      });
  };
}

export function catalogoUpsert(type, body) {
  return function(dispatch, getState) {
    var state = getState();
    var url = `producto${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    if (type == "producto") url = `producto`;

    if (body.id) url += "/update";
    else {
      delete body.id;
      url += "/create";
    }

    return Ajax.post(dispatch, url, body)
      .then(function(result) {
        if (type == "grupoList") {
          const grupoListsById = { ...state.catalogo.grupoListsById, [result.id]: result };
          let productosByGrupoId = {};
          Object.values(grupoListsById).forEach(grupoList => {
            if (!productosByGrupoId[grupoList.productoGrupoId])
              productosByGrupoId[grupoList.productoGrupoId] = [];
            productosByGrupoId[grupoList.productoGrupoId].push(grupoList.productoId);
          });
          dispatch({
            type: "CATALOGO_UPDATE",
            payload: {
              productosByGrupoId: productosByGrupoId,
              grupoListsById: grupoListsById
            }
          });
        } else
          dispatch({
            type: "CATALOGO_UPDATE",
            payload: {
              [`${type}sById`]: { ...state.catalogo[`${type}sById`], [result.id]: result }
            }
          });
        dispatch(alert("El cambio fue exitoso", "success", true));
        return result;
      })
      .catch(function(apiError) {
        console.log(apiError);
        dispatch(alert("Ocurrio un error enviando el cambio" + apiError));
      });
  };
}

export function onCatalogoDelete(pedidoId) {
  return async function(dispatch, getState) {
    var newById = { ...getState().visitaTablet.pedidosById };
    delete newById[pedidoId];

    dispatch({
      type: "CATALOGO_UPDATE",
      payload: { pedidosById: newById, view: "HOME", selectedPedidoId: null, selectedClienteId: null }
    });
  };
}

export function onCatalogoSetSelected(type, id) {
  return function(dispatch, getState) {
    dispatch({
      type: "VISIT_SET_SELECTED",
      payload: {
        type: type,
        id: id
      }
    });

    if (type == "departamento") {
      var departamentos = getCatalogoById(getState(), "departamentos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "grupo",
          id: -1
        }
      });
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "CATALOGO_UPDATE": {
      return { ...state, ...action.payload };
    }

    case "VISIT_SET_SELECTED": {
      return {
        ...state,
        ["selected" +
        action.payload.type.charAt(0).toUpperCase() +
        action.payload.type.slice(1) +
        "Id"]: action.payload.id
      };
    }

    default: {
      return state;
    }
  }
};

export function getCatalogoAll(state, type) {
  var typeById = state.catalogo[`${type}ById`];
  var array = Object.values(typeById);

  if (array.length > 0 && array[0].hasOwnProperty("orden")) array.sort(sortBy("orden"));
  else if (array.length > 0 && array[0].hasOwnProperty("name")) array.sort(sortBy("name"));
  else if (array.length > 0 && array[0].hasOwnProperty("id")) array.sort(sortBy("id"));
  return array;
}

export function getCatalogoById(state, type, id) {
  var typeById = state.catalogo[`${type}ById`];
  return typeById[id];
}

export function getCatalogoSelected(state, type = "") {
  var selectedId = state.catalogo[`selected${type.charAt(0).toUpperCase()}${type.slice(1)}Id`];
  if (!selectedId) return null;
  return state.catalogo[`${type}sById`][selectedId];
}

export function getCatalogoByIdFn(state) {
  return (type, id) => {
    return state.catalogo[`${type}sById`][id];
  };
}

export function getCategoriasByDepartamentoFn(store) {
  return departamentoId => {
    var categorias = Object.values(store.catalogo.categoriasById).filter(categoria => {
      if (categoria.productoDepartamentoId === departamentoId) {
        categoria.grupos = Object.values(store.catalogo.gruposById).filter(grupo => {
          return grupo.productoCategoriaId == categoria.id;
        });
        categoria.grupos.sort(sortBy("name"));
        return true;
      }
      return false;
    });

    categorias.sort(sortBy("orden"));

    return categorias;
  };
}

function sortBy(field) {
  return (a, b) => {
    if (field == "orden") {
      if (!a.orden || a.orden == 0) a.orden = 100000;
      if (!b.orden || b.orden == 0) b.orden = 100000;
    }

    if (a[field] > b[field]) return 1;
    if (b[field] > a[field]) return -1;
    else return 0;
  };
}
