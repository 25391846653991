import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onReloadData, onSave, onAction, reSelectDataByType } from "redux/reducers/data";
import { onFilter, selectFilterByKey, changeGroup, selectGroups } from "redux/reducers/table";

import {
  selectFilters,
  changeLimit,
  selectLimit,
  onResetFilters,
  onResetViewItem
} from "redux/reducers/table";
import { alert } from "redux/reducers/header";

import { connect } from "react-redux";
import SmartTable from "components/SmartTable/Table";
import ActionMenu from "./actionMenu";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import PrintComponent from "./print";
import PivotComponent from "./pivot";

import Analisis from "components/Analisis";

import {
  unSubscribeToStandardChannel,
  subscribeToStandardChannel,
  selectDataByTypeAndId
} from "redux/reducers/socket";

import TaskList from "./taskList";

export const styles = theme => ({
  root: {
    backgroundColor: "#fff"
  },
  main: {
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  toolbar: {
    backgroundColor: "#fff",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing(1)
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  warningButton: {},
  customBadge: {
    top: -4,
    right: -15,
    // The border color match the background color.
    border: `2px solid ${theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[900]}`
  },
  badgeLabel: {
    marginLeft: 25
  },
  badge: {
    top: -9,
    right: -20,
    minWidth: 25,
    minHeight: 25,
    width: "auto",
    height: "auto",
    padding: 1,
    // The border color match the background color.
    border: `2px solid ${theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[900]}`
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[100],
    padding: 0
  },
  limitContainer: {
    marginLeft: 85
  },
  limit: {
    marginTop: 10
  },
  pageTitle: {},
  bottomNavigation: {
    height: 20
  }
});

export function makeTable(modelMap, formatters, actionMap) {
  var Table = class Any extends BaseTable {
    getModelMap() {
      return modelMap || {};
    }

    getActionMap() {
      return actionMap || {};
    }

    getCustomFormatters() {
      return formatters || {};
    }
  };

  const mapStateToProps = (state, ownProps) => {
    return {
      //currentViewItem: selectDataByTypeAndId(state, ownProps.metadataType, state.table.currentViewId),
      currentViewId: state.table.currentViewId,
      filters: selectFilters(state, ownProps.metadataType),
      metadata: selectMetadataByType(state, ownProps.metadataType),
      dataRows: reSelectDataByType(state, ownProps),
      limit: selectLimit(state, ownProps.metadataType),
      groups: selectGroups(state, ownProps.metadataType)
    };
  };

  const ConnectedTable = connect(mapStateToProps)(Table);

  return withStyles(styles, { withTheme: true })(ConnectedTable);
}

class BaseTable extends React.Component {
  state = {
    selectedItems: [],
    selectedAllItems: false,
    dialogSelectedItems: [],
    dialogOpen: false,
    dialogPrint: false,
    dialogPivot: false,
    dialogPrintReport: null,
    dialogBatch: false,
    printing: false,
    printColumns: [],
    customFormatters: this.getCustomFormatters()
  };

  componentDidMount() {
    this._componentDidMount();
  }

  componentWillReceiveProps(nextProps) {
    this._componentWillReceiveProps(nextProps);
  }

  _componentDidMount() {
    this.props.dispatch(
      onLoadMetaData(this.props.metadataType, null, {
        inProgress: true,
        source: "table"
      })
    );
    //this.props.dispatch(subscribeToStandardChannel(this.props.metadataType));
  }

  _componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    //this.props.dispatch(unSubscribeToStandardChannel(this.props.metadataType));
  }

  reload() {
    this.props.dispatch(onReloadData(this.props.metadataType));
  }

  updateSelectedRows = rows => {
    this.setState({ selectedItems: rows });
  };

  onCrear(items) {
    this.props.history.push(this.props.location.pathname + "/create");
  }

  onBorrar(items) {
    var ids = items.map(item => item.id);
    this.props.dispatch(onAction(this.props.metadataType, "destroy", ids));
  }

  showAnalizar() {
    if (
      this.state.selectedItems.length > 0 &&
      this.props.metadata.actions &&
      JSON.stringify(this.props.metadata.actions).indexOf("Analizar") > -1
    )
      return true;
    return false;
  }

  getModelMap() {
    return {};
  }

  getActionMap() {
    return {};
  }

  onCustomAction(action) {
    return function(items) {
      var ids = items.map(item => item.id);
      var actionName = action.key || action.title.toLowerCase();

      if (action.then == "confirm") {
        var res = window.confirm(
          ids.length == 0
            ? "Desea realizar " + action.title + " con todas las filas?"
            : "Desea realizar " + action.title + " con " + ids.length + " filas?"
        );
        if (!res) return;
      }

      this.props.dispatch(onAction(this.props.metadataType, actionName, ids)).then(response => {
        if (action.then == "openUrl") {
          window.open(response.url);
        }
      });
    };
  }

  onFastCreate = (metadataType, operation, formId, assignToType, closeOnSuccess) => {
    //Doing this so state is serializable, we'll see how it turns out

    var props = this.props;
    this.FastCreateComponent = this.props.getComponentFromPath(metadataType, operation);
    this.setState({
      fastCreate: {
        metadataType: metadataType,
        operation: operation,
        formId: formId || Math.random(),
        assignToType: assignToType,
        closeOnSuccess: closeOnSuccess
      }
    });
  };

  handleFastCreateClose = createdResult => {
    if (this.state.fastCreate.closeOnSuccess) this.state.fastCreate.closeOnSuccess(createdResult);
    this.setState({ fastCreate: null });
    this.FastCreateComponent = () => null;
  };

  renderFastCreateModal() {
    const { classes } = this.props;
    if (!this.state.fastCreate) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth="lg"
        open={this.state.fastCreate != null}
        onClose={this.handleFastCreateClose}
        aria-labelledby="form-dialog-title">
        <DialogContent classes={{ root: classes.dialog }}>
          <this.FastCreateComponent
            formId={this.state.fastCreate.formId}
            metadataType={this.state.fastCreate.metadataType}
            onClose={this.handleFastCreateClose}
          />
        </DialogContent>
      </Dialog>
    );
  }

  handleMenuClick = action => {
    var actionMap = this.getActionMap();
    var actionName = action.key || action.title;
    if (action.constraint && action.constraint == "single" && this.state.selectedItems.length > 0)
      return this.props.dispatch(
        alert("Este boton solo permite una fila selecionada y hay  " + this.state.selectedItems + " activas")
      );
    if (
      action.constraint &&
      (action.constraint == "single" || action.constraint == "multiple") &&
      this.state.selectedItems.length == 0
    )
      return this.props.dispatch(alert("Debe escoger al menos una columna"));

    var customAction = this[`on${actionName}`];

    if (actionMap[actionName]) {
      customAction = actionMap[actionName];
      customAction(this, this.state.selectedItems);
      return this.setState({ selectedItems: [] });
    } else if (!customAction) customAction = this.onCustomAction(action);

    customAction.bind(this)(this.state.selectedItems, action);
    this.setState({ selectedItems: [] });
  };

  handleLimitChange = e => {
    this.props.dispatch(changeLimit(this.props.metadataType));
  };

  handleSelectAllClick = e => {
    var allIdx = this.props.dataRows.length;
    var arrayIndexes = [];
    for (let i = 0; i < allIdx; i++) {
      arrayIndexes.push(i)
    }
    if(this.state.selectedAllItems){
      this.setState({ selectedIndexes: [] , selectedItems: [], selectedAllItems: false});
    }else{
      this.setState({ selectedIndexes: arrayIndexes , selectedItems: this.props.dataRows, selectedAllItems: true});
    }
  };

  clearSelectedIndexes = () => {
    this.setState({ selectedIndexes: [], selectedItems: [], selectedAllItems: false});
  };


  handleRefreshClick = e => {
    this.setState({ selectedItems: [] });
    this.props.dispatch(onReloadData(this.props.metadataType));
  };

  handleResetClick = e => {
    this.setState({ selectedItems: [] });
    this.props.dispatch(onResetFilters(this.props.metadataType));
  };

  handleBatchClick = e => {
    this.setState({ dialogBatch: true });
  };

  onShowModal = (name, params, size) => {
    this.setState({
      dialogOpen: true,
      dialogSelectedItems: [],
      dialogName: name,
      dialogParams: params,
      dialogSize: size
    });
  };

  onCloseModal = () => {
    this.setState({
      selectedItems: [],
      dialogSelectedItems: [],
      dialogOpen: false,
      dialogName: null,
      dialogParams: null,
      dialogSize: null
    });
  };

  getTableProps() {
    return {
      updateSelectedRows: this.updateSelectedRows
    };
  }

  getCellActions(column, row) {}

  onPrint = report => {
    this.setState({ dialogPrint: true, dialogPrintReport: report });
  };

  onPivot = () => {
    this.setState({ dialogPivot: true });
  };

  onClosePrint = e => {
    this.setState({ dialogPrint: false, dialogPrintReport: null });
  };

  onClosePivot = e => {
    this.setState({ dialogPivot: false });
  };

  onGraph = e => {
    this.setState({ dialogGraph: true });
  };

  onCloseGraph = e => {
    this.setState({ dialogGraph: false });
  };

  onCloseBatch = e => {
    this.setState({ dialogBatch: false });
  };

  renderPrintComponent() {
    if (!this.state.dialogPrint) return null;
    return (
      <PrintComponent
        baseReport={this.state.dialogPrintReport}
        filters={this.props.filters}
        metadata={this.props.metadata}
        dataRows={this.props.dataRows}
        dialogOpen={this.state.dialogPrint}
        onClosePrint={this.onClosePrint}
      />
    );
  }

  renderPivotComponent() {
    if (!this.state.dialogPivot) return null;

    return (
      <PivotComponent
        baseReport={this.state.dialogPrintReport}
        filters={this.props.filters}
        metadata={this.props.metadata}
        dataRows={this.props.dataRows}
        dialogOpen={this.state.dialogPivot}
        onClosePivot={this.onClosePivot}
      />
    );
  }

  handleModalClick = action => {
    var newState = {
      dialogName: action.title,
      dialogOpen: true,
      dialogSelectedItems: this.state.selectedItems.concat([])
    };
    if (action.size) newState.dialogSize = action.size;
    this.setState(newState);
    return this.setState({ selectedItems: [] });
  };

  renderModal() {
    const { classes } = this.props;
    if (!this.state.dialogOpen) return null;
    var Modal = this.getModelMap()[this.state.dialogName];

    if (!Modal) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth={this.state.dialogSize || "md"}
        fullScreen={this.state.dialogSize == "fullScreen"}
        open={this.state.dialogOpen}
        onClose={this.onCloseModal}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <div>
            <Modal
              onFastCreate={this.onFastCreate}
              dispatch={this.props.dispatch}
              items={this.state.dialogSelectedItems}
              metadata={this.props.metadata}
              onCloseModal={this.onCloseModal}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  onCloseViewModal = () => {
    this.props.dispatch(onResetViewItem());
  };

  getCustomFormatters() {
    return {};
  }

  renderTable() {
    const { classes } = this.props;
    if (!this.props.metadata || !this.props.dataRows) return null;
    var analizar = this.showAnalizar() || false;
    if (this.state.selectedItems.length == 0 && !analizar) analizar = true;

    if (this.props.metadata.view == "TASK_LIST") return <TaskList {...this.props} />;

    return (
      <div>
        <SmartTable
          width={"calc(100%)"}
          onFastCreate={this.onFastCreate}
          customFormatters={this.state.customFormatters}
          onShowModal={this.onShowModal}
          getCellActions={this.getCellActions}
          updateSelectedRows={this.updateSelectedRows}
          selectedIndexes={this.state.selectedIndexes}
          selectedAllItems={this.state.selectedAllItems}
          clearSelectedIndexes={this.clearSelectedIndexes}
          metadataType={this.props.metadataType}
          metadata={this.props.metadata}
          dataRows={this.props.dataRows}
          groups={this.props.groups}
          history={this.props.history}
          height={"calc(100vh - 95px)"}
        />
      </div>
    );
  }

  renderAnalisis() {
    if (!this.showAnalizar()) return null;

    return <Analisis width="85vw" ids={this.state.selectedItems} metadate={this.props.metadata} />;
  }

  render() {
    const { classes, theme } = this.props;
    if (!this.props.metadata) return null;
    var limit = this.props.metadata.limit || 300;
    if (this.props.limit) limit = this.props.metadata.maxLimit || 10000;

    var actions = [];
    if (this.props.metadata && this.props.metadata.actions) actions = this.props.metadata.actions;
    return (
      <div className={classes.root}>
        <div style={{ width: "auto" }} className={classes.toolbar}>
          <ActionMenu
            dataRows={this.props.dataRows}
            count={this.props.metadata.count}
            reports={this.props.metadata.reports || []}
            selectedItems={this.state.selectedItems}
            handleLimitChange={this.handleLimitChange}
            handleSelectAllClick={this.handleSelectAllClick}
            handleRefreshClick={this.handleRefreshClick}
            handleResetClick={this.handleResetClick}
            handleBatchClick={this.handleBatchClick}
            handleItemClick={this.handleMenuClick}
            handleModalClick={this.handleModalClick.bind(this)}
            onPrint={this.onPrint}
            onPivot={this.onPivot}
            onGraph={this.onGraph}
            actions={actions || []}
            modals={this.props.metadata.modals || []}
            batch={this.props.metadata.batch}
          />
        </div>

        <div className={classes.main}>
          {this.renderModal()}
          {this.renderPrintComponent()}
          {this.renderPivotComponent()}

          {this.renderFastCreateModal()}
          {this.renderAnalisis()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default BaseTable;
