import { simpleApi } from "../api";

const defaultState = {
  listsByTypeAndId: {}
};

function processFilters(filters, item) {
  return filters.map(filter => {
    filter = filter.concat([]);

    if (filter[2] && filter[2].indexOf("$") == 0 && item) {
      var key = filter[2].replace("$", "");
      if (key.length == 0) key = "id";
      filter[2] = item[key];
    }
    return filter;
  });
}

export function loadRelatedList(type, item, relatedField) {
  return function(dispatch, getState) {
    var state = getState();

    dispatch(
      simpleApi(relatedField.route, {
        ...(relatedField.params || {}),
        filters: processFilters(relatedField.filters, item)
      })
    ).then(result => {
      return dispatch({
        type: "UPDATE_RELATED_LIST",
        payload: { type: type, id: item.id, key: relatedField.key, list: result }
      });
    });
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "UPDATE_RELATED_LIST":
      var newState = {
        ...state,
        listsByTypeAndId: {
          ...state.listsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: {
            ...state.listsByTypeAndId[`${action.payload.type}-${action.payload.id}`],
            [action.payload.key]: action.payload.list
          }
        }
      };

      console.log(newState);
      return newState;

    default:
      return state;
  }
};
