import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleTable from "components/Table/Table.jsx";
import Table from "views/base/table";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit } from "redux/reducers/table";
import { simpleApi } from "redux/reducers/api";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { alert } from "redux/reducers/header";

import numeral from "numeral";

const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  root: {
    minWidth: 300
  },
  bold: {
    fontWeight: 800
  },
  formControl: {
    minWidth: 300,
    marginBottom: 20
  },
  progress: {
    marginLeft: "30%",
    marginTop: 50
  }
});

class SaldoModal extends React.Component {
  state = { notasCredito: null };

  componentDidMount() {
    if(!this.props.items[0]) return this.props.dispatch(alert("No se selecciono ninguna factura a pagar."))
    var clienteId = this.props.items[0].clienteId;
    var saldo = this.props.items[0].total;
    var id = this.props.items[0].id;

    this.setState({ id: id, referencia: "", formaPago: "", notasCredito: [], saldo: saldo });

    this.props.dispatch(simpleApi("saldo/findNCContado", { id: id, clienteId: clienteId })).then(res => {
      this.setState({ notasCredito: res });
    });
  }

  handleFormaChange = e => {
    this.setState({ formaPago: e.target.value });
    if (e.target.value == "efectivo") this.setState({ referencia: "n/d" });
    else this.setState({ referencia: "" });
  };

  handleReferenciaChange = e => {
    this.setState({ referencia: e.currentTarget.value });
  };

  onPagarClick = () => {
    this.props
      .dispatch(
        onAction("saldo", "pagar", {
          id: this.state.id,
          notasCredito: this.state.notasCredito,
          clienteId: this.state.clienteId,
          saldo: this.state.saldo,
          formaPago: this.state.formaPago,
          referencia: this.state.referencia
        })
      )
      .then(results => {
        this.props.onCloseModal();
      })
      .catch(() => {
        //this.onCloseModal();
      });
  };

  renderLoading() {
    const { classes } = this.props;

    return <CircularProgress className={classes.progress} />;
  }

  render() {
    if (!this.state.id) return null;
    if (!this.state.notasCredito) return this.renderLoading();
    const { classes } = this.props;
    var ncSaldo = 0;
    var items = [];

    var saldoPendiente = this.props.items[0].totalComprobante;
    var montoNc = 0;
    let ncs = this.state.notasCredito.map(nc => {
      var pagoNc = nc.total;
      var checkSum = (parseInt(saldoPendiente * 100000) + parseInt(nc.total * 100000)) / 100000;
      if (checkSum < 0) pagoNc = saldoPendiente * -1;
      saldoPendiente = (parseInt(saldoPendiente * 100000) + parseInt(pagoNc * 100000)) / 100000;
      montoNc += pagoNc;
      nc.pago = pagoNc;
      return [nc.tipo + "-" + nc.consecutivo, pagoNc + " de " + nc.total];
    });

    var porPagar = saldoPendiente;

    items.push(["Factura", numeral(this.state.saldo).format("0,0.00")]);
    if (this.state.notasCredito.length > 0) {
      items.push([<Typography style={{ color: "red" }}>Se usarán las NC's</Typography>, ""]);
      items = items.concat(ncs);
    }

    items.push(["Cliente debe pagar", numeral(porPagar).format("0,0.00")]);
    items.push([<div style={{ color: "green" }}>Devolver al cliente</div>, "*pronto*"]);

    return (
      <div className={classes.root}>
        <DialogContentText>Pagar facturas de contado</DialogContentText>

        <FormControl fullWidth className={classes.formControl}>
          <InputLabel shrink={true}>Forma de Pago</InputLabel>

          <Select value={this.state.formaPago} onChange={this.handleFormaChange} fullWidth>
            <MenuItem value={"efectivo"}>Efectivo</MenuItem>
            <MenuItem value={"tarjeta"}>Tarjeta</MenuItem>
            <MenuItem value={"transferencia"}>Transferencia</MenuItem>
            <MenuItem value={"nota credito"}>Nota Credito</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth className={classes.formControl}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            autoFocus
            fullWidth
            value={this.state.referencia}
            onChange={this.handleReferenciaChange}
            label="Referencia"
            type="text"
          />
        </FormControl>

        <SimpleTable tableData={items} />

        <DialogActions>
          <Button onClick={this.props.onCloseModal.bind(this)} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.onPagarClick.bind(this)} color="primary">
            Pagar
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(allStyles, { withTheme: true })(SaldoModal);
