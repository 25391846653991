import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MoreHoriz from "@material-ui/icons/MoreHoriz";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Formatter component
class ChildFormatter extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderModal() {
    var items = this.props.row[this.props.column.key] || [];

    return (
      <div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">{"Vista General"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <List>
                {items.map((item, index) => {
                  // var subKeys = Object.keys(item[key]);

                  //if (!subKeys || subKeys.length > 0) return null;

                  return (
                    <div>
                      <ListItem key={index}>
                        <List>
                          {Object.keys(item).map(key => {
                            return (
                              <ListItem key={key}>
                                <ListItemText secondary={key} primary={JSON.stringify(item[key])} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.renderModal()}
        {!this.props.row[this.props.column.key] ||
        this.props.row[this.props.column.key].length == 0 ? null : (
          <IconButton onClick={this.handleClickOpen} color="secondary" aria-label="Ver detalles">
            {this.props.row[this.props.column.key].length} <MoreHoriz />
          </IconButton>
        )}
      </div>
    );
  }
}

export default function(column) {
  return class extends React.Component {
    render() {
      return <ChildFormatter column={column} {...this.props} />;
    }
  };
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
