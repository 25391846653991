import Ajax from "../../helpers/ajax";
import Finance from "../../helpers/finance";

//import { setPrecioAndDescuento } from "../helpers/precios";
import moment from "moment";
import { alert } from "redux/reducers/header";
import { loadSmartView } from "../ui/smartView";

var init = {
  view: "HOME",
  pedidosById: {},
  recibosById: {},
  clientesById: {},
  productosById: {},
  gruposById: {},
  recomendationsById: {},
  transportesById: {},
  priceListsById: {},
  departamentosById: [],
  categoriasByDepartamento: [],
  gruposByCategoria: [],
  selectedClienteId: null,
  selectedPedidoId: null,
  selectedDocumentoId: null,
  selectedCategoriaId: null,
  selectedGrupoId: null,
  selectedReciboId: null
};

export function initVisitTablet() {
  return function(dispatch, getState) {
    try {
      var priceLists = JSON.parse(window.localStorage.getItem("priceLists_tablet") || "[]");
      var clientes = JSON.parse(window.localStorage.getItem("clientes_tablet") || "[]");
      var recomendations = JSON.parse(window.localStorage.getItem("recomendations_tablet") || "[]");
      var productos = JSON.parse(window.localStorage.getItem("productos_tablet") || "[]");
      var transportes = JSON.parse(window.localStorage.getItem("transportes_tablet") || "[]");

      recomendations.forEach(recomendation => {
        init.recomendationsById[recomendation.clienteId] = recomendation;
      });

      priceLists.forEach(priceList => {
        if (priceList) init.priceListsById[priceList.id] = priceList;
      });

      clientes.forEach(cliente => {
        init.clientesById[cliente.id] = cliente;
      });

      productos.forEach(producto => {
        if (!producto.preciosByName) producto.preciosByName = {};
        var keys = Object.keys(producto.precios);
        keys.forEach(key => {
          var id = key.split("_");
          //init.priceListsById[id[0]] = id[1];
          producto.preciosByName[id[1]] = producto.precios[key];
        });
      });

      transportes.forEach(transporte => {
        init.transportesById[transporte.id] = transporte;
      });
      init.productosById = productos.reduce(function(map, obj) {
        map[obj.id] = obj;
        return map;
      }, {});

      dispatch({ type: "VISITA_UPDATE", payload: init });
      return dispatch(pullFromServer());
    } catch (e) {
      console.log(e);
    }
  };
}

export function pullRecomendations(clienteId) {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    try {
      var recomendations = await Ajax.post(dispatch, "cliente/queProductosCompra", {
        filters: [["movimientoInventario.clienteId", "=", clienteId]]
      });
      recomendations.forEach(item => {
        item.clienteId = clienteId;
      });

      var init = getState().visitaTablet;
      init.recomendationsById = { ...init.recomendationsById };
      init.recomendationsById[clienteId] = recomendations;

      dispatch({ type: "VISITA_UPDATE", payload: init });
    } catch (e) {
      console.log(e);
      //Handle Load Error
    }
  };
}

export function pullPriceListsFromServer() {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    try {
      var priceLists = await Ajax.post(dispatch, "grupo/query");

      var init = { priceListsById: {} };

      priceLists.forEach(priceList => {
        if (priceList) init.priceListsById[priceList.id] = priceList;
      });

      return dispatch({ type: "VISITA_UPDATE", payload: init });
    } catch (e) {
      console.log(e);
      //Handle Load Error
    }
  };
}

export function pullTransporteFromServer() {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    try {
      var transportes = await Ajax.post(dispatch, "transporte/query");

      var init = { transportesById: {} };

      transportes.forEach(transporte => {
        init.transportesById[transporte.id] = transporte;
      });

      dispatch({ type: "VISITA_UPDATE", payload: init });
    } catch (e) {
      console.log(e);
      //Handle Load Error
    }
  };
}

export function pullFromServer() {
  return async function(dispatch, getState) {
    console.log("onlione", window.navigator);
    if (!window.navigator.onLine) return Promise.resolve({});

    var clientes = await Ajax.post(dispatch, "cliente/queryForTablet");

    var init = { clientesById: {} };
    clientes.forEach(cliente => {
      init.clientesById[cliente.id] = cliente;
    });

    init.productosById = {};

    dispatch({ type: "VISITA_UPDATE", payload: init });
    return dispatch(pullProductosFromServer());
  };
}

export function pullProductosFromServer() {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    var productos = await Ajax.post(dispatch, "producto/allWithPrecios");
    var init = {};

    init.productosById = {};
    productos.forEach(producto => {
      if (!producto.preciosByName) producto.preciosByName = {};
      init.productosById[producto.id] = producto;
    });

    dispatch({ type: "VISITA_UPDATE", payload: init });
    return dispatch(pullPriceListsFromServer());

    // return dispatch(pullTransporteFromServer());
  };
}

export function sendPedido(pedido) {
  return function(dispatch, getState) {
    var state = getState();

    return Ajax.post(dispatch, "orden/createForTablet", pedido);
  };
}

export function onVisitaBackHome() {
  return async function(dispatch, getState) {
    dispatch({
      type: "VISITA_UPDATE",
      payload: { view: "HOME", selectedPedidoId: null, selectedClienteId: null }
    });
  };
}

export function onVisitaBackToPedido() {
  return async function(dispatch, getState) {
    dispatch({
      type: "VISITA_UPDATE",
      payload: { view: "PEDIDO" }
    });
  };
}

export function onVisitaNewPedido() {
  return async function(dispatch, getState) {
    var state = getState();
    if (!state.visitaTablet.selectedClienteId) return dispatch(alert("Debe escoger un cliente primero"));

    var cliente = getVisitaSelected(state, "cliente");

    let pedido = {
      id: "L" + parseInt(Math.random() * 1000000),
      clienteId: cliente.id,
      tipo: "pedido",
      fuente: "vendedor",
      plazo: cliente.creditoPlazo,
      cedula: cliente.cedula,
      emails: cliente.correoDocumentosElectronicos,
      pedidoLinea: [],
      __clienteId: cliente.name,
      fecha: moment().format("YYYY-MM-DD"),
      transporteId: cliente.transporteId,
      __transporteId: cliente.__transporteId
    };

    dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: { ...state.visitaTablet.pedidosById, [pedido.id]: pedido }
      }
    });
    dispatch(onVisitSetSelected("pedido", pedido.id));
  };
}

export function onUpdatePedido(pedido, pedidoItems) {
  return async function(dispatch, getState) {
    var state = getState();

    if (!pedidoItems) pedidoItems = pedido.pedidoLinea;

    pedido = {
      ...pedido,
      pedidoLinea: pedidoItems,
      ...Finance.ordenTotal(pedidoItems)
    };

    var newById = { ...state.visitaTablet.pedidosById, [pedido.id]: pedido };

    return dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: newById,
        view: state.visitaTablet.view == "PEDIDO" ? "PEDIDO_SAVE" : "HOME",
        selectedPedidoId: state.visitaTablet.view == "PEDIDO" ? state.visitaTablet.selectedPedidoId : null
      }
    });
  };
}

export function onDeletePedido(pedidoId) {
  return async function(dispatch, getState) {
    var newById = { ...getState().visitaTablet.pedidosById };
    delete newById[pedidoId];

    dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: newById,
        view: "HOME",
        selectedPedidoId: null,
        selectedClienteId: null,
        selectedGrupoId: null
      }
    });
  };
}

export function onClienteSetSelected(type, id) {
  return function(dispatch, getState) {
    dispatch({
      type: "VISIT_SET_SELECTED",
      payload: {
        type: type,
        id: id
      }
    });

    if (id)
      dispatch(
        loadSmartView({
          item: getState().visitaTablet.clientesById[id],
          type: type,
          id: id
        })
      );

    if (type == "pedido") {
      var pedido = getVisitaById(getState(), "pedidos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "cliente",
          id: pedido.clienteId
        }
      });
      dispatch({
        type: "VISITA_UPDATE",
        payload: { view: "PEDIDO" }
      });
    }

    if (type == "departamento") {
      var departamentos = getVisitaById(getState(), "departamentos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "grupo",
          id: -1
        }
      });
    }
  };
}

export function onVisitSetSelected(type, id) {
  return function(dispatch, getState) {
    dispatch({
      type: "VISIT_SET_SELECTED",
      payload: {
        type: type,
        id: id
      }
    });

    if (type == "pedido") {
      var pedido = getVisitaById(getState(), "pedidos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "cliente",
          id: pedido.clienteId
        }
      });
      dispatch({
        type: "VISITA_UPDATE",
        payload: { view: "PEDIDO" }
      });
    }

    if (type == "departamento") {
      var departamentos = getVisitaById(getState(), "departamentos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "grupo",
          id: -1
        }
      });
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "VISITA_UPDATE": {
      Object.keys(action.payload).forEach(key => {
        if (
          [
            "priceListsById",
            "clientesById",
            "productosById",
            "recomendationsById",
            "transportesById"
          ].indexOf(key) > -1
        ) {
          window.localStorage.setItem(
            key.replace("ById", "_tablet"),
            JSON.stringify(Object.values(action.payload[key] || []))
          );
        }
      });
      return { ...state, ...action.payload };
    }

    case "VISIT_SET_SELECTED": {
      return {
        ...state,
        ["selected" +
        action.payload.type.charAt(0).toUpperCase() +
        action.payload.type.slice(1) +
        "Id"]: action.payload.id
      };
    }

    default: {
      return state;
    }
  }
};

export function getVisitaAll(state, type, clienteId) {
  var typeById = state.visitaTablet[`${type}ById`];
  var array = Object.values(typeById);

  if (array.length > 0 && array[0].hasOwnProperty("orden")) array.sort(sortBy("orden"));
  else if (array.length > 0 && array[0].hasOwnProperty("name")) array.sort(sortBy("name"));
  else if (array.length > 0 && array[0].hasOwnProperty("id")) array.sort(sortBy("id"));
  return array;
}

export function getVisitaById(state, type, id) {
  var typeById = state.visitaTablet[`${type}ById`];
  return typeById[id];
}

export function getVisitaSelected(state, type = "") {
  var selectedId = state.visitaTablet[`selected${type.charAt(0).toUpperCase()}${type.slice(1)}Id`];

  if (!selectedId) return null;
  return state.visitaTablet[`${type}sById`][selectedId];
}

export function getVisitaByIdFn(state) {
  return (type, id) => {
    return state.visitaTablet[`${type}sById`][id];
  };
}

export function getRecomendationsFn(state) {
  return clienteId => {
    var recomendations = state.visitaTablet.recomendationsById[clienteId] || [];
    return recomendations.map(item => {
      return { ...state.visitaTablet.productosById[item.productoId], ...item };
    });
  };
}

export function getCategoriasByDepartamentoFn(store) {
  return departamentoName => {
    var categorias = store.visitaTablet.categoriasByDepartamento[departamentoName] || [];
    var array = categorias.map(categoria => {
      categoria.grupos = store.visitaTablet.gruposByCategoria[categoria.id];
      if (categoria.grupos && categoria.grupos.length > 0) categoria.grupos.sort(sortBy("name"));
      return categoria;
    });
    if (array.length > 0) array.sort(sortBy("orden"));
    return array;
  };
}

function sortBy(field) {
  return (a, b) => {
    if (field == "orden") {
      if (!a.orden || a.orden == 0) a.orden = 100000;
      if (!b.orden || b.orden == 0) b.orden = 100000;
    }

    if (a[field] > b[field]) return 1;
    if (b[field] > a[field]) return -1;
    else return 0;
  };
}
