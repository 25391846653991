import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class FormDialog extends React.Component {
  state = {
    open: false,
    email: "",
    nombre: "",
    cedula: "",
    price: 0,
    loading: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChange = type => {
    return e => {
      return this.setState({ [type]: e.currentTarget.value, productoId: this.props.productoId });
    };
  };

  validate = type => {
    if (!this.state[type] || this.state[type].length < 2) {
      alert("Debe completar el campo " + type);
      return false;
    }
    return true;
  };

  onBuy = () => {
    if (!this.validate("nombre")) return;
    if (!this.validate("email")) return;
    if (!this.validate("cedula")) return;
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3300);
    return this.props.onBuy(this.state);
  };

  renderButton() {
    if (this.state.loading) return null;
    else
      return (
        <Fragment>
          <Button onClick={this.props.handleClose} color="default">
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: 20, marginRight: 10 }}
            onClick={this.onBuy}
            variant="contained"
            color="primary">
            Comprar
          </Button>
        </Fragment>
      );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"> eFactura @ {this.props.price}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingrese los datos segun estan registrados en su legislacion tributaria.
            </DialogContentText>
            <br />
            <TextField
              value={this.state.nombre}
              onChange={this.onChange("nombre")}
              autoFocus
              margin="dense"
              label="Nombre Fisico o Juridico"
              type="text"
              fullWidth
            />
            <TextField
              value={this.state.cedula}
              onChange={this.onChange("cedula")}
              margin="dense"
              label="Cedula o Numero Tributario"
              type="number"
              fullWidth
            />
            <TextField
              value={this.state.email}
              onChange={this.onChange("email")}
              margin="dense"
              label="Correo Electronico Principal"
              type="email"
              fullWidth
            />

            {this.state.loading ? <LinearProgress variant="query" /> : null}
          </DialogContent>

          <DialogActions>{this.renderButton()}</DialogActions>
        </Dialog>
      </div>
    );
  }
}
