import Ajax from "../helpers/ajax";

const init = { visible: false, loading: false, byType: {} };

export function loadAnalysis(metadataType, ids) {
  return function(dispatch, getState) {
    var state = getState();

    if (!ids || ids.length == 0) return;
    return Ajax.post(dispatch, `crm/${metadataType}/analizar`, { ids: ids })
      .then(data => {
        return dispatch(loadAnalysisSuccess(metadataType, data));
      })
      .catch(e => {
        console.log(e);
      });
  };
}

export function loadAnalysisSuccess(metadataType, data) {
  return {
    type: "DATA_ANALYSIS_SUCCESS",
    payload: {
      data: data,
      type: metadataType
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "DATA_ANALYSIS_SUCCESS": {
      var payload = action.payload;
      return {
        ...state,
        byType: { ...state.byType, ...state.byType[action.payload.type], [payload.type]: payload.data }
      };
    }
  }
  return state;
};

export function selectDataByType(state, type) {
  if (!state.analysis.byType[type]) return { metrics: [], item: {}, ventas: [], saldos: [], pagos: [] };
  return state.analysis.byType[type];
}
