import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import purple from "@material-ui/core/colors/deepPurple";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

import Buy from "./buy";

const styles = theme => ({
  card: {},
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#544ecc"
  },
  price: {
    backgroundColor: "#544ecc",
    fontSize: 30,
    color: "#fff",
    textAlign: "center",
    paddingLeft: 5,
    marginLeft: "40%"
  }
});

class RecipeReviewCard extends React.Component {
  state = { expanded: true, open: false, productoId: null, price: 0 };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  comprarClick = (productoId, price) => {
    return () => {
      this.setState({ open: true, productoId: productoId, price: price });
    };
  };

  closeComprar = e => {
    this.setState({ open: false, productoId: null, price: 0 });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Buy
          price={this.state.price}
          onBuy={this.props.onBuy}
          open={this.state.open}
          productoId={this.state.productoId}
          handleClose={this.closeComprar}
        />

        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe" className={classes.avatar}>
                {this.props.plan}
              </Avatar>
            }
            title={this.props.title}
            subheader={this.props.subtitle}
          />
          {this.props.image ? (
            <CardMedia className={classes.media} image={this.props.image} title="Paella dish" />
          ) : null}
          <CardContent>
            <Typography style={{ marginBottom: 30 }} component="p">
              {this.props.description}
            </Typography>
            <Typography className={classes.price} variant="caption" />
            <Button
              onClick={this.comprarClick(this.props.productoId, this.props.price)}
              fullWidth
              color="primary"
              variant="contained">
              Comprar a {this.props.price}
            </Button>
          </CardContent>

          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ position: "relative" }}>
              <SwitchesGroup checks={this.props.checks} />
              <Typography paragraph>
                Todos nuestros planes incluyen 99.999% de disponibilidad, distribución global y backups cada 5
                minutos.
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RecipeReviewCard);

class SwitchesGroup extends React.Component {
  state = {
    gilad: true,
    jason: false,
    antoine: true
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Features</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[0]} value="gilad" />}
            label="Multiples Usuarios"
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[1]} value="gilad" />}
            label="Ordenes y Pedidos"
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[2]} value="gilad" />}
            label="Precios y Descuentos por Cliente"
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[3]} value="gilad" />}
            label="Vender a Credito"
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[4]} value="gilad" />}
            label="Productos e Inventarios"
          />
          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[5]} value="gilad" />}
            label="Cuentas por Pagar"
          />

          <FormControlLabel
            control={<Switch color="primary" checked={this.props.checks[6]} value="gilad" />}
            label="Contabilidad e Impuestos"
          />
        </FormGroup>
        <FormHelperText>Otros</FormHelperText>
      </FormControl>
    );
  }
}
