import React from "react";
import PropTypes from "prop-types";
import { shapes } from "react-data-grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { onFilter, selectFilterByKey, selectGroupByKey, changeGroup } from "redux/reducers/table";
import GridOff from "@material-ui/icons/GridOff";
import { connect } from "react-redux";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Blue from "@material-ui/core/colors/blue";
import style from "./FilterStyles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Typography from "@material-ui/core/Typography";

const { ExcelColumn } = shapes;

const styles = {
  ...style,
  formControl: {
    ...style.formControl,
    margin: 5
  },
  tabContainer: {
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    border: "1px solid #dedede"
  }
};

const mapStateToProps = (store, props) => {
  return {
    filter: selectFilterByKey(store, props.column.metadataType, props.column.key),
    grouped: selectGroupByKey(store, props.column.metadataType, props.column.key)
  };
};

class DateFilter extends React.Component {
  state = {
    open: false,
    textValue: "",
    showRange: false,
    start: moment(),
    end: moment(),
    fixed: "HOY",
    view: 0
  };

  handleClickOpen = () => {
    if (this.props.column.groupOnly) return;

    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRemove = () => {
    this.setState({ open: false, start: moment(), end: moment(), textValue: "" });
    this.props.onChange({
      [this.props.column.key]: {
        metadataType: this.props.metadataType,
        column: this.props.column,
        textValue: "",
        filterValue: null
      }
    });
  };

  handleFilter = e => {
    if (!this.state.end || !this.state.start) return;
    var dateStart = this.state.start;
    var dateEnd = this.state.end;

    this.setState({ open: false });

    var key = this.props.column.key;
    var textValue = ";";
    if (this.state.view == 0) {
      textValue = this.state.fixed;
      filterValue = [`${this.props.metadataType}.${key}`, "FIXED", this.state.fixed];
    } else if (this.state.view == 2) {
      var textValue = `${dateStart.format("MM/DD")} <> ${dateEnd.format("MM/DD")}`;

      var filterValue = [
        `${this.props.metadataType}.${key}`,
        "BETWEEN",
        [this.state.start.format("YYYY-MM-DD  00:00:00"), this.state.end.format("YYYY-MM-DD 23:59:59")]
      ];
    } else {
      textValue = `${dateStart.format("MM/DD/YYYY")}`;
      filterValue = [
        `${this.props.metadataType}.${key}`,
        "BETWEEN",
        [this.state.start.format("YYYY-MM-DD  00:00:00"), this.state.start.format("YYYY-MM-DD  23:59:59")]
      ];
    }
    if (this.props.column.select) filterValue[0] = key;

    this.props.onChange({
      [this.props.column.key]: {
        metadataType: this.props.metadataType,
        column: this.props.column,
        textValue: textValue,
        filterValue: filterValue
      }
    });
  };

  onChangeStart = e => {
    var value = e.currentTarget.value;
    this.setState({ start: moment(value), fixed: null });
  };

  onChangeEnd = e => {
    var value = e.currentTarget.value;
    this.setState({ end: moment(value) });
  };

  onChangeFixed = e => {
    var value = e.target.value;
    this.setState({ fixed: value });
  };

  onClick = () => {
    this.props.onChange({
      column: this.props.column,
      value: "GROUP"
    });
  };

  renderIconButton() {
    const { classes } = this.props;
    var icon;
    if (!this.props.grouped) icon = <ViewAgenda classes={{ root: classes.svg }} />;
    else icon = <GridOff />;

    return (
      <IconButton
        className={classes.iconButton}
        color={this.props.grouped ? "primary" : "default"}
        size="sm"
        onClick={this.onClick}>
        {icon}
      </IconButton>
    );
    return null;
  }

  handleChange = (event, value) => {
    this.setState({ view: value });
  };

  renderSingleDateSelection() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <TextField
          id="dateStart"
          label="Fecha"
          type="date"
          value={this.state.start.format("YYYY-MM-DD")}
          onChange={this.onChangeStart}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
      </FormControl>
    );
  }

  renderLiteralDateSelection() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel>Comparador</InputLabel>
        <Select defaultValue={"HOY"} value={this.state.fixed} onChange={this.onChangeFixed}>
          <MenuItem value={"HOY"}>HOY</MenuItem>
          <MenuItem value={"AYER"}>AYER</MenuItem>
          <MenuItem value={"ESTA SEMANA"}>ESTA SEMANA</MenuItem>
          <MenuItem value={"ESTE MES"}>ESTE MES</MenuItem>
          <MenuItem value={"ESTE AÑOF"}>PERIODO FISCAL</MenuItem>
          <MenuItem value={"ULTIMO MES"}>ULTIMO MES</MenuItem>
          <MenuItem value={"ULTIMO AÑOF"}>ULTIMO PERIODO FISCAL</MenuItem>
        </Select>
      </FormControl>
    );
  }

  renderRangeDateSelection() {
    const { classes } = this.props;

    return (
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            id="dateStart"
            label="Inicio"
            fullWidth
            type="date"
            value={this.state.start.format("YYYY-MM-DD")}
            onChange={this.onChangeStart}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="dateEnd"
            fullWidth
            label="Fin"
            type="date"
            onChange={this.onChangeEnd}
            value={this.state.end.format("YYYY-MM-DD")}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
      </div>
    );
  }

  renderDateSelection() {
    if (this.state.view == 0) return this.renderLiteralDateSelection();
    if (this.state.view == 2) return this.renderRangeDateSelection();
    else return this.renderSingleDateSelection();
  }

  render() {
    const { classes } = this.props;

    let columnStyle = { maxWidth: "100%" };

    let filterValue = this.props.filter || { textValue: "" };

    return (
      <div style={columnStyle}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={10}>
            <TextField
              classes={{
                root: classes.inputRoot
              }}
              value={filterValue.textValue}
              InputProps={{
                classes: {
                  input: classes.input,
                  root: classes.inputRoot,
                  underline: classes.underline
                }
              }}
              className={classes.textInput}
              id="input-with-icon-grid"
              onClick={this.handleClickOpen}
            />
          </Grid>

          <Grid item xs={2}>
            {this.renderIconButton()}
          </Grid>
        </Grid>

        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Filtro de Fecha</DialogTitle>
          <DialogContent>
            <AppBar position="static">
              <Tabs value={this.state.view} onChange={this.handleChange}>
                <Tab label="Literal" />
                <Tab label="Por Fecha" />
                <Tab label="Por Rango" />
              </Tabs>
            </AppBar>

            <div className={classes.tabContainer}>{this.renderDateSelection()}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRemove} color="secondary">
              Eliminar
            </Button>
            <Button onClick={this.handleFilter} color="primary">
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn)
};

const DateFilterWithStyles = withStyles(styles)(connect(mapStateToProps)(DateFilter));

export default function(metadataType) {
  return class extends React.Component {
    render() {
      return <DateFilterWithStyles metadataType={metadataType} {...this.props} />;
    }
  };
}
