import Ajax from "../helpers/ajax";
import { push } from "connected-react-router";
import * as Sentry from "@sentry/browser";
import { alert } from "./header";

const init = {
  view: "",
  user: {},
  users: [],
  account: "",
  accounts: [],
  authenticated: true
};

export function onLogout() {
  window.localStorage.setItem("authorization", null);
  localStorage.clear();
  window.location.reload();
}

export function loadUsers() {
  return function(dispatch, getState) {
    return Ajax.post(dispatch, `owner/query`, { filters: [["activo", "=", true]] }).then(function(result) {
      dispatch(onLoadUsersSuccess(result));
      return result;
    });
  };
}

export function onWhoAmISuccess(result) {
  return {
    type: "USER_WHOAMI_SUCCESS",
    payload: result
  };
}

export function onLoadUsersSuccess(result) {
  return {
    type: "USERS_LOAD_SUCCESS",
    payload: result
  };
}

export function onSelectApi(api) {
  window.localStorage.setItem("account", api);
  return {
    type: "USER_SET_ACCOUNT",
    payload: api
  };
}

export function onLoadAccount() {
  return function(dispatch, getState) {
    return Ajax.login(dispatch, `config/all`, {}).then(function(result) {
      dispatch(onLoadAccountSuccess(result));
      return result;
    });
  };
}

export function onLoadAccountSuccess(accounts) {
  return {
    type: "ACCOUNT_LOAD_SUCCESS",
    payload: accounts
  };
}

export function onLogin(email) {
  return function(dispatch, getState) {
    return Ajax.login(dispatch, `login/login`, { email: email }).then(function(result) {
      window.localStorage.setItem("email", email);
      window.localStorage.setItem("account", result.account);
      dispatch(onLoginSuccess(result));
      return result;
    });
  };
}

export function onUserExists(email) {
  return function(dispatch, getState) {
    return Ajax.login(dispatch, `login/userExists`, { email: email });
  };
}

export function onCode(email, code, account) {
  return function(dispatch, getState) {
    return Ajax.login(dispatch, `login/code`, { code: code, email: email }).then(function(result) {
      window.localStorage.setItem("authorization", result.authorization);
      dispatch(onCodeSuccess(result));
      window.location.reload();
      return result;
    });
  };
}

export function onQueryStringCode(authorization, account) {
  return function(dispatch, getState) {
    window.history.replaceState({}, document.title, "/" + "home");

    window.localStorage.setItem("authorization", authorization);
    window.localStorage.setItem("account", account);
    dispatch(onCodeSuccess());

    window.location.reload();
    return true;
  };
}

export function onLoginAs(id) {
  return function(dispatch, getState) {
    return Ajax.post(dispatch, `login/loginAs`, { id: id }).then(function(result) {
      dispatch(alert("Para regresar use el codigo: " + result.code, "success"));
      setTimeout(() => {
        window.localStorage.setItem("authorization", result.authorization);
        window.location.reload();
      }, 2500);

      return result;
    });
  };
}

export function onLoginSuccess(type, result) {
  return {
    type: "USER_LOGIN_SUCCESS"
  };
}

export function onCodeSuccess(type, result) {
  return {
    type: "USER_CODE_SUCCESS"
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "USER_WHOAMI_SUCCESS": {
      return { ...state, user: action.payload.user };
    }
    case "USERS_LOAD_SUCCESS": {
      return { ...state, users: action.payload };
    }

    case "AJAX_401": {
      return { ...state, view: "EMAIL", authenticated: false };
    }

    case "USER_LOGIN_SUCCESS": {
      return { ...state, view: "CODE" };
    }

    case "USER_CODE_SUCCESS": {
      return { ...state, view: null };
    }

    case "USER_SET_ACCOUNT": {
      return { ...state, account: action.payload };
    }

    case "ACCOUNT_LOAD_SUCCESS": {
      return { ...state, accounts: action.payload };
    }
  }

  return state;
};
