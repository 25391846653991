import React, { Fragment } from "react";
import PropTypes, { nominalTypeHack } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { alert } from "redux/reducers/header";
import StarIcon from "@material-ui/icons/Star";
import { IconButton } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import amber from "@material-ui/core/colors/amber";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {},
  inline: {
    display: "inline",
    fontSize: 11
  },

  button: {
    margin: 7
  },
  textfield: {
    margin: "0px 10px"
  },
  icon: {
    margin: theme.spacing(2)
  },
  iconSelected: {
    color: amber[500]
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "90%",
    fontSize: 10
  }
});

class AlignItemsList extends React.Component {
  state = {
    message: "",
    creating: false
  };

  componentDidMount() {
    if (this.props.onMount) this.props.onMount();
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  onCreateNota = () => {
    this.setState({ creating: true });
  };

  onSaveNota = () => {
    if (!this.state.message || this.state.message.length < 1)
      return this.props.dispatch(alert("Ingreser su nota antes de guardaerlo "));
    this.props
      .onCreate({
        message: this.state.message
      })
      .then(() => {
        this.onCancelNota();
      });
  };

  onCancelNota = () => {
    this.setState({ creating: false, message: "" });
  };

  renderCreate() {
    const { classes } = this.props;
    if (!this.props.onCreate) return null;
    return (
      <div>
        {this.state.message.length > 0 ? (
          <div>
            <TextField
              autoFocus={true}
              placeholder="Escriba su nota"
              className={classes.textField}
              value={this.state.message}
              multiline
              rowsMax={8}
              rows={4}
              onChange={this.handleChange("message")}
            />
            <Button
              onClick={this.onCancelNota}
              variant="contained"
              color="default"
              className={classes.button}>
              Cancelar
            </Button>
            <Button onClick={this.onSaveNota} variant="contained" color="primary" className={classes.button}>
              Guardar
            </Button>
          </div>
        ) : (
          <TextField
            placeholder="Escriba su nota"
            className={classes.textField}
            variant="outlined"
            value={this.state.message}
            onChange={this.handleChange("message")}
          />
        )}
      </div>
    );
  }

  renderList() {
    const { classes } = this.props;
    var props = this.props;

    var items = null;
    if (props.items.length > 0) {
      items = props.items.map(item => {
        return (
          <ListItem style={{ paddingLeft: 0 }} alignItems="flex-start">
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  this.props.favoriteItem({ typeid: item.typeid, favorite: !item.favorite ? true : false });
                }}>
                <StarIcon className={item.favorite ? classes.iconSelected : ""} />
              </IconButton>
            </ListItemSecondaryAction>
            <ListItemText
              primary={this.props.renderer(item)}
              primaryTypographyProps={{ style: { fontSize: 11 } }}
              secondary={
                <Fragment>
                  {item.ownerName}
                  <Typography
                    component="div"
                    className={classes.inline}
                    variant="caption"
                    color="textSecondary">
                    {moment(item.createdAt).format("DD MMM")}{" "}
                    <span style={{ fontSize: 9 }}>`{moment(item.createdAt).format("YY")}</span>
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
        );
      });
    }

    return (
      <Fragment>
        <List>{items}</List>
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} className={classes.root}>
        <Typography variant="overline">Notas</Typography>
        {this.renderCreate()}
        {this.renderList()}
      </div>
    );
  }
}

AlignItemsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlignItemsList);
