import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onReloadData, onSave, onAction } from "redux/reducers/data";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { changeLimit, selectLimit, selectSingleGroup } from "redux/reducers/table";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import numeral from "numeral";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import { simpleApi } from "redux/reducers/api";
import { alert } from "redux/reducers/header";
import CircularProgress from "@material-ui/core/CircularProgress";
import Reporte from "components/Report";

import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 300
    }
  }
};
export const styles = theme => ({
  reportHeader: {
    borderBottom: "2px solid #333",
    marginBottom: 12
  },
  root: {
    backgroundColor: "#fff"
  },
  main: {
    margin: theme.spacing(5),
    marginTop: 0,
    marginBottom: 0
  },
  toolbar: {
    margin: theme.spacing(4),
    backgroundColor: "#fff",
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing(1)
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  table: {
    marginTop: 100
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogRoot: {
    marginTop: 100
  },
  headerCell: {
    backgroundColor: "#333"
  },
  dialogTitle: {
    marginBottom: theme.spacing(1)
  },
  formControl: {
    width: "100%",
    marginBottom: 25
  },
  chip: {
    marginRight: 5
  }
});

class PrintView extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }

  state = {
    selectedItems: [],
    dialogPrint: false,
    printing: false,
    printView: false,
    printColumns: [],
    groupBy: [],
    sumFields: [],
    sortFields: [],
    filters: [],
    name: "",
    showLines: true
  };

  componentDidMount() {
    this.loadReport(this.props);
  }

  componentWillReceiveProps(nextProps) {}

  loadReport(props) {
    var fields = [];
    var sums = [];
    var filters = [];
    var groups = [];
    var sort = [];
    var name = "";
    if (props.baseReport) {
      fields = props.baseReport.fields;
      filters = (props.filters || []).concat(props.baseReport.filters);
      groups = props.baseReport.groups;
      sums = props.baseReport.sums;
      sort = props.baseReport.sort;
      name = props.baseReport.name;
    } else {
      name = `Reporte ${props.metadata.title}`;
      fields = [];
      groups = [];
      sums = [];
      sort = [];
      filters = props.filters || [];
    }

    this.setState({
      name: name,
      printColumns: fields || [],
      groupBy: groups || [],
      sumFields: sums || [],
      sortFields: sort || [],
      filters: filters || []
    });
  }

  onClosePrint = e => {
    this.setState({ printView: false });
    this.props.onClosePrint();
  };

  toCsv = () => {
    var props = this.props;
    var objectToCSVRow = function(item, columns) {
      var dataArray = [];
      columns.forEach(columnKey => {
        var column = props.metadata.properties[columnKey];

        if (!column.select && columnKey.indexOf("Id") > -1) columnKey = "__" + columnKey;

        var value = item[columnKey];
        if (!value && value != false) value = "";
        var innerValue = value.toString();
        var result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
        dataArray.push(result);
      });
      return dataArray.join(",");
    };

    let arrayOfObjects = this.props.dataRows;

    if (!arrayOfObjects.length) {
      return;
    }

    var csvContent = [];

    // headers
    csvContent.push(this.state.printColumns.join(","));

    arrayOfObjects.forEach(item => {
      var newContent = objectToCSVRow(item, this.state.printColumns);
      csvContent.push(newContent);
    });

    var fileName = this.props.metadata.key + ".csv";
    var data = csvContent.join("\r\n");
    var csvData = data;
    var blob = new Blob([csvData], {
      type: "application/csv;charset=utf-8;"
    });

    if (window.navigator.msSaveBlob) {
      // FOR IE BROWSER
      navigator.msSaveBlob(blob, fileName);
    } else {
      // FOR OTHER BROWSERS
      var link = document.createElement("a");
      var csvUrl = URL.createObjectURL(blob);
      link.href = csvUrl;
      link.style = "visibility:hidden";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  onPrint = e => {
    this.setState({ dialogPrint: true });
  };

  onPrintFromModal = e => {
    this.setState({ dialogPrint: false, printView: true, loading: true });
    this.props
      .dispatch(
        onLoadData(this.props.metadata.key, this.state.filters, {
          limitless: true,
          order: this.state.sortFields.join(",")
        })
      )
      .then(() => {
        this.setState({ loading: false });
      });
  };

  onSaveFromModal = e => {
    this.setState({ loading: true });

    this.props
      .dispatch(
        simpleApi("reporte/create", {
          table: this.props.metadata.key,
          filters: this.state.filters,
          sums: this.state.sumFields,
          sort: this.state.sortFields,
          groups: this.state.groupBy,
          fields: this.state.printColumns,
          name: this.state.name
        })
      )
      .then(() => {
        this.props.dispatch(alert("Se guardo el reporte", "success"));
        this.props.dispatch(
          onLoadData(this.props.metadata.key, this.state.filters, {
            limitless: true,
            order: this.state.sortFields.join(",")
          })
        );
      })
      .then(() => {
        this.setState({ dialogPrint: false, printView: true, loading: false });
        return true;
      });
  };

  onPrintFromView = e => {
    //window.print();

    printDiv(this.table.current);
  };

  onFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      printColumns: value
    });
  };

  onNameChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      name: value
    });
  };

  onGroupFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      groupBy: value
    });
  };

  onSumFieldChange = e => {
    var value = e.value || e.target.value;

    this.setState({
      sumFields: value
    });
  };

  onSortFieldChange = e => {
    var value = e.value || e.target.value;
    if (value.length > 1) value = value[value.length - 1];

    this.setState({
      sortFields: value
    });
  };

  onMostrarLineasChange = e => {
    this.setState({
      showLines: e.target.checked
    });
  };

  getGroupTableData() {
    var groups = {};
    var columnKeys = this.state.printColumns;
    var sumFields = this.state.sumFields;
    var totalFields = {};

    this.props.dataRows.forEach(row => {
      var groupKey = row[this.state.groupBy[0]];

      var group = groups[groupKey];
      if (!group) group = { rows: [], name: groupKey, sums: {} };
      columnKeys.forEach(columnKey => {
        if (sumFields.indexOf(columnKey) > -1) {
          if (!group.sums[columnKey]) group.sums[columnKey] = 0;
          group.sums[columnKey] += row[columnKey];
          if (!totalFields[columnKey]) totalFields[columnKey] = 0;
          totalFields[columnKey] += row[columnKey];
        }
      });
      group.rows.push(row);
      groups[groupKey] = group;
    });

    var rows = [];
    Object.keys(groups).forEach(groupKey => {
      var groupRow = groups[groupKey];
      rows.push(
        columnKeys.map((key, index) => {
          if (index == 0) return groupRow.name;
          else return "";
        })
      );

      groupRow.rows.map(row => {
        rows.push(
          columnKeys.map(key => {
            var column = this.props.metadata.properties[key];
            if (column.element == "autocomplete") key = "__" + key;

            if (column.type == "number") return numeral(row[key]).format("0,0.00");

            return row[key];
          })
        );
      });

      rows.push(
        columnKeys.map((key, index) => {
          if (groupRow.sums[key]) return numeral(groupRow.sums[key]).format("0,0.00");
          else return "";
        })
      );
    });

    rows.push(
      columnKeys.map((key, index) => {
        if (totalFields[key]) return numeral(totalFields[key]).format("0,0.00");
        else return "";
      })
    );

    return rows;
  }

  getTableData() {
    if (this.state.groupBy.length > 0) return this.getGroupTableData();

    return this.props.dataRows.map(row => {
      var keys = this.state.printColumns;
      return keys.map(key => {
        var column = this.props.metadata.properties[key];
        if (column.element == "autocomplete") key = "__" + key;
        return row[key];
      });
    });
  }

  renderPrintView() {
    const { classes } = this.props;
    var title = this.props.baseReport ? this.props.baseReport.name : null;

    if (!this.state.printView) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        fullScreen={true}
        open={this.state.printView}
        onClose={this.onClosePrint}
        aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography className={classes.grow} variant="title" color="inherit">
              {`${this.props.metadata.title} ${title || ""}`} 
            </Typography>
            <Button onClick={this.onClosePrint} color="inherit">
              Cancelar
            </Button>
            <Button onClick={this.onPrintFromView} color="inherit" autoFocus>
              Imprimir
            </Button>
            <Button onClick={this.toCsv} color="inherit" autoFocus>
              Excel
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent classes={{ root: classes.dialogRoot }}>
          <div ref={this.table}>
            <Grid container className={classes.reportHeader}>
              <Grid item xs="9">
                <Typography variant="subtitle1" gutterBottom>
                  {this.props.metadata.namespace} - {title || this.props.metadata.title || this.props.metadata.key}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Preparado Por: {this.props.user.name}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Autorizado: {this.props.user.id}</div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                  <div>{this.props.user.account}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Fecha: {moment().format("DD MMM-YYYY")}</div>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <div>Hora: {moment().format("HH:MM")}</div>
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="overline" gutterBottom>
              Código Verificador: {parseInt(Math.random() * 100000)}
            </Typography>

            {this.state.loading ? <CircularProgress className={classes.progress} color="secondary" /> : null}

            <Reporte
              showLines={this.state.showLines}
              metadata={this.props.metadata}
              rows={this.props.dataRows}
              {...this.state}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderSelectColumsForPrint() {
    const { classes } = this.props;
    if (!this.props.dialogOpen) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        fullWidth
        maxWidth="md"
        open={this.props.dialogOpen}
        onClose={this.props.onClosePrint}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText className={classes.dialogTitle}>Reportes para Imprimir</DialogContentText>

          <FormControl className={classes.formControl}>
            <TextField
              label="Nombre del Reporte"
              className={classes.textField}
              value={this.state.name}
              onChange={this.onNameChange}
              margin="normal"
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-chip">Escoja los campos</InputLabel>
            <Select
              multiple
              fullWidth
              value={this.state.printColumns}
              onChange={this.onFieldChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(item => (
                    <Chip key={item} label={item} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}>
              {this.props.metadata.table.fields.map(item => {
                var column = this.props.metadata.properties[item];

                return (
                  <MenuItem key={item} value={item}>
                    {column.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Cuales campos agrupamos</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={this.state.groupBy}
                  onChange={this.onGroupFieldChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(item => (
                        <Chip key={item} label={item} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}>
                  {this.props.metadata.table.fields
                    .filter(item => {
                      return this.state.printColumns.indexOf(item) > -1;
                    })
                    .map(item => {
                      var column = this.props.metadata.properties[item];

                      if (column.type == "number") return null;
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Cuales campos sumamos</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={this.state.sumFields}
                  onChange={this.onSumFieldChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(item => (
                        <Chip key={item} label={item} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}>
                  {this.props.metadata.table.fields
                    .filter(item => {
                      return this.state.printColumns.indexOf(item) > -1;
                    })
                    .map(item => {
                      var column = this.props.metadata.properties[item];

                      if (column.type != "number" && column.type != "integer") return null;

                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-chip">Alguna preferencia de Orden?</InputLabel>
            <Select
              multiple
              fullWidth
              value={this.state.sortFields}
              onChange={this.onSortFieldChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => {
                if (!selected) selected = [];
                return (
                  <div className={classes.chips}>
                    {selected.map(item => (
                      <Chip key={item} label={item} className={classes.chip} />
                    ))}
                  </div>
                );
              }}
              MenuProps={MenuProps}>
              {this.props.metadata.table.fields
                .filter(item => {
                  return this.state.printColumns.indexOf(item) > -1;
                })
                .map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Typography variant="caption">Filtros</Typography>
          {this.state.filters.map(filter => {
            return (
              <Chip
                key={filter[0]}
                label={`${filter[0].split(".")[0]} ${filter[1]} ${filter[2]}`}
                className={classes.chip}
              />
            );
          })}

          <FormControl component="fieldset">
            <FormLabel component="legend">Mostrar Lineas</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={this.state.showLines} onChange={this.onMostrarLineasChange} />}
                label="Mostrar Lineas"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.onPrintFromModal} color="primary">
            Preparar Reporte
          </Button>
          <Button variant="contained" onClick={this.onSaveFromModal} color="primary">
            Guardar Reporte
          </Button>
          <Button onClick={this.props.onClosePrint} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, theme } = this.props;
    if (!this.props.metadata) return null;

    return (
      <div className={classes.root}>
        <div className={classes.main}>
          {this.renderSelectColumsForPrint()}
          {this.renderPrintView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    grouping: selectSingleGroup(store),
    user: store.user.user,
    account: store.user.account
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PrintView));

function printDiv(printElement) {
  var printWindow = window.open("", "PRINT");
  printWindow.document.write(document.documentElement.innerHTML);
  setTimeout(() => {
    // Needed for large documents
    printWindow.document.body.style.margin = "0 0";
    printWindow.document.body.innerHTML = printElement.outerHTML;
    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/
    printWindow.print();
    printWindow.close();
  }, 1000);
}
