import React from "react";

class LinkFormatter extends React.Component {
  getValue = () => {
    var value = this.props.value;

    if (!value || value.length == 0) return null;

    if (this.props.column.prefix) value = this.props.column.prefix + value;

    return value;
  };

  onClick = e => {
    const value = this.getValue();
    e.preventDefault();
    e.stopPropagation();
    window.open(value, "_blank");
  };

  render() {
    const value = this.getValue();
    if (!value || value.length == 0) return null;

    var width = this.props.column.width || 100;
    return <a onClick={this.onClick}>{value.substring(0, width / 9)}...</a>;
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <LinkFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
