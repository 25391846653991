import { combineReducers } from "redux";
import user from "./user";
import table from "./table";
import form from "./form";
import header from "./header";
import metadata from "./metadata";
import data from "./data";
import api from "./api";
import feed from "./feed";
import analysis from "./analysis";
import socket from "./socket";
import visitaTablet from "./custom/visitaTablet";
import pedidosTablet from "./custom/pedidosTablet";

import catalogo from "./custom/catalogo";
import electronico from "./electronico";
import alerts from "./ui/alerts";
import smartView from "./ui/smartView";
import fastCreate from "./ui/fastCreate";
import task from "./ui/task";
import note from "./ui/note";
import contacto from "./ui/contacto";
import metric from "./ui/metric";
import relatedList from "./ui/relatedList";
import audit from "./ui/audit";
import { connectRouter } from "connected-react-router";

//import adminArea from './modules/adminArea'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    table,
    form,
    header,
    alerts,
    visitaTablet,
    pedidosTablet,
    catalogo,
    metadata,
    data,
    api,
    feed,
    socket,
    electronico,
    analysis,
    smartView,
    fastCreate,
    note,
    task,
    metric,
    contacto,
    relatedList,
    audit
  });
