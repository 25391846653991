import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { connect } from "react-redux";

import Page from "./page";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { onLogin, onCode, onQueryStringCode, onSelectApi, onLoadAccount } from "redux/reducers/user";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    },
    formControl: {
      marginBottom: theme.spacing(1)
    },
    root: {},
    textField: {
      width: "100%"
    }
  };
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const mapStateToProps = (store, props) => {
  return {
    user: store.user
  };
};

class FullScreenDialog extends React.Component {
  state = {
    email: window.localStorage.getItem("email") || "",
    code: "",
    error: null
  };

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get("code");
    const account = urlParams.get("account");
    if (authToken) this.props.dispatch(onQueryStringCode(authToken, account));
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog fullScreen open={this.props.user.view != ""} TransitionComponent={Transition}>
          <Page {...this.props} />
        </Dialog>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FullScreenDialog));
