import Ajax from "../helpers/ajax";
import { alert } from "./header";

const init = { byFormId: {} };

export function simpleApi(route, body, showAlert) {
  return function(dispatch, getState) {
    var state = getState();

    var responsePromise = Ajax.post(dispatch, `${route}`, body);

    if (!showAlert) return responsePromise;
    else
      return responsePromise
        .then(response => {
          dispatch(alert("La solicitud se envio con exito", "success", true));
          return response;
        })
        .catch(err => {
          console.log(err);
          dispatch(alert("Ocurrio un error." + err.message));
        });
  };
}

export default (state = init, action) => {
  //switch (action.type) {
  //}
  return state;
};
