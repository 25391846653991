import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

class TextFormatter extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderModal() {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">{this.props.column.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{this.props.value}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    var index = 0;
    var lastChar = "0";
    var value = this.props.value;
    if (!value) return null;
    var width = this.props.column.width || 100;
    return (
      <div>
        {this.renderModal()}
        <div
          onClick={() => {
            this.setState({ open: true });
          }}>
          {value.substring(0, width / 9)}...
        </div>
      </div>
    );
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <TextFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
