import React, { Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { simpleApi } from "redux/reducers/api";

import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Link } from "@material-ui/core";
import { showFastCreate } from "redux/reducers/ui/fastCreate";

const styles = theme => {
  return {
    link: {
      cursor: "pointer",
      color: theme.palette.primary.dark,
      textTransform: "capitalize"
    },

    table: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 2000,
      minHeight: 550,
      overflow: "scroll",
      marginBottom: 20
    }
  };
};

class PureForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadList(this.props.relatedField);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.item.id != nextProps.item.id) this.props.loadList(this.props.relatedField);
  }

  onCreateFromRelatedList = relatedField => {
    this.props.dispatch(
      showFastCreate(
        {
          metadataType: relatedField.table,
          operation: "create",

          onCreateComplete: createdObject => {
            this.props.loadList(this.props.relatedField, true);
          }
        },
        { name: "", clienteId: this.props.item.id }
      )
    );
  };

  render() {
    const { classes, relatedField, listValues } = this.props;
    var actions = relatedField.actions || [];
    if (!listValues || listValues.length == 0)
      return (
        <div className={classes.table}>
          <Typography className={classes.title} variant="caption">
            No hay datos,{" "}
            {actions.indexOf("Crear") > -1 ? (
              <Link
                onClick={() => {
                  this.onCreateFromRelatedList(relatedField);
                }}>
                Nuevo?
              </Link>
            ) : null}
          </Typography>
        </div>
      );
    var fields = relatedField.fields || Object.keys(listValues[0]);
    var value = null;
    if (Array.isArray(listValues)) {
      var innerValues = [];
      value = (
        <div className={classes.table}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                {fields.map(field => {
                  return <TableCell key={field}>{field}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.indexOf("Crear") > -1 ? (
                <TableRow>
                  {fields.map((field, index) => {
                    return (
                      <TableCell component="th" scope="row">
                        {index == 0 ? (
                          <Link
                            onClick={() => {
                              this.onCreateFromRelatedList(relatedField);
                            }}>
                            Nuevo?
                          </Link>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
              {listValues.map(item => {
                return (
                  <TableRow key={item.id}>
                    {fields.map(field => {
                      var value = item[field];
                      if (field.indexOf("__") > -1 && field.indexOf("Id") > -1) {
                        value = (
                          <a
                            className={classes.link}
                            onClick={() => {
                              this.props.showSubView(
                                field.replace("__", "").replace("Id", ""),
                                item[field.replace("__", "")]
                              );
                            }}>
                            {item[field]}
                          </a>
                        );
                      } else if (field == "id") {
                        value = (
                          <a
                            className={classes.link}
                            onClick={() => {
                              this.props.showSubView(relatedField.table, item[field]);
                            }}>
                            {item[field]}
                          </a>
                        );
                      } else if (field.indexOf("Id") > -1) {
                        value = (
                          <a
                            className={classes.link}
                            onClick={() => {
                              this.props.showSubView(field.replace("Id", ""), item[field]);
                            }}>
                            {item[field]}
                          </a>
                        );
                      }

                      return (
                        <TableCell component="th" scope="row">
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
    return value;
  }
}

export default withStyles(styles, { withTheme: true })(PureForm);
