import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#ddd"
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#8884d8"
  }
})(LinearProgress);

const styles = {
  card: {
    width: "100%",
    marginBottom: 12,
    position: "relative"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

const maps = {
  NIVEL6013: [20, 60, 120, 360, 560, 960, 1920, 5000, 8000]
};

function SimpleCard(props) {
  const { classes } = props;

  var metrics = props.metrics;

  var keyMetric = metrics.filter(loopMetric => {
    return props.metric.keyMetric == loopMetric.key;
  })[0];

  var sumFields = keyMetric.groupBy.sumFields;
  if (sumFields.indexOf(".") > -1) {
    sumFields = sumFields.split(".")[1];
  }

  var value =
    props.values[keyMetric.key] && props.values[keyMetric.key][0]
      ? props.values[keyMetric.key][0][sumFields]
      : 0;

  if (value && value > 0)
    maps[props.metric.map].reverse().forEach(mapItem => {
      if (value <= mapItem) value = mapItem;
    });

  return (
    <Card className={classes.card}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={maps[props.metric.map].indexOf(value) / maps[props.metric.map].length}
      />
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.metric.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {value}
        </Typography>

        <Typography style={{ fontSize: "0.45rem" }} color="textSecondary" variant="caption">
          {props.metric.help}
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
