import React, { Suspense } from "react";
import queryString from "query-string";
import Toolbar from "../base/toolbar";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { makeTable } from "views/base/table";
import { makeEdit } from "views/base/edit";
import { makeCreate } from "views/base/create";
import Ajax from "../../redux/helpers/ajax";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Feed from "components/Feed";
import { subscribeToDefaultChannels } from "redux/reducers/socket";
import FastCreate from "./fastCreate";
import SmartView from "./smartView";
import Home from "./home";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100vw"
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  demo: {
    paddingTop: theme.spacing(4),
    padding: "10px"
  },
  actions: {
    marginTop: theme.spacing(4)
  },

  notificiationPaper: {
    padding: 0,
    margin: 30
  }
});

class BaseRoute extends React.Component {
  componentDidMount() {
    //this.props.dispatch(subscribeToDefaultChannels());
  }

  getComponentFromPath = (metadataType, operation, id) => {
    var Route = this.props.menu.routes[metadataType] || {};
    var operations = Route.operations;

    if (!Route || !operations || !operations[operation]) {
      if (operation == "table") return makeTable();
      if (operation == "edit") return makeEdit();
      if (operation == "create") return makeCreate();
    }
    return operations[operation];
  };

  getComponent(metadataType, operation, props) {
    var Route = this.props.menu.routes[props.match.params.metadataType] || {};

    if (!metadataType) metadataType = this.props.baseMetadataType;
    var Operation;
    var parsedQuery = queryString.parse(props.location.search);

    var formId = parsedQuery.formId || parseInt(Math.random() * 10000000);

    var Operation;
    if (operation == "home") Operation = Home;
    else Operation = this.getComponentFromPath(metadataType, operation, props.match.params.id);

    Ajax.api = this.props.menu.api || "crm";
    Ajax.port = this.props.menu.port || 4000;

    return (
      <div>
        <Toolbar
          menu={this.props.menu}
          handleDrawerOpen={this.props.handleDrawerOpen}
          onNavigate={this.props.onNavigate}
          {...this.props}
          location={this.props.location}
          history={this.props.history}
          routes={this.props.menu.routes}
        />
        <Operation
          id={props.match.params.id}
          getComponentFromPath={this.getComponentFromPath}
          formId={formId || Math.random()}
          metadataType={Route.metadataType || metadataType}
          {...props}
          menu={this.props.menu}
          handleDrawerOpen={this.props.handleDrawerOpen}
        />
      </div>
    );
  }

  home = props => {
    return this.getComponent(props.match.params.metadataType, "table", props);
  };

  root = props => {
    return this.getComponent(props.match.params.metadataType, "home", props);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <SmartView />
        <FastCreate getComponentFromPath={this.getComponentFromPath} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path={`/${this.props.namespace}/:metadataType/:operation/:id`}
              render={props => {
                var Route = this.props.menu.routes[props.match.params.metadataType] || {};

                return this.getComponent(
                  Route.metadataType || props.match.params.metadataType,
                  props.match.params.operation,
                  props
                );
              }}
            />
            <Route
              path={`/${this.props.namespace}/:metadataType/:operation`}
              render={props => {
                var Route = this.props.menu.routes[props.match.params.metadataType] || {};

                return this.getComponent(
                  Route.metadataType || props.match.params.metadataType,
                  props.match.params.operation,
                  props
                );
              }}
            />
            <Route path={`/${this.props.namespace}/:metadataType`} render={props => this.home(props)} />
            <Route path={"/home"} render={props => this.root(props)} />
            <Route path={`/${this.props.namespace}`} render={props => <div />} />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {};
};

export default withStyles(styles, { withTheme: true })(BaseRoute);
