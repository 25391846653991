import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  bold: {
    fontWeight: 800
  },
})

class JournalMayorizarModal extends React.Component {

  state = {
    accounts: []
  }

  componentDidMount() {

  }

  render() {
    return <div>ok</div>
  }
}

export default withStyles(allStyles, { withTheme: true })(JournalMayorizarModal);

