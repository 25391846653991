import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import { PieChart, Pie, Sector, Cell, ReferenceLine } from "recharts";
import { useDispatch } from "react-redux";

import moment from "moment";
import { simpleApi } from "redux/reducers/api";

const styles = {
  card: {
    width: "100%",

    padding: 8,
    paddingTop: 0
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      if (props.loadMetrics && props.cliente) {
        dispatch(
          simpleApi("movimientoInventario/groupBy", {
            filters: [
              ["movimientoInventario.clienteId", "=", props.cliente.id],
              ["movimientoInventario.fecha", "FIXED", "16 SEMANAS"]
            ],
            sumFields: "cantidad",
            groupBy: ["grupoProductoId"],
            dateGroup: "fecha-semana"
          })
        ).then(response => {
          setData(response);
        });
      } else setData([]);
    },
    [props.cliente]
  );

  React.useEffect(
    () => {
      setData(props.data);
    },
    [props.data]
  );

  const bull = <span className={classes.bullet}>•</span>;

  var sumByGrupos = {};
  var count = 17;
  var weeks = [];
  while (count > 0) {
    var w1 = moment()
      .startOf("week")
      .add(count * -1, "week")
      .format("YYYY ww")
      .replace(" ", "");

    weeks.push(w1);
    count--;
  }
  var semanas = {};
  weeks.forEach(week => {
    semanas[week] = [
      { week: week, index: 1, value: -1, grupo: "G1" },
      { week: week, index: 1, value: -1, grupo: "G2" },
      { week: week, index: 1, value: -1, grupo: "G3" },
      { week: week, index: 1, value: -1, grupo: "G4" },
      { week: week, index: 1, value: -1, grupo: "G5" }
    ];
  });

  if (data)
    data.forEach(item => {
      if (!sumByGrupos[item.grupoProductoId]) sumByGrupos[item.grupoProductoId] = 0;
      sumByGrupos[item.grupoProductoId] += item.cantidad;
      if (
        item.grupoProductoId > 0 &&
        semanas[item.semana] &&
        semanas[item.semana][item.grupoProductoId - 1]
      ) {
        semanas[item.semana][item.grupoProductoId - 1].value += item.cantidad;
        semanas[item.semana][item.grupoProductoId - 1].grupo = `G${item.grupoProductoId}`;
        semanas[item.semana][item.grupoProductoId - 1].grupoId = item.grupoProductoId;
      }
    });
  var sourceData = Object.values(semanas).reverse();

  return (
    <div className={classes.card}>
      <Typography variant="outline">Cobertura Semanal</Typography>
      <HeatChart promedios={sumByGrupos} sourceData={sourceData} {...props} />
      <Typography style={{ fontSize: 9, marginTop: 10 }} variant="caption">
        La intensidad de cada punto indica el peso de la venta en esa semana y grupo.
      </Typography>
    </div>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);

class HeatChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ width: this.myRef.current.offsetWidth });
  }

  onClick = (item, index) => {
    return () => {
      var props = this.props;
      if (this.props.onClick) this.props.onClick(item, index);
    };
  };

  renderTooltip = props => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            minWidth: 150,
            zIndex: 10000,
            padding: 10
          }}>
          <p>
            Semana {data.week.slice(4, 6)} <small>'{data.week.slice(0, 4)} </small>
          </p>
          <p>
            <span>
              {data.grupo}: <strong>{data.value} unidades</strong>
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  renderChart(day, data) {
    const range = [65, 65];

    return (
      <ResponsiveContainer width="100%" height={day == "@" ? 35 : 25}>
        <ScatterChart
          margin={{
            top: day == "@" ? 29 : 18,
            right: 0,
            bottom: 0,
            left: -60
          }}>
          {day == "@" ? (
            <XAxis
              tickMargin={0}
              tick={{ fontSize: 8 }}
              axisLine={false}
              type="category"
              dataKey="grupo"
              interval={0}
              tickLine={false}
            />
          ) : null}
          <YAxis
            type="number"
            tick={{ fontSize: 8 }}
            dataKey="index"
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: day, style: { fontSize: 8 }, fontSize: 8, position: "insideBottomRight" }}
          />

          {day == "-4" ? <ReferenceLine strokeDasharray="3 3" y={0} stroke="rgba(136,132,216,1)" /> : null}

          <ZAxis type="number" dataKey="value" range={range} />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
            content={this.renderTooltip}
          />
          <Scatter data={data} fill="#8884d8">
            {data.map((entry, index) => {
              if (entry.value <= 0)
                return (
                  <Cell key={`cell-${index}`} stroke={"rgba(44,44,44,0.5)"} fill={"rgba(136,132,216,0)"} />
                );
              return (
                <Cell
                  onClick={this.onClick(entry, day)}
                  key={`cell-${index}`}
                  stroke={"rgba(136,132,216,1)"}
                  fill={
                    "rgba(136,132,216," +
                    (entry.value / (this.props.promedios[entry.grupoId] / 4)) * 1.2 +
                    ")"
                  }
                />
              );
            })}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <div style={{ width: "100%" }} ref={this.myRef}>
        {this.props.sourceData.map((item, index) => {
          var indexName = "-" + index;
          if (index == 0) indexName = "@";
          return this.renderChart(indexName, item);
        })}
      </div>
    );
  }
}
