import React from "react";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

class LinkFormatter extends React.Component {
  onClick = () => {
    var url =
      "/" +
      this.props.tableProps.metadata.namespace +
      "/" +
      this.props.column.metadataType +
      "/edit/" +
      this.props.row.id;
    this.props.tableProps.history.push(url);
  };

  render() {
    var value = this.props.value;
    var defaultValue = "";
    if (!value || value.length == 0) value = defaultValue;

    var hasEdit = false;

    if (!this.props.tableProps.metadata.actions) return value;
    else {
      this.props.tableProps.metadata.actions.map(action => {
        if (action.title == "Editar") {
          hasEdit = true;
          defaultValue = "Editar";
        }
      });
    }

    if (this.props.column.element == "autocomplete") {
      value =
        this.props.row[`__${this.props.column.key}`] ||
        this.props.row[this.props.column.key] ||
        this.props.row.id ||
        defaultValue;
    }
    if (!hasEdit) return value;

    return (
      <Typography>
        <Link onClick={this.onClick}>{value}</Link>
      </Typography>
    );
  }
}

export default function(column, reload, getRow, handleUpdate, tableProps) {
  return class extends React.Component {
    render() {
      return (
        <LinkFormatter
          {...this.props}
          handleUpdate={handleUpdate}
          tableProps={tableProps}
          reload={reload}
          getRow={getRow}
          column={column}
        />
      );
    }
  };
}
