import React from "react";
import PropTypes from "prop-types";
import { shapes } from "react-data-grid";
import TextField from "@material-ui/core/TextField";
import TextInput from "@material-ui/core/Input";

import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import { onFilter, selectFilterByKey, selectGroupByKey, changeGroup } from "redux/reducers/table";
import GridOff from "@material-ui/icons/GridOff";
import IconButton from "@material-ui/core/IconButton";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import styles from "./FilterStyles";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
const style = {
  ...styles,
  switch: {},
  formControl: {
    top: -10
  }
};

const { ExcelColumn } = shapes;

const mapStateToProps = (store, props) => {
  return {
    filter: selectFilterByKey(store, props.column.metadataType, props.column.key),
    grouped: selectGroupByKey(store, props.column.metadataType, props.column.key)
  };
};

class TextFilter extends React.Component {
  handleChange = e => {
    if (this.props.column.groupOnly) return;

    let checked = !this.props.filter ? "No" : this.props.filter.textValue == "Si";
    if (checked) checked = 0;
    else checked = 1;

    var key = this.props.column.key;

    var filterValue = [`${this.props.metadataType}.${key}`, "=", checked];
    if (this.props.column.select) filterValue[0] = key;

    this.props.onChange({
      [this.props.column.key]: {
        metadataType: this.props.metadataType,
        column: this.props.column,
        textValue: checked ? "Si" : "No",
        filterValue: filterValue
      }
    });
  };

  onClick = () => {
    this.props.onChange({
      column: this.props.column,
      value: "GROUP"
    });
  };

  renderIconButton() {
    const { classes } = this.props;
    var icon;
    if (!this.props.grouped) icon = <ViewAgenda classes={{ root: classes.svg }} />;
    else icon = <GridOff />;

    return (
      <IconButton
        className={classes.iconButton}
        color={this.props.grouped ? "primary" : "default"}
        size="sm"
        onClick={this.onClick}>
        {icon}
      </IconButton>
    );
    return null;
  }

  render() {
    const { classes } = this.props;

    let columnStyle = {
      maxWidth: "100%"
    };

    let filterValue = this.props.filter || { textValue: "" };
    return (
      <div style={columnStyle}>
        <div style={columnStyle}>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <Switch
                  checked={
                    filterValue.textValue == "Si" ||
                    (filterValue.textValue == "" && this.props.column.key == "activo")
                  }
                  onChange={this.handleChange}
                  classes={{ root: classes.switch }}
                  color="primary"
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              {this.renderIconButton()}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

TextFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn)
};

const TextWithStyles = withStyles(style)(connect(mapStateToProps)(TextFilter));

export default function(metadataType) {
  return class extends React.Component {
    render() {
      return <TextWithStyles metadataType={metadataType} {...this.props} />;
    }
  };
}
