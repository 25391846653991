import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import AutoComplete from "components/Autocomplete";

import FileUpload from "components/SmartUploadField";
import { Grid, Box } from "@material-ui/core";

import EstadoSelector from "./estadoSelector";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: "80vh",
    width: "100%",
    overflow: "scroll"
  },
  control: {
    padding: theme.spacing(2)
  }
}));
export default function FormDialog(props) {
  const [state, setState] = React.useState({
    task: {
      ownerId: "",
      estado: "pendiente",
      attachment: "",
      name: "",
      progress: 0,
      urgent: false,
      seccion: "",
      fechaVencimiento: ""
    }
  });

  React.useEffect(
    () => {
      setState({ task: props.task || {} });
    },
    [props.task]
  );

  const classes = useStyles();

  function handleChange(name) {
    return event => {
      setState({ task: { ...state.task, [name]: event.target.value } });
    };
  }

  function onChangeOwner(key, value, extra) {
    setState({
      task: { ...state.task, ownerId: value, __ownerId: extra.name, __ownerId: extra.name }
    });
  }

  function onClick() {}

  function handleClose() {}

  function onEdit(item) {
    return () => {
      if (item.ownerId != props.user.id && item.createdById != props.user.id)
        return props.dispatch(alert("Solo puede editar tareas propias.", "warning"));

      setState({ task: item, creating: true });
    };
  }

  function handleComplete(fileKey, path, fullPath) {
    setState({
      task: { ...state.task, attachment: fullPath }
    });
  }

  function onChangeEstado(item, key, value) {
    setState({ ...state, estado: value });
  }

  function valuetext(value) {
    return `${value}°C`;
  }

  function onSaveTarea() {
    props.onSave(state.task);
  }
  function onChangeProgress(item, progress) {
    var task = { ...state.task, progress: progress };
    setState({ task: task });
  }
  return (
    <div>
      <div style={{ padding: 15 }}>
        <TextField
          label="Titulo"
          style={{ marginBottom: 15 }}
          placeHolder="Crear Tarea"
          className={classes.textField}
          value={state.task.name}
          onClick={onClick}
          autoFocus
          InputLabelProps={{
            shrink: true
          }}
          inputStyle={{
            fontSize: 11
          }}
          fullWidth
          onChange={handleChange("name")}
        />

        <Grid container spacing={3}>
          <Grid xs={6} item>
            <AutoComplete
              onChange={onChangeOwner}
              className={classes.textField}
              item={{ ...state.task, __ownerId: state.task.ownerName }}
              column={{
                title: "Propietario",
                key: "ownerId",
                type: "integer",
                table: "owner",
                filterable: true,
                sortable: true,
                route: "owner/findLikeName",
                metadataType: "owner",
                element: "autocomplete",
                elementOptions: {
                  primary: "name"
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ marginTop: 12 }}
              label="Vencimiento"
              className={classes.textField}
              value={state.task.fechaVencimiento}
              type="date"
              onChange={handleChange("fechaVencimiento")}
            />
          </Grid>
        </Grid>

        <TextField
          placeHolder="Descripcion"
          className={classes.textField}
          value={state.task.description}
          variant="outlined"
          onClick={onClick}
          inputStyle={{
            fontSize: 11
          }}
          rows={3}
          multiline
          maxRows={4}
          rowsMax={4}
          label="Descripcion"
          fullWidth
          onChange={handleChange("description")}
        />

        <div style={{}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mt={2}>
                <EstadoSelector item={state.task} onChange={onChangeEstado} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FileUpload
                metadata={state.task}
                fileKey="attachment"
                route={"files/upload"}
                onComplete={handleComplete}
                onClose={handleClose}
                label={"Archivo Adjunto"}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 1, paddingRight: 1 }}>
          <TextField
            label="Comentarios"
            multiline
            variant="outlined"
            maxRows={6}
            rows={6}
            fullWidth
            className={classes.textField}
            value={state.task.comentarios}
            type="date"
            onChange={handleChange("comentarios")}
          />
        </div>
        <Grid container justify="space-between" style={{ marginTop: 20 }}>
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="default"
            onClick={props.onCancel}
            className={classes.button}>
            Cancelar
          </Button>
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="default"
            onClick={props.onDelete}
            className={classes.button}>
            Borrar
          </Button>
          <Button variant="contained" color="primary" onClick={onSaveTarea} className={classes.button}>
            Guardar
          </Button>
        </Grid>
      </div>
    </div>
  );
}
