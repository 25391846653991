import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";

import { connect } from "react-redux";

import moment from "moment";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons

import Check from "@material-ui/icons/Check";

// core components

// material-ui components

import TextField from "@material-ui/core/TextField";

import { simpleApi } from "redux/reducers/api";
import Task from "components/Task";
import { createTask, queryTasks, selectAllTasks } from "redux/reducers/ui/task";

import CircularProgress from "@material-ui/core/CircularProgress";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Notas from "components/Nota";
import Contactos from "components/Contacto";
import Stat from "components/Graphs/Stat";
import Compare from "components/Graphs/Compare";
import Nivel from "components/Graphs/Nivel";

import Bar from "components/Graphs/Bar";
import Pie from "components/Graphs/Pie";

import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";

import Button from "@material-ui/core/Button";
import { alert } from "redux/reducers/header";
import { Box } from "@material-ui/core";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      flexGrow: 1,
      display: "flex",

      overflow: "hidden",
      width: "100%",
      minWidth: 320
    },

    gridCard: {
      marginBottom: 20,
      marginTop: 10
    },
    subheader: {
      width: "100%"
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },

    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff"
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0
    },

    noCardHeaderRoot: {
      paddingLeft: 0,
      paddingBottom: 0,
      marginBottom: 0
    },
    cardContent: {},
    link: {
      cursor: "pointer",
      color: theme.palette.primary.dark,
      textTransform: "capitalize"
    },
    fieldWrapper: {
      borderBottom: "4px dotted rgba(240,240,250,0.6)",
      marginBottom: 15,
      paddingBottom: 15
    },

    singleMetrics: {
      borderLeft: "4px dotted rgba(240,240,250,0.6)",
      marginBottom: 15,
      paddingLeft: 8,
      paddingTop: 8,
      backgroundColor: "rgba(240,240,250,0.6)",
      paddingBottom: 15,
      minHeight: "100%"
    },

    historogram: {
      border: "1px solid #ddd",
      borderLeft: "none",
      marginBottom: 15,
      paddingLeft: 8,
      paddingTop: 8,
      backgroundColor: "rgba(240,240,250,0.6)",
      paddingBottom: 15,
      minHeight: "100%"
    },

    productosSection: {
      marginTop: 20
    },

    table: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 300,
      overflow: "scroll",
      marginBottom: 20
    },
    title: {
      marginBottom: 20,
      color: "rgba(120,140,140,1)"
    },
    relatedListWrapper: {
      padding: 10,
      minHeight: 300,
      maxHeight: 500,
      overflow: "scroll",
      border: "1px solid #ddd"
    },
    individualListWrapper: {
      padding: 10,
      marginBottom: 20,
      minHeight: 100,
      maxHeight: 1500,

      border: "1px solid #ddd"
    },
    metricsWrapper: {}
  };
};

const mapStateToProps = (store, props) => {
  return {
    tasks: selectAllTasks(store),
    user: store.user.user,
    users: store.user.users
  };
};

class CxpEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      item: {}
    };
  }

  componentDidMount() {
    return this.props.dispatch(queryTasks());
  }

  componentWillReceiveProps(nextProps) {}

  onCreateTask = task => {
    return this.props.dispatch(createTask(task, "home"));
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Grid container>
          <Grid item xs={8}>
            <Task
              users={this.props.users}
              dispatch={this.props.dispatch}
              user={this.props.user}
              dispatch={this.props.dispatch}
              onCreate={this.onCreateTask}
              items={this.props.tasks}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(CxpEdit));
