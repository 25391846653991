import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    padding: 5,
    height: 120
  }
});

class SimpleTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Ventas" />
            <Tab label="Inventarios" />
            <Tab label="Cobro" />
            <Tab label="Gastos y Proveedores" />
            <Tab label="Analisis y Reportes" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <Grid spacing={3} container>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Seguimiento de Clientes</Typography>
                  <Typography component="body2">
                    Información en tiempo real e historico del comportamiento de su cliente y alarmas que le
                    informan cuando debe tomar acciónes especificas.
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Ordenes y Proformas</Typography>
                  <Typography component="body2">
                    App super rapido para Mobile, Tablet y Desktop para crear ordenes y proformas en segundos.
                    Imprima y envia facturas con el seguimiento de inteligencia artificial de eFactura que le
                    da seguimiento por usted.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Pricelists</Typography>
                  <Typography component="body2">
                    Venda como mejor le parezca, con las listas de precios individuales puede tener un control
                    granular de los precios y descuentos por cliente y con el app de administración puede
                    manejar miles de precios y combinaciones sin dolores de cabeza.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Comisiones</Typography>
                  <Typography component="body2">
                    Si cuenta con vendedores o socios que comisionan, eFactura le da seguimiento en tiempo
                    real de cualquier comision y le permite configurarlas segun una gran varidad de opciones
                    como el cliente, forma de pago, producto y mucho mas.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Procesos</Typography>
                  <Typography component="body2">
                    Todos los procesos de ventas de su empresa son auditados, con dobles controles de
                    seguridad que reducen considerablemente el error humano en la operación asi como el fraude
                    interno.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <Grid spacing={3} container>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Catalogos</Typography>
                  <Typography component="body2">
                    Organice su catalogo de ventas en departamentos, categorias y agrupe la variabilidad de
                    productos como tamaños, colores y presentaciones para realizar mercadeo, construir ordenes
                    y hacer mas eficiente el manejo de sus productos.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Compras, Importaciones y Movimientos</Typography>
                  <Typography component="body2">
                    Con los dobles controles y aplicaciones inteligentes de eFactura, maneje su inventario de
                    forma amigable y segura.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Tomas de Inventario</Typography>
                  <Typography component="body2">
                    Realice tomas de inventario de forma continua y guarda la informacion historica para
                    analisis posterior de movimientos y faltantes. Calendarice las tomas de inventarios y
                    simplifique el seguimiento de su inventario. Todo de forma automatica.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Grid spacing={3} container>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Saldos</Typography>
                  <Typography component="body2">
                    eFactura esta diseñado para operar carteras de credito de miles de facturas en millones de
                    dolares.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Recibos Automaticos</Typography>
                  <Typography component="body2">
                    Conectese con su banco via API o descarguer los estados de cuenta y cree automaticamente
                    miles de recibos de los depositos directos a sus cuentas bancarias.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Pronto Pago</Typography>
                  <Typography component="body2">
                    Ofrezca descuentos especiales por pronto pago de sus facturas de credito, cree Notas de
                    Credito y apliquelas automaticamente.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Envio de Estados de Cuenta</Typography>
                  <Typography component="body2">
                    Envie estados de cuenta automaticamente a toda su cartera de clientes, asi como
                    notificaciones de todos los eventos relacionados con su cuenta.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            <Grid spacing={3} container>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Recibo Automatico de Facturas</Typography>
                  <Typography component="body2">
                    Importe, o reciba por email sus facturas en formato XML o PDF y estas se agregan
                    automaticamente al sistema sin que tenga que digitarlas.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Viaticos y Cuentas de Gastos</Typography>
                  <Typography component="body2">
                    Cree cuentas de gastos o viaticos para sus empleados, y administriste facilmente sus
                    tarjetas de credito.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Ordenes de Compra e Importación</Typography>
                  <Typography component="body2">
                    Maneja de formna sencilla la creacion y aprobacion de ordenes de compra, ligue sus
                    facturas de compra de materia prima, importación y otros costos relacionados a las venta
                    para el costeo automatico y asistido de sus productos y servicios.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </TabContainer>
        )}
        {value === 4 && (
          <TabContainer>
            <Grid spacing={3} container>
              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Reportes Operativos</Typography>
                  <Typography component="body2">
                    Construya y Guarde sus reportes para generalos y mantener un control de su operación.
                    Guardelos digitalmente para ahorrar papel y calendarice su ejecución para tener la
                    seguridad de un control historico automatico de la información operativa de su empresa.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Analisis de Datos</Typography>
                  <Typography component="body2">
                    Analice el 100% de su información hasta 10 años en el pasado de manera rapida y segura.
                    Los controls de perfil de eFactura permiten un control granular del acceso a la
                    información por fila y por columna.
                  </Typography>
                </Paper>
              </Grid>

              <Grid xs={4} item>
                <Paper className={classes.paper} elevation={0}>
                  <Typography variant="subtitle2">Exploración Interactiva KPi</Typography>
                  <Typography component="body2">
                    Defina las metricas mas importantes de su negocio y sus metas segun los mas modernos ejes
                    estrategicos y guardelos en eFactura para que el sistema automaticamente le de seguimiento
                    al avance de sus proyectos mas impactantes para su empresa.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </TabContainer>
        )}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTabs);
