import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import thunk from "redux-thunk";

const history = createBrowserHistory();

export default function() {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  //
  var composed = composeEnhancer(applyMiddleware(routerMiddleware(history), thunk));
  /* eslint-disable no-underscore-dangle */
  const store = createStore(rootReducer(history), composed);
  /* eslint-enable */
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("./reducers/index").default(history);
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
