import React, { lazy } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";

import { onLoadDataById, selectDataByTypeAndId } from "redux/reducers/data";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";

import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import Integer from "components/SmartForm/elements/integer";
import String from "components/SmartForm/elements/string";
import Number from "components/SmartForm/elements/number";
import Lookup from "components/SmartForm/elements/lookup";
import Multiselect from "components/SmartForm/elements/multiselect";
import Select from "components/SmartForm/elements/select";
import SelectDynamic from "components/SmartForm/elements/selectDynamic";

import Boolean from "components/SmartForm/elements/boolean";
import JsonList from "components/SmartForm/elements/jsonList";
import BillingSelector from "components/SmartForm/elements/billingSelector";
import Date from "components/SmartForm/elements/date";
import MultiFileUpLoader from "components/SmartForm/elements/multiFileUploader";

import Autocomplete from "components/SmartForm/elements/autocomplete";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import PureView from "./PureView";

import ClienteView from "./ClienteView";
import { LinearProgress } from "@material-ui/core";
import { showSmartView } from "redux/reducers/ui/smartView";
import { showFastCreate } from "redux/reducers/ui/fastCreate";

import { loadRelatedList } from "redux/reducers/ui/relatedList";
import { createContacto, editContacto } from "redux/reducers/ui/contacto";
import { createTask } from "redux/reducers/ui/task";
import { createNote } from "redux/reducers/ui/note";

const typeMap = {
  integer: Integer,
  string: String,
  number: Number,
  lookup: Lookup,
  select: Select,
  selectDynamic: SelectDynamic,
  multiFileUpLoader: MultiFileUpLoader,
  billingSelector: BillingSelector,
  autocomplete: Autocomplete,
  date: Date,
  files: lazy(() => import("components/SmartForm/elements/files")),
  boolean: Boolean,
  multiselect: Multiselect,
  jsonList: JsonList
};

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      width: "100%"
    },

    gridCard: {
      marginBottom: 20,
      marginTop: 10
    },
    subheader: {
      width: "100%"
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff"
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0
    }
  };
};

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user,
    contactos: store.contacto.contactosByTypeAndId[`${props.type}-${props.id}`] || [],
    notes: store.note.notasByTypeAndId[`${props.type}-${props.id}`] || [],
    tasks: store.task.tasksByTypeAndId[`${props.type}-${props.id}`] || [],
    audits: store.audit.auditsByTypeAndId[`${props.type}-${props.id}`] || [],
    metrics: store.metric.metricsByTypeAndId[`${props.type}-${props.id}`] || [],
    relatedLists: store.relatedList.listsByTypeAndId[`${props.type}-${props.id}`] || {},
    metadata: selectMetadataByType(store, props.type),
    item: store.smartView.itemsByTypeAndId[`${props.type}-${props.id}`] || []
  };
};

class SmartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { audits: [], notes: [], relatedFields: {}, metrics: [], tasks: null, contactos: [] };
  }

  showSubView = (type, id) => {
    this.props.dispatch(showSmartView({ type: type, id: id }));
  };

  loadList = relatedList => {
    var data = this.props;
    this.props.dispatch(loadRelatedList(data.metadata.key, data.item, relatedList));
  };

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(this.props.formId, name, value, extra);
  };

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onCreateNote = note => {
    return this.props.dispatch(createNote(note, this.props.metadata.key, this.props.item.id));
  };

  onCreateContacto = (contacto, callback) => {
    return this.props.dispatch(createContacto(this.props.item, contacto, callback));
  };

  onEditContacto = id => {
    return this.props.dispatch(editContacto(this.props.item, id));
  };

  onCreateTask = task => {
    return this.props.dispatch(createTask(task, this.props.metadata.key, this.props.item.id));
  };

  onProgressTask = task => {
    var originalTaskIndex;
    this.state.tasks.forEach((item, index) => {
      if (item.taskId == task.taskId) originalTaskIndex = index;
    });
    var tasks = this.state.tasks;
    tasks.splice(originalTaskIndex, 1, task);
    this.setState({ tasks: tasks });

    return this.props.dispatch(
      simpleApi("task/progress", { ...task, type: this.props.data.metadata.key, id: this.props.id })
    );
  };

  favoriteItem = note => {
    return this.props.dispatch(simpleApi("notes/favorite", note)).then(results => {
      this.setState({
        notes: results
      });
    });
  };

  renderView() {
    if (this.props.type == "cliente")
      return (
        <ClienteView
          dispatch={this.props.dispatch}
          user={this.props.user}
          showSubView={this.showSubView}
          data={this.props.data}
          contactos={this.props.contactos}
          tasks={this.props.tasks}
          lists={this.props.relatedLists}
          metrics={this.props.metrics}
          audits={this.props.audits}
          notes={this.props.notes}
          metadata={this.props.metadata}
          item={this.props.item}
          onProgressTask={this.onProgressTask}
          loadList={this.loadList}
          onCreateContacto={this.onCreateContacto}
          onEditContacto={this.onEditContacto}
          onCreateTask={this.onCreateTask}
          onCreateNote={this.onCreateNote}
          favoriteItem={this.favoriteItem}
          {...this.props}
          onFieldChange={this.onFieldChange}
        />
      );
    else
      return (
        <PureView
          dispatch={this.props.dispatch}
          user={this.props.user}
          showSubView={this.showSubView}
          contactos={this.props.contactos}
          tasks={this.props.tasks}
          lists={this.props.relatedLists}
          metrics={this.props.metrics}
          audits={this.props.audits}
          notes={this.props.notes}
          metadata={this.props.metadata}
          item={this.props.item}
          onProgressTask={this.onProgressTask}
          loadList={this.loadList}
          onCreateContacto={this.onCreateContacto}
          onEditContacto={this.onEditContacto}
          onCreateTask={this.onCreateTask}
          onCreateNote={this.onCreateNote}
          favoriteItem={this.favoriteItem}
          {...this.props}
          onFieldChange={this.onFieldChange}
        />
      );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>{!this.props.metadata || !this.props.item ? <LinearProgress fullWidth /> : this.renderView()}</div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartForm));
