import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SmartView from "components/SmartView/SmartView";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { selectMetadataByType, selectMetadataByTypeFn } from "redux/reducers/metadata";
import { selectDataByTypeAndId, onLoadDataById, selectDataByTypeAndIdFn } from "redux/reducers/data";
import { hideSmartView, showSmartView } from "redux/reducers/ui/smartView";
import { onLoadMetaData } from "../../redux/reducers/metadata";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { AppBar } from "@material-ui/core";

const styles = theme => ({
  titleRoot: {
    margin: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginLeft: 8
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: 8,
    color: "#fff"
  }
});

const mapStateToProps = (store, props) => {
  return {
    smartViews: Object.values(store.smartView.viewsByTypeAndId)
  };
};

class SmartViewContainer extends React.Component {
  state = {
    value: 0,
    items: {}
  };

  onCloseViewModal = (type, id) => {
    return e => {
      this.props.dispatch(hideSmartView({ type: type, id: id }));
    };
  };

  renderSmartView(view, index) {
    return <SmartView index={index} type={view.type} id={view.id} />;
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    if (this.props.smartViews.length == 0) return null;

    return this.props.smartViews
      .filter(item => item != null)
      .map((view, index) => {
        if (!view) return null;
        return (
          <Dialog
            scroll="body"
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            fullScreen={index == 0 ? true : false}
            fullWidth={true}
            maxWidth={"lg"}
            open={true}
            onClose={this.onCloseViewModal(view.type, view.id)}>
            <AppBar style={{ backgroundColor: "#544ecc" }} position="static" color="primary">
              <MuiDialogTitle disableTypography className={classes.titleRoot}>
                <Typography style={{ color: "#fff", marginTop: 15 }} variant="subtitle1">
                  {view.type} {view.id}
                </Typography>
                <IconButton
                  aria-label="Close"
                  className={classes.closeButton}
                  onClick={this.onCloseViewModal(view.type, view.id)}>
                  <CloseIcon />
                </IconButton>
              </MuiDialogTitle>
            </AppBar>
            <DialogContent style={{ marginTop: 0 }}>{this.renderSmartView(view, index)}</DialogContent>
          </Dialog>
        );
      });
  }
}

SmartViewContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartViewContainer));
