import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Box, Divider, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import EstadoSelector from "./estadoSelector";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  function handleChange(event) {
    props.onChange(props.item, "estado", event.target.value);
  }

  function handleDateChange(event) {
    props.onChange(props.item, "fechaVencimiento", event.target.value);
  }

  function onEdit() {
    props.onEdit(props.item);
  }

  var owner = (props.item.__ownerId || "")
    .split(" ")
    .map(name => name[0])
    .join("");

  var createdBy = "";
  if (props.item.createdById && props.item.ownerId != props.item.createdById)
    createdBy =
      (props.item.createdBy || "")
        .split(" ")
        .map(name => name[0])
        .join("") + " > ";

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText
          secondary={<div style={{ textTransform: "uppercase" }}>{`${createdBy}${owner}`}</div>}
          primary={
            <div>
              {open ? <EditIcon onClick={onEdit} style={{ margin: 2 }} /> : null}
              {props.item.name}
            </div>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box pl={2} pr={2} pt={1} pb={1}>
          <div>{props.item.description}</div>
          <Box pt={1}>
            <Grid container>
              <Grid item xs={6}>
                <EstadoSelector item={props.item} onChange={props.onChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  style={{ margin: 0 }}
                  label="Fecha Vencimiento"
                  className={classes.textField}
                  value={props.item.fechaVencimiento}
                  onChange={handleDateChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}
