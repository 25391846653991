import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import numeral from "numeral";

class NumberFormatter extends React.Component {
  render() {
    return <div style={{ textAlign: "right" }}>{numeral(this.props.value).format("0,0.00")}</div>;
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <NumberFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
