import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

class FileListFormatter extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onClick = e => {
    window.open(e.currentTarget.dataset.url);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    var value = this.props.value;

    if (!value || value.length == 0) return null;
    var types = [];
    value.forEach(item => {
      if (types.indexOf(item.type) == -1) types.push(item.type);
    });
    var values = [
      <span>
        {value.length} {types.join(",")}
      </span>
    ];

    values.push(this.renderMany(value));
    return values;
  }

  renderMany(value) {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <IconButton
          style={{ position: "absolute", right: -20, top: -15 }}
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {value.map((item, index) => {
            var url = (item.prefix || this.props.column.prefix) + "/" + item.key;
            var name = item.key.split("/").slice(-1)[0];

            return (
              <MenuItem onClick={this.onClick} data-url={url} value={url}>
                <Tooltip placement="right-end" title={name}>
                  <span>{item.type}</span>
                </Tooltip>
              </MenuItem>
            );
          })}
        </Menu>
      </Fragment>
    );
  }
}
export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <FileListFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
