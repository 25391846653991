import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleTable from "components/Table/Table.jsx";
import Table from "views/base/table";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit, selectFilters } from "redux/reducers/table";
import { simpleApi } from "redux/reducers/api";

const allStyles = theme => ({
  root: {
    minWidth: 500
  },
  bold: {
    fontWeight: 800
  },
  formControl: {
    width: "100%"
  }
});

class MovimientoDevolverModal extends React.Component {
  state = {
    templates: [],
    filters: [],
    selectedTemplate: null,
    body: {}
  };

  componentDidMount = e => {
    this.props.dispatch(simpleApi("emails/template/all", {})).then(res => {
      this.setState({
        templates: res.filter(item => item.indexOf("all_") > -1).map(item => item.replace("", ""))
      });
    });
  };

  onSelect = () => {
    this.props
      .dispatch(
        simpleApi("email/sendTemplate", {
          filters: this.props.filters,
          ids: (this.props.items || []).map(item => item.id),
          template: this.state.selectedTemplate,
          body: this.state.body
        })
      )
      .then(res => {
        this.props.onCloseModal();
      });
  };

  onSelectTemplate = e => {
    this.setState({ selectedTemplate: e.currentTarget.dataset.template });
  };

  handleChange = type => {
    return e => {
      this.setState({ body: { ...this.state.body, [type]: e.currentTarget.value } });
    };
  };

  renderInfo() {
    if (this.props.items && this.props.items.length > 0)
      return (
        <Fragment>
          <Typography variant="caption">Filtro Seleccionado</Typography>
          {this.renderIds()}
        </Fragment>
      );
    else
      return (
        <Fragment>
          <Typography variant="caption">Filtros</Typography>
          {this.renderFilters()}
        </Fragment>
      );
  }

  renderFilters() {
    const { classes } = this.props;

    return this.props.filters.map(filter => {
      return (
        <Chip
          key={filter[0]}
          label={`${filter[0].split(".")[0]} ${filter[1]} ${filter[2]}`}
          className={classes.chip}
        />
      );
    });
  }

  renderIds() {
    const { classes } = this.props;

    return this.props.items.map(item => {
      return <Chip key={item.id} label={item.name} className={classes.chip} />;
    });
  }

  renderOptions() {
    const { classes } = this.props;

    if (this.state.selectedTemplate == "promocion")
      return (
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              id="outlined-multiline-flexible"
              label="Mensaje"
              multiline
              rowsMax="6"
              value={this.state.body.mensaje}
              onChange={this.handleChange("mensaje")}
              className={classes.textField}
              margin="normal"
              helperText="Escriba lo mas importante del email. Esto va salir en el resumen del email."
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="outlined-multiline-flexible"
              label="Foto del Producto"
              value={this.state.body.fotoProducto}
              onChange={this.handleChange("fotoProducto")}
              className={classes.textField}
              margin="normal"
              helperText="El url de la foto del producto"
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="outlined-multiline-flexible"
              label="Foto Marca"
              value={this.state.body.fotoProducto}
              onChange={this.handleChange("fotoMarca")}
              className={classes.textField}
              margin="normal"
              helperText="El url de la foto de la marca"
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="outlined-multiline-flexible"
              label="Oferta"
              value={this.state.body.fotoProducto}
              onChange={this.handleChange("oferta")}
              className={classes.textField}
              margin="normal"
              helperText="El descuento o precio especial"
              variant="outlined"
            />
          </FormControl>
        </div>
      );
    else
      return (
        <FormControl className={classes.formControl}>
          <TextField
            id="outlined-multiline-flexible"
            label="Mensaje"
            multiline
            rowsMax="6"
            value={this.state.body.message}
            onChange={this.handleChange("message")}
            className={classes.textField}
            margin="normal"
            helperText="Escriba el mensaje que le quiere enviar a todos los contactos"
            variant="outlined"
          />
        </FormControl>
      );
  }

  render() {
    const { classes } = this.props;

    var templates = [];
    this.state.templates.forEach(template => {
      if (template.indexOf("all_") == -1) return;
      var fixedName = template
        .replace("all_", "")
        .replace("_staging", "")
        .replace("_production", "");
      if (templates.indexOf(fixedName) == -1) templates.push(fixedName);
    });

    return (
      <div className={classes.root}>
        <DialogContentText>Envio de emails</DialogContentText>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-chip">Escoja email a enviar:</InputLabel>
          <Select fullWidth value={this.state.selectedTemplate} onChange={this.onSelectTemplate}>
            {templates.map(item => (
              <MenuItem data-template={"all_" + item} key={item} value={"all_" + item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {this.renderOptions()}

        {this.renderInfo()}

        <DialogActions>
          <Button onClick={this.props.onCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.onSelect} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    filters: selectFilters(store, "contacto") || []
  };
};

export default withStyles(allStyles, { withTheme: true })(connect(mapStateToProps)(MovimientoDevolverModal));
