import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class ConsecutivoFormatter extends React.Component {
  render() {
    var index = 0;
    var lastChar = "0";
    if (!this.props.value) return "";
    var value = this.props.value + "";
    value = value.replace("001", "").replace("00001", "");
    value = value.substring(2);
    value.split("").forEach((char, loopIndex) => {
      if (char != "0" && lastChar == "0" && index == 0) index = loopIndex;
    });
    const dataTransform = this.props.value.length == 20 ? value.substring(index) : this.props.value;

    return dataTransform;
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <ConsecutivoFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
