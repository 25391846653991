import "./styleBootstrap";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppContainer } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import deepPurple from "@material-ui/core/colors/indigo";
import blueGrey from "@material-ui/core/colors/lightBlue";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import ErrorBoundery from "./ErrorBoundery";
import * as Sentry from "@sentry/browser";
import { SnackbarProvider } from "notistack";
import { enqueueSnackbar } from "./redux/reducers/ui/alerts";
import Button from "@material-ui/core/Button";
import { initMetadata } from "redux/reducers/metadata";

import moment from "moment";

moment.updateLocale("en", {
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "pocos segundos",
    ss: "%d secundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora=",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años"
  }
});

moment.relativeTimeThreshold("ss", 3);
moment.relativeTimeThreshold("s", 4);
moment.relativeTimeThreshold("m", 4);
moment.relativeTimeThreshold("h", 4);
moment.relativeTimeThreshold("d", 25);
moment.relativeTimeThreshold("M", 10);

const hist = createBrowserHistory();
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: blueGrey
  },
  typography: {
    fontSize: 12
  }
});

const swConfig = {
  onUpdate: registration => {
    store.dispatch(
      enqueueSnackbar({
        message: "Hay una nueva version del sistema. La cargamos ? ",
        options: {
          variant: "info",
          persist: true,
          action: (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                registration.waiting.postMessage("message");
                navigator.serviceWorker.controller.postMessage("message");
              }}
              size="small">
              {"Recargar"}
            </Button>
          )
        }
      })
    );
  }
};

serviceWorker.register(swConfig);

const render = Component => {
  if (process.env.NODE_ENV != "development")
    Sentry.init({
      dsn: "https://3e63208a7b0f4976b7388560eb6d2006@sentry.io/1288930",
      environment: process.env.NODE_ENV
    });

  store.dispatch(initMetadata()).then(() => {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <ConnectedRouter history={hist}>
            <MuiThemeProvider theme={theme}>
              <ErrorBoundery>
                <SnackbarProvider>
                  <App dispatch={store.dispatch} />
                </SnackbarProvider>
              </ErrorBoundery>
            </MuiThemeProvider>
          </ConnectedRouter>
        </Provider>
      </AppContainer>,
      document.getElementById("root")
    );
  });
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

String.prototype.fromClave = function() {
  return this.substring(34, 41);
};

var refreshing;
navigator.serviceWorker.addEventListener("controllerchange", function() {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});
