import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";

const onFieldChangeAccount = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };
  if (name == "normalCredit" && value == true) delta.normalDebit = false;
  if (name == "normalDebit" && value == true) delta.normalCredit = false;
  return delta;
};

export default onFieldChangeAccount;
