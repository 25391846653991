import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
const styles = {
  card: {
    width: "100%",
    marginBottom: 12
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;

  var valueKey = "";

  if (props.metric.statKey) {
    var statKey = props.metric.statKey;
    if (statKey.indexOf(".") > -1) {
      valueKey = statKey.split(".")[1];
    } else valueKey = statKey;
  } else {
    var sumFields = props.metric.groupBy.sumFields;
    if (sumFields.indexOf(".") > -1) {
      valueKey = sumFields.split(".")[1];
    } else valueKey = sumFields;
  }

  var rawValue = props.value || [{ [valueKey]: 0 }];
  var value = 0;

  try {
    value = rawValue[0][valueKey];
  } catch (e) {}

  if (!props.metric.statKey) {
    value = numeral(value).format("(₡ 0.00 a)");
  }

  return (
    <div className={classes.card}>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {props.metric.title}
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </div>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
