import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import AutoComplete from "components/SmartForm/elements/autocomplete";
import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";
import { showSmartView } from "redux/reducers/ui/smartView";
import Link from "@material-ui/core/Link";

const mapStateToProps = (store, props) => {
  return {};
};
class AutoCompleteFormatter extends React.Component {
  onChange = (key, value, selectedObject) => {
    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: value },
      fromRowId: this.props.row.id
    });
  };

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onClick = e => {
    this.props.dispatch(
      showSmartView({
        item: {
          id: this.props.row[this.props.column.key],
          name: this.props.row["__" + this.props.column.key]
        },
        type: this.props.column.table,
        id: this.props.row[this.props.column.key]
      })
    );
  };

  render() {
    if (!this.props.column.editable)
      return (
        <Link
          component="button"
          variant="body2"
          style={{ textTransform: "capitalize" }}
          onClick={this.onClick}>
          {this.props.row["__" + this.props.column.key] || ""}
        </Link>
      );
    if (this.props.isScrolling) return null;

    return (
      <AutoComplete
        size={12}
        isTable={true}
        {...this.props}
        item={this.props.row}
        onChange={this.onChange}
        dispatchSimpleApi={this.dispatchSimpleApi}
      />
    );
  }
}

export default function(column, reload, getRow, handleUpdate, parentProps) {
  let cls = class extends React.Component {
    render() {
      return (
        <AutoCompleteFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          parentProps={parentProps}
          onFastCreate={parentProps.onFastCreate}
          dispatch={parentProps.dispatch}
          column={column}
          {...this.props}
        />
      );
    }
  };

  return cls;
}
