import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";

const onPagoDocumentoFieldChange = (form, name, value, extra) => {
  if (["clienteId"].indexOf(name) > -1) {
    if (form.item.clienteId)
      return {
        _errorInTransform: true,
        message: "El cliente no se puede cambiar, para corregir este campo debe crear otra orden."
      };
    return { ...form.item, [name]: value };
  }

  var delta = { ...form.item, [name]: value };

  if (["lineaPagoDocumento"].indexOf(name) > -1) {
    var total = value.reduce((acumulator, lineaPago) => {
      return acumulator + lineaPago.monto;
    }, 0);
    delta = { ...delta, monto: parseInt(Number(total).toFixed()), lineaPagoDocumento: value };
  }

  return delta;
};

export default onPagoDocumentoFieldChange;
