import React, { Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { simpleApi } from "redux/reducers/api";

import CircularProgress from "@material-ui/core/CircularProgress";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Notas from "components/Nota";
import Contactos from "components/Contacto";
import SideBar from "./SideBar";
import Metric from "components/Graphs/Metric";
import Compare from "components/Graphs/Compare";
import Stat from "components/Graphs/Stat";
import Nivel from "components/Graphs/Nivel";

import Bar from "components/Graphs/Bar";
import Pie from "components/Graphs/Pie";

import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";

import { showFastCreate } from "redux/reducers/ui/fastCreate";
import RelatedList from "components/RelatedList";
import Task from "components/Task";
import {
  onVisitaNewPedido,
  onVisitSetSelected,
  onVisitaEnviarPedido
} from "redux/reducers/custom/visitaTablet";
import Button from "@material-ui/core/Button";
import { alert } from "redux/reducers/header";
import HeatCalendar from "components/Graphs/HeatCalendar";
import { Box } from "@material-ui/core";
import { Divider } from "@material-ui/core";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      flexGrow: 1,
      display: "flex",

      overflow: "hidden",
      width: "100%",
      minWidth: 320
    },

    gridCard: {
      marginBottom: 20,
      marginTop: 10
    },
    subheader: {
      width: "100%"
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff"
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0
    },

    noCardHeaderRoot: {
      paddingLeft: 0,
      paddingBottom: 0,
      marginBottom: 0
    },
    cardContent: {},
    link: {
      cursor: "pointer",
      color: theme.palette.primary.dark,
      textTransform: "capitalize"
    },
    fieldWrapper: {
      borderBottom: "4px dotted rgba(240,240,250,0.6)",
      marginBottom: 15,
      paddingBottom: 15,
      marginRight: 15,
      minWidth: 120
    },

    singleMetrics: {
      borderLeft: "4px dotted rgba(240,240,250,0.6)",
      marginBottom: 15,
      paddingLeft: 8,
      paddingTop: 8,
      backgroundColor: "rgba(240,240,250,0.6)",
      paddingBottom: 15,
      minHeight: "100%"
    },

    historogram: {
      border: "1px solid #ddd",
      borderLeft: "none",
      marginBottom: 15,
      paddingLeft: 8,
      paddingTop: 8,
      backgroundColor: "rgba(240,240,250,0.6)",
      paddingBottom: 15,
      minHeight: "100%"
    },

    productosSection: {
      marginTop: 20
    },

    table: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 300,
      overflow: "scroll",
      marginBottom: 20
    },
    title: {
      marginBottom: 20,
      color: "rgba(120,140,140,1)"
    },
    relatedListWrapper: {
      padding: 10,
      minHeight: 300,
      border: "1px solid #ddd"
    },
    individualListWrapper: {
      padding: 10,
      marginBottom: 20,
      minHeight: 100,

      border: "1px solid #ddd"
    },
    metricsWrapper: {}
  };
};

class PureForm extends React.Component {
  constructor(props) {
    super(props);
  }

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(name, value, extra);
  };

  onCreatePedido = () => {
    var contactos = [];
    try {
      contactos = this.props.contactos.filter(contacto => {
        if (contacto.rol && contacto.rol.indexOf("venta") > -1) return true;
        return false;
      });
    } catch (e) {}
    if (contactos.length == 0)
      this.props.dispatch(
        alert("Cliente SIN contactos de ventas. Pronto no va poder hacerle pedidos.", "warning")
      );

    this.props.dispatch(onVisitaNewPedido());
  };

  onEnviarEstados = () => {
    var contactos = [];
    try {
      contactos = this.props.contactos.filter(contacto => {
        if (contacto.rol && contacto.rol.indexOf("pago") > -1) return true;
        return false;
      });
    } catch (e) {}

    var contactosIds = contactos.map(item => item.id);

    var result = this.props.dispatch(
      simpleApi("email/sendTemplate", {
        filters: [],
        ids: contactosIds,
        template: "all_estadoCuenta",
        body: { message: "Buenas, adjunto puede encontrar su estado de cuenta. Saludos." }
      })
    );

    if (result) this.props.dispatch(alert("Envio exitoso!", "success"));
  };

  renderInfo(normalFields, fullFields) {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.root}>
          {normalFields.map((field, index) => {
            var column = this.props.metadata.properties[field];
            return this.renderInput(column, field, index == 0 ? 3 : 2);
          })}
        </Grid>

        <Grid container className={classes.root}>
          {fullFields.map(field => {
            var column = this.props.metadata.properties[field];

            return this.renderInput(column, field, 12);
          })}
        </Grid>
      </Fragment>
    );
  }

  renderTasks() {
    return null;
  }

  renderLists(relatedFields, isInvididual) {
    const { classes } = this.props;

    return (relatedFields || []).map(relatedField => {
      var props = this.props;

      var listValues = {};
      if (this.props.lists) listValues = this.props.lists[relatedField.key] || {};

      return (
        <div className={isInvididual ? classes.individualListWrapper : classes.relatedListWrapper}>
          <RelatedList
            dispatch={this.props.dispatch}
            loadList={this.props.loadList}
            listValues={listValues}
            relatedField={relatedField}
            item={this.props.item}
            showSubView={this.props.showSubView}
            metadata={this.props.metadata}
          />
        </div>
      );
    });
  }

  renderProductHistory(relatedFields) {
    const { classes } = this.props;

    return (relatedFields || []).map(relatedField => {
      var props = this.props;

      var listValues = {};
      if (this.props.lists) listValues = this.props.lists[relatedField.key] || {};

      return (
        <div className={classes.productosSection}>
          <Grid container>
            <Grid item md={9} lg={10} xs={12}>
              <div className={classes.individualListWrapper}>
                <div>
                  <Typography variant="outline">Ultimas Compras</Typography>
                  <Typography style={{ fontSize: 12 }} variant="caption">
                    La cantidad comprada en las ultimas 4 semanas y la ultima vez que se compró.
                  </Typography>
                </div>
                <RelatedList
                  dispatch={this.props.dispatch}
                  loadList={this.props.loadList}
                  listValues={listValues}
                  relatedField={relatedField}
                  item={this.props.item}
                  showSubView={this.props.showSubView}
                  metadata={this.props.metadata}
                />
              </div>
            </Grid>
            <Grid item md={3} lg={2} xs={12}>
              <div className={classes.historogram}>
                <HeatCalendar loadMetrics={true} cliente={props.item} />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    });
  }

  renderMetrics() {
    const { classes } = this.props;

    if (!this.props.metadata.view || !this.props.metadata.view.metrics) return null;
    var metrics = this.props.metadata.view.metrics.filter(metric => {
      return metric.type == "METRIC";
    });
    var compares = this.props.metadata.view.metrics.filter(metric => {
      return metric.type == "COMPARE";
    });
    var stats = this.props.metadata.view.metrics.filter(metric => {
      return metric.type == "STAT";
    });
    var niveles = this.props.metadata.view.metrics.filter(metric => {
      return metric.type == "NIVEL";
    });
    var graphs = this.props.metadata.view.metrics.filter(metric => {
      return metric.type != "METRIC" && metric.type != "COMPARE";
    });

    return (
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Grid className={classes.metricsWrapper} container>
            {compares.map(metric => {
              return (
                <Grid item style={{ padding: 12 }} xs={3}>
                  <Compare
                    item={this.props.item}
                    metric={metric}
                    metrics={this.props.metadata.view.metrics}
                    values={this.props.metrics}
                  />
                </Grid>
              );
            })}
            {niveles.map(metric => {
              return (
                <Grid item style={{ padding: 12 }} xs={3}>
                  <Nivel
                    item={this.props.item}
                    metric={metric}
                    metrics={this.props.metadata.view.metrics}
                    values={this.props.metrics}
                  />
                </Grid>
              );
            })}
            {stats.map(metric => {
              return (
                <Grid item style={{ padding: 12 }} xs={3}>
                  <Stat item={this.props.item} metric={metric} value={this.props.metrics[metric.key]} />
                </Grid>
              );
            })}
          </Grid>

          <Grid className={classes.metricsWrapper} container>
            {graphs.map(metric => {
              if (metric.type == "PIE")
                return (
                  <Grid style={{ padding: 12 }} item xs={4}>
                    <Pie metric={metric} value={this.props.metrics[metric.key]} />
                  </Grid>
                );
              else if (metric.type == "BAR")
                return (
                  <Grid style={{ padding: 12 }} item xs={4}>
                    <Bar metric={metric} value={this.props.metrics[metric.key]} />
                  </Grid>
                );
              else return null;
            })}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.singleMetrics}>
            {metrics.map(metric => {
              return <Metric metric={metric} value={this.props.metrics[metric.key]} />;
            })}
          </div>
        </Grid>
      </Grid>
    );
  }

  renderPedidos() {
    const { classes } = this.props;
    if (!this.props.data || !this.props.data.pedidos) return null;
    return (
      <div className={classes.metricsWrapper}>
        <div>
          <Typography variant="outline">Pedidos</Typography>
        </div>
        <Button onClick={this.onCreatePedido} variant="outlined" color="primary" className={classes.button}>
          Nuevo
        </Button>

        {this.props.data.pedidos
          .filter(item => item.clienteId == this.props.id)
          .map(pedido => {
            return (
              <Button
                onClick={() => {
                  this.props.dispatch(onVisitSetSelected("pedido", pedido.id));
                }}
                variant="outlined"
                className={classes.button}>
                {pedido.id}

                <IconButton
                  onClick={() => {
                    this.props.dispatch(onVisitaEnviarPedido(pedido.id));
                  }}
                  aria-label="Delete">
                  <SendIcon />
                </IconButton>
              </Button>
            );
          })}
      </div>
    );
  }

  renderContactos() {
    if (this.props.metadata.key != "cliente") return;
    return (
      <Contactos
        dispatch={this.props.dispatch}
        renderer={item => {
          return item.message;
        }}
        onEdit={this.props.onEditContacto}
        onCreate={this.props.onCreateContacto}
        items={this.props.contactos || []}
      />
    );
  }

  renderInnerField(field, innerValue, xs = 4) {
    const { classes } = this.props;

    if (innerValue == null) return null;
    return (
      <Grid item xs={xs}>
        {field}: {JSON.stringify(innerValue)}
      </Grid>
    );
  }

  onCreateFromRelatedList = relatedList => {
    this.props.dispatch(
      showFastCreate(
        {
          metadataType: relatedList.table,
          operation: "create",

          onCreateComplete: createdObject => {}
        },
        { name: "", clienteId: this.props.item.id }
      )
    );
  };

  renderJsonTable(column, value) {
    const { classes } = this.props;

    var fields = column.fields || Object.keys(value);
    if (Array.isArray(value)) {
      fields = column.fields || Object.keys(value[0]);
      var innerValues = [];
      value = (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map(field => {
                return <TableCell key={field}>{field}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(item => {
              return (
                <TableRow key={item.id}>
                  {fields.map(field => {
                    var value = item[field];
                    if (field.indexOf("__") > -1 && field.indexOf("Id") > -1) {
                      value = (
                        <a
                          className={classes.link}
                          onClick={() => {
                            this.props.showSubView(
                              field.replace("__", "").replace("Id", ""),
                              item[field.replace("__", "")]
                            );
                          }}>
                          {item[field]}
                        </a>
                      );
                    }

                    return (
                      <TableCell component="th" scope="row">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      value = (
        <Grid container>
          {fields.map(field => {
            return this.renderInnerField(field, value[field]);
          })}
        </Grid>
      );
    }
    return value;
  }

  renderInput = (column, key, size) => {
    const { classes } = this.props;
    var key = column.key;

    var value = this.props.item[key];
    if (value == null) return;

    if (column.element == "autocomplete")
      value = (
        <a
          className={classes.link}
          onClick={() => {
            this.props.showSubView(column.metadataType, this.props.item[key]);
          }}>
          {this.props.item[`__${key}`]}
        </a>
      );
    if (column.type == "boolean") value = value == 1 ? "si" : "no";

    if (column.isJSON && value != null) value = this.renderJsonTable(column, value);

    return (
      <Grid className={classes.fieldWrapper} item>
        <Typography variant="caption">{column.title}</Typography>
        <div>{value ? value : ""}</div>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    var normalFields = this.props.metadata.table.fields;
    var fullFields = [];
    var relatedFields = [];
    var individualFields = [];

    if (this.props.metadata.view) {
      normalFields = this.props.metadata.view.fields || this.props.metadata.table.fields;
      relatedFields = this.props.metadata.view.relatedFields || [];
      individualFields = this.props.metadata.view.individualFields || [];
      fullFields = this.props.metadata.view.fullFields || [];
    }

    return (
      <div style={{ minWidth: 320 }}>
        {this.props.metadata.layout ? (
          <Grid container style={{ height: "88vh" }}>
            <Grid
              style={{
                height: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
              }}
              item
              xs={12}
              md={9}
              lg={9}>
              <Box p={2} pl={1} pr={0}>
                {this.renderInfo(normalFields, fullFields)}
                {this.renderMetrics()}
                {this.renderPedidos()}

                {this.renderProductHistory(individualFields, true)}

                <div>
                  <Typography variant="outline">Información Crédito</Typography>
                </div>
                <Button
                  onClick={this.onEnviarEstados}
                  variant="outlined"
                  color="primary"
                  className={classes.button}>
                  Enviar Estado de cuenta
                </Button>

                <SideBar color="primary" names={relatedFields.map(relatedField => relatedField.title)}>
                  {this.renderLists(relatedFields)}
                </SideBar>
              </Box>
            </Grid>
            <Grid
              style={{
                height: "100%",
                overflowY: "scroll",
                borderLeft: "1px solid #ddd",
                backgroundColor: "#fafafa"
              }}
              md={3}
              lg={3}
              xs={12}
              item>
              <Box p={2}>
                {this.renderContactos()}
                <Divider mb={2} variant="fullWidth" />
                <Notas
                  tablet={this.props.tablet}
                  dispatch={this.props.dispatch}
                  renderer={item => {
                    return item.message;
                  }}
                  onCreate={this.props.onCreateNote}
                  items={this.props.notes}
                  favoriteItem={this.props.favoriteItem}
                />
                <Divider variant="fullWidth" />
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {this.props.form && this.props.form.loadStatus == "PENDING" ? (
          <div style={{ margin: 20 }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PureForm);
