import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorView from "components/ErrorBoundery";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    if (process.env.NODE_ENV == "development") {
      console.log(error);
      console.log(info);
      return;
    }
    Sentry.configureScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorView />
    }
    return this.props.children;
  }
}

export default ErrorBoundary;