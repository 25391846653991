import Finance from "redux/helpers/finance";
import Dinero from "dinero.js";

Dinero.globalLocale = "es-CR";
Dinero.defaultPrecision = 5;

const onChange = (form, name, value, extra) => {
  var item = form.item;

  if (["proveedorId"].indexOf(name) > -1) {
    if (item.proveedorId)
      return {
        _errorInTransform: true,
        message: "El proveedor no se puede cambiar, para corregir este campo debe crear otra factura de compra."
      };

    return {
      ...item,
      proveedorId: value,
      cedula: extra.cedula,
      ownerId: extra.ownerId,
      __proveedorId: extra.name,
      plazo: 0
    };
  } else if (["lineaFacturaCompra"].indexOf(name) > -1) {
    value.forEach(linea => {
      linea.excentoPorcentaje = form.item.excentoPorcentaje || 0;
      _setImpuestos(linea);
    });
    return { ...item, ...Finance.ordenTotal(value), lineaFacturaCompra: value };
  } else if (["excentoPorcentaje"].indexOf(name) > -1) {
    if (!item.excentoTipo) return { _errorInTransform: true, message: "debe completa el campo tipo primero" };
    if (!item.excentoNumero)
      return { _errorInTransform: true, message: "debe completa el campo numero primero" };
    if (!item.excentoInstitucion)
      return { _errorInTransform: true, message: "debe completa el institucion tipo primero" };
    if (!item.excentoFecha)
      return { _errorInTransform: true, message: "debe completa el campo fecha primero" };

    var delta = { ...item, excentoPorcentaje: value, [name]: value };

    if (item.lineaFacturaCompra) {
      item.lineaFacturaCompra.forEach(linea => {
        _setImpuestos(linea, value);
      });
      delta = { ...delta, ...Finance.ordenTotal(item.lineaFacturaCompra), lineaFacturaCompra: item.lineaFacturaCompra };
    }
    return delta;
  }

  return { ...item, [name]: value };
};

const _setImpuestos = (linea, excentoPorcentaje) => {
  if (excentoPorcentaje) linea.excentoPorcentaje = parseFloat(excentoPorcentaje);

  linea._impuestoUnitario = linea._impuestoUnitario
    ? parseInt(linea._impuestoUnitario)
    : parseInt(linea.impuestoUnitario); //reset impuesto unitario

  linea.excentoPorcentaje = linea.excentoUnitario ? linea.excentoUnitario : linea.excentoPorcentaje;
  
  var excentoPorcentaje = new Dinero({ amount: parseInt((linea.excentoPorcentaje || 0) * 100000) }); //excentoPorcentaje for Dinero
  var impuestoUnitarioPercent = Dinero({ amount: 100 * 100000 })
    .subtract(excentoPorcentaje)
    .toRoundedUnit(2); // impuesto unitario 100 - Excento

  linea._excentoUnitario = Dinero({ amount: parseInt(linea._impuestoUnitario) * 100000 })
    .percentage(linea.excentoPorcentaje)
    .toRoundedUnit(2);
  linea.impuestoUnitario = Dinero({ amount: parseInt(linea._impuestoUnitario) * 100000 })
    .percentage(impuestoUnitarioPercent)
    .toRoundedUnit(2);
};

export default onChange;
