import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import KanbanList from "./list";
import Filters from "./filters";
import EditModal from "./editModal";
import { Fab, Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import moment from "moment";
//
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: "80vh",
    width: "100%",
    overflow: "scroll"
  },
  control: {
    padding: theme.spacing(2)
  }
}));

export default function SpacingGrid(props) {
  const [spacing, setSpacing] = React.useState(2);
  const [tasks, setTasks] = React.useState([]);
  const [filteredTasks, setFilteredTasks] = React.useState([]);
  const [listFilter, setListFilter] = React.useState([-1, -2]);
  const [editTask, setEditTask] = React.useState(null);

  React.useEffect(
    () => {
      setTasks(props.items);
      _setFilteredTasks(listFilter, props.items);
    },
    [props.items]
  );

  const classes = useStyles();

  function onListChange(type) {
    //var newArray = type.filter();
    setListFilter(type);
    _setFilteredTasks(type);
  }

  function _setFilteredTasks(listFilter, loopTasks) {
    if (!loopTasks) loopTasks = tasks;
    var showMyTasks = false;
    var showMyRequests = false;
    var showForIds = [];

    listFilter.forEach(filter => {
      if (filter == -1) showMyTasks = true;
      else if (filter == -2) showMyRequests = true;
      else showForIds.push(filter);
    });
    var foundTasks = loopTasks.filter(item => {
      var found = false;

      if (showMyTasks && item.ownerId == props.user.id) found = true;
      if (showMyRequests && item.ownerId != props.user.id && item.createdById == props.user.id) found = true;
      if (showForIds.indexOf(item.ownerId) > -1) found = true;

      return found;
    });

    setFilteredTasks(foundTasks);
  }

  function onChange(item, key, value) {
    item[key] = value;
    props.onCreate(item).then(() => {
      var originalTasks = [...tasks];
      originalTasks.forEach(loopItem => {
        if (loopItem.id == item.id) loopItem[key] = value;
      });
      setTasks(originalTasks);
      _setFilteredTasks(listFilter, originalTasks);
    });
  }

  function onCancelEdit() {
    setEditTask(null);
  }

  function onCreate() {
    setEditTask({
      ownerId: props.user.id,
      __ownerId: props.user.name,
      estado: "pendiente",
      fechaVencimiento: moment().format("YYYY-MM-DD")
    });
  }

  function onSaveTask(item) {
    props.onCreate(item).then(() => {
      setEditTask(null);
    });
  }

  function onEdit(item) {
    setEditTask(item);
  }

  return (
    <div>
      <Grid container justify="space-between">
        <Filters
          setListFilter={setListFilter}
          listFilter={listFilter}
          users={props.users}
          dispatch={props.dispatch}
          onListChange={onListChange}
        />
        <Box m={2}>
          <Fab onClick={onCreate} color="primary">
            <Add />
          </Fab>
        </Box>
      </Grid>
      <Divider />
      <Grid className={classes.root} container direction="row" justify="space-around" alignItems="flex-start">
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <KanbanList
              onEdit={onEdit}
              onChange={onChange}
              title={"Pendientes"}
              items={filteredTasks.filter(item => {
                if (item.isSection) return false;
                return item.estado == "pendiente";
              })}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <KanbanList
              onEdit={onEdit}
              onChange={onChange}
              title={"por empezar"}
              items={filteredTasks.filter(item => {
                if (item.isSeccion) return false;
                return item.estado == "por empezar";
              })}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <KanbanList
              onEdit={onEdit}
              onChange={onChange}
              title={"Trabajando"}
              items={filteredTasks.filter(item => {
                if (item.isSeccion) return false;
                return item.estado == "trabajando";
              })}
            />
          </Paper>
        </Grid>
      </Grid>
      <EditModal
        onSaveTask={onSaveTask}
        handleClose={onCancelEdit}
        open={editTask != null}
        task={editTask}
        onCancelEdit={onCancelEdit}
      />
    </div>
  );
}
