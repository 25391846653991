import { alert } from "redux/reducers/header";

var Ajax = {
  authorization_code: null,
  cookieLoaded: false,
  port: 4000,
  api: "crm"
};

Ajax.getURL = function(path, params) {
  var realParts = [];

  path.split("/").forEach(part => {
    if (part && part.length > 0) realParts.push(part);
  });

  var fullPath;

  if (realParts.length == 2) fullPath = `api/${Ajax.api}/${realParts[0]}/${realParts[1]}`;
  else if (realParts.length == 3) fullPath = `api/${realParts[0]}/${realParts[1]}/${realParts[2]}`;
  else throw new Error("Path is not in correct format " + path);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
    var port = Ajax.port;
    if (realParts[0] == "login" || Ajax.api == "login") port = 4001;
    if (realParts[0] == "tesoreria" || Ajax.api == "tesoreria") port = 4002;
    if (realParts[0] == "contabilidad" || Ajax.api == "contabilidad") port = 4003;
    if (realParts[0] == "emails" || Ajax.api == "emails") port = 4004;
    return `http://localhost:${port}/${fullPath}`;
  } else return `/${fullPath}`;
};

Ajax.getLoginURL = function(path, params) {
  if (path[0] !== "/") path = "/" + path;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
    return `http://localhost:4001/api/login${path}`;
  } else return `/api/login${path}`;
};

Ajax.logout = function() {
  window.localStorage.setItem("authorization", null);
  window.location.reload();
};

Ajax.getHeaders = function(withToken) {
  var authorization = window.localStorage.getItem("authorization");
  var account = window.localStorage.getItem("account");

  if (authorization && authorization.length > 1 && withToken !== false)
    return new Headers({
      "Content-Type": "application/json",
      "x-authorization": authorization,
      "x-account": account || ""
    });
  else
    return new Headers({
      "Content-Type": "application/json",
      "x-account": account || ""
    });
};

Ajax.handleResponse = function(status, type, url, response, headers, dispatch) {
  var id = parseInt(Math.random() * 1000000);

  if (status < 300) return Promise.resolve(response);
  else if (status > 403 && status <= 500) {
    dispatch(alert(response.label || response.message, "warning", true));
    return Promise.reject(response);
  } else if (status === 401)
    dispatch({ type: "AJAX_401", payload: { id: id, status: status, error: response } });
  else if (status === 402)
    dispatch({ type: "AJAX_402", payload: { id: id, status: status, error: response } });
  else if (status === 403) dispatch(alert(response.label || response.message, "info"));
  else {
    dispatch(alert(response.label || response.message));
    console.log("Error", status, JSON.stringify(response));
  }
  return Promise.reject(response);
};

Ajax.get = function(dispatch, path, params) {
  var status;
  var headers;
  var url = Ajax.getURL(path, params);

  dispatch({ type: "AJAX_START" });

  return fetch(url, {
    headers: Ajax.getHeaders(true),
    mode: "cors",
    method: "GET"
  })
    .then(function(response) {
      dispatch({ type: "AJAX_COMPLETE" });

      headers = response.headers;
      status = response.status;
      if (status === 401) return {};
      if (response[0] !== "{") return JSON.stringify(response);
      return response.json();
    })
    .then(function(response) {
      return Ajax.handleResponse(status, "GET", url, response, headers);
    })
    .catch(function(err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ type: "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    });
};

Ajax.post = function(dispatch, path, body, params) {
  var status;
  var headers;
  var url = path.indexOf("https") > -1 ? path : Ajax.getURL(path, params);

  dispatch({ type: "AJAX_START" });

  return fetch(url, {
    headers: Ajax.getHeaders(true),
    body: JSON.stringify(body),
    mode: "cors",
    method: "POST"
  })
    .then(function(response) {
      dispatch({ type: "AJAX_COMPLETE" });
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function(response) {
      return Ajax.handleResponse(status, "POST", url, response, headers, dispatch);
    })
    .catch(function(err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ type: "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    });
};

Ajax.put = function(path, body, params) {
  var status;
  var headers;
  var url = Ajax.getURL(path, params);
  return fetch(url, {
    headers: Ajax.getHeaders(true),
    body: JSON.stringify(body),
    mode: "cors",
    method: "PUT"
  })
    .then(function(response) {
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function(response) {
      return Ajax.handleResponse(status, "PUT", url, response, headers);
    })
    .catch(function(err) {
      if (!headers) headers = {};
      return {
        errorType: "AJAX_HTTP_ERROR",
        error: err
      };
    });
};

Ajax.delete = function(path, params) {
  var status;
  var headers;
  var url = Ajax.getURL(path, params);
  return fetch(url, {
    headers: Ajax.getHeaders(true),
    mode: "cors",
    method: "DELETE"
  })
    .then(function(response) {
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function(response) {
      return Ajax.handleResponse(status, "DELETE", url, response, headers);
    })
    .catch(function(err) {
      if (!headers) headers = {};
      return {
        errorType: "AJAX_HTTP_ERROR",
        error: err
      };
    });
};

Ajax.login = function(dispatch, path, body, params) {
  var status;
  var headers;
  var url = Ajax.getLoginURL(path, params);

  dispatch({ type: "AJAX_START" });

  return fetch(url, {
    headers: Ajax.getHeaders(false),
    body: JSON.stringify(body),
    mode: "cors",
    method: "POST"
  })
    .then(function(response) {
      dispatch({ type: "AJAX_COMPLETE" });
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function(response) {
      return Ajax.handleResponse(status, "POST", url, response, headers, dispatch);
    })
    .catch(function(err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ type: "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    });
};

export default Ajax;
