import Finance from "redux/helpers/finance";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";
import { alert } from "redux/reducers/header";
import Dinero from "dinero.js";

Dinero.globalLocale = "es-CR";
Dinero.defaultPrecision = 5;

const onChange = (form, name, value, extra) => {
  var item = form.item;

  if (["clienteId"].indexOf(name) > -1) {
    if (item.clienteId)
      return {
        _errorInTransform: true,
        message:
          "El cliente no se puede cambiar, para corregir este campo debe crear otra orden."
      };

    return {
      ...item,
      clienteId: value,
      cedula: extra.cedula,
      ownerId: extra.ownerId,
      emails: extra.correoDocumentosElectronicos,
      transporteId: extra.transporteId || 1,
      __clienteId: extra.name,
      plazo: extra.creditoPlazo || 0
    };
  } else if (["ordenLinea"].indexOf(name) > -1) {
    value.forEach(linea => {
      //linea.excentoPorcentaje = form.item.excentoPorcentaje || 0;
      // _setImpuestos(linea);
    });
    return { ...item, ...Finance.ordenTotal(value), ordenLinea: value };
  } else if (["exoneradoPorcentaje"].indexOf(name) > -1) {
    if (!item.exoneradoTipo)
      return {
        _errorInTransform: true,
        message: "debe completa el campo tipo primero"
      };
    if (!item.exoneradoNumero)
      return {
        _errorInTransform: true,
        message: "debe completa el campo numero primero"
      };
    if (!item.exoneradoInstitucion)
      return {
        _errorInTransform: true,
        message: "debe completa el institucion tipo primero"
      };
    if (!item.exoneradoFecha)
      return {
        _errorInTransform: true,
        message: "debe completa el campo fecha primero"
      };

    var delta = {
      ...item,
      especial: true,
      [name]: value
    };

    return delta;
  }

  return { ...item, [name]: value };
};

export default onChange;
