import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from "recharts";
const styles = {
  card: {
    width: "100%"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;

  var value = props.value || [{ name: "a", value: 15 }, { name: "b", value: 29 }, { name: "c", value: 45 }];
  var metric = props.metric || {
    title: props.title || "Grafico",
    groupBy: { dateGroup: "name", groupBy: ["xxxxxx"], sumFields: "value" }
  };
  var loading = false;
  if (!props.value) loading = true;

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ marginBottom: 10 }}>
          <Typography className={classes.title} color="textSecondary">
            {metric.title}
          </Typography>
          <Typography style={{ fontSize: 11 }} variant="caption" color="textSecondary">
            {props.helpText}
          </Typography>
        </div>
        <BarChartComponent loading={loading} data={value} groupBy={metric.groupBy} />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(SimpleCard);

class BarChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ width: this.myRef.current.offsetWidth });
  }

  render() {
    var data = [];

    if (this.props.data) data = this.props.data;

    var data = data.map(groupItem => {
      var name = "N/A";
      var dateGroup = this.props.groupBy.dateGroup;
      name = dateGroup ? dateGroup.split("-")[1] : this.props.groupBy.groupBy[0] || "N/A";

      return {
        value: parseInt(groupItem[this.props.groupBy.sumFields] * 100) / 100,
        name: groupItem[name]
      };
    });

    return (
      <div ref={this.myRef}>
        <BarChart width={this.state.width || 0} height={100} data={data}>
          <XAxis dataKey="name" />
          <YAxis width={45} allowDecimals={false} />
          <Bar nameKey="name" dataKey="value" fill={this.props.loading ? "#ddd" : "#8884d8"} />
          <Tooltip formatter={value => numeral(value).format("(₡ 0.00 a)")} />
        </BarChart>
      </div>
    );
  }
}
