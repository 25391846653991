import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { loadUsers } from "redux/reducers/user";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 500,
    marginLeft: 20,
    marginBottom: 20
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

export default function MultipleSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const baseListFilter = [{ id: -1, name: "Mis Tareas" }, { id: -2, name: "Asignadas por mí" }];
  const [items, setItems] = React.useState(baseListFilter);

  React.useEffect(() => {
    props.dispatch(loadUsers());
  }, []);

  React.useEffect(
    () => {
      var users = props.users;
      users.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (b.name > a.name) return -1;
        return 0;
      });
      if (props.users) setItems(baseListFilter.concat(users));
    },
    [props.users]
  );

  function handleChange(event) {
    props.onListChange(event.target.value);
  }

  function onClick() {
    props.dispatch(loadUsers());
  }

  return (
    <div className={classes.root}>
      <FormControl className={clsx(classes.formControl, classes.noLabel)}>
        <Select
          onClick={onClick}
          multiple
          displayEmpty
          value={props.listFilter}
          onChange={handleChange}
          input={<Input id="select-multiple-placeholder" />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <em>Tipo de Lista</em>;
            }
            var seletedFilter = items.filter(item2 => {
              var found = false;
              selected.forEach(sel => {
                if (item2.id == sel) found = true;
              });
              return found;
            });
            return seletedFilter.map(item => item.name).join(", ");
          }}
          MenuProps={MenuProps}>
          {items.map(item => (
            <MenuItem key={item.name} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
