import React, { Fragment } from "react";
import PropTypes, { nominalTypeHack } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { alert } from "redux/reducers/header";
import StarIcon from "@material-ui/icons/Star";
import { IconButton } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import amber from "@material-ui/core/colors/amber";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";

const styles = theme => ({
  root: {
    minHeight: 80,
    marginBottom: 40,
    position: "relative"
  },
  inline: {
    display: "inline"
  },
  fab: {
    position: "absolute",
    right: 0,
    bottom: -25
  },
  button: {
    margin: 7
  },
  textfield: {
    margin: "0px 10px"
  },
  icon: {
    margin: theme.spacing(2)
  },
  iconSelected: {
    color: amber[500]
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    width: "90%"
  },
  inputProps: {
    fontSize: 11
  },
  avatar: {
    margin: 7
  },
  orangeAvatar: {
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500]
  }
});

class AlignItemsList extends React.Component {
  state = {
    message: "",
    creating: false,
    contacto: { name: "", email: "" }
  };

  componentDidMount() {
    if (this.props.onMount) this.props.onMount();
  }

  handleChange = name => event => {
    this.setState({ contacto: { ...this.state.contacto, [name]: event.target.value } });
  };

  onClick = () => {
    this.setState({ creating: true });
  };

  onCreateNota = () => {
    this.setState({ creating: true });
  };

  onSaveContacto = () => {
    if (
      this.state.contacto.name.length == -1 ||
      this.state.contacto.email.length == -1 ||
      this.state.contacto.email.indexOf("@") == -1
    )
      return this.props.dispatch(
        alert("Revise el nombre y email del contacto. Ambos deben estar completos y correctos")
      );
    this.props.onCreate(this.state.contacto, () => {
      this.onCancelContacto();
    });
  };

  onCancelContacto = () => {
    this.setState({ creating: false, contacto: { name: "", email: "" } });
  };

  onEdit = id => {
    return () => {
      this.props.onEdit(id);
    };
  };

  renderCreate() {
    const { classes } = this.props;
    if (!this.props.onCreate) return null;
    return (
      <div>
        {this.state.creating ? (
          <div className={{ borderBottom: "3px solid rgba(240,240,240,0.9)", marginBottom: 20 }}>
            <div>
              <TextField
                autoFocus
                label="Nombre"
                className={classes.textField}
                placeholder=" "
                inputStyle={{ fontSize: "11px" }}
                value={this.state.contacto.name || ""}
                onClick={this.onClick}
                InputProps={{
                  classes: {
                    input: classes.inputProps
                  }
                }}
                onChange={this.handleChange("name")}
              />
              <TextField
                label="Email"
                placeholder="@"
                inputStyle={{ fontSize: "11px" }}
                className={classes.textField}
                value={this.state.contacto.email || ""}
                InputProps={{
                  classes: {
                    input: classes.inputProps
                  }
                }}
                type="email"
                onChange={this.handleChange("email")}
              />
              <div style={{ marginLeft: 8 }} />
            </div>

            <Button
              onClick={this.onCancelContacto}
              variant="outlined"
              size="small"
              color="default"
              className={classes.button}>
              Cancelar
            </Button>
            <Button
              onClick={this.onSaveContacto}
              size="small"
              variant="outlined"
              color="primary"
              className={classes.button}>
              Guardar
            </Button>
          </div>
        ) : (
          <TextField
            placeholder="Escriba el nombre del contacto"
            className={classes.textField}
            value={this.state.contacto.name}
            onClick={this.onClick}
            onChange={this.handleChange("name")}
          />
        )}
      </div>
    );
  }

  renderList() {
    const { classes } = this.props;
    var props = this.props;

    var items = <ListItem>No hay contactos!</ListItem>;
    if (props.items.length > 0) {
      items = props.items.map(item => {
        return (
          <div>
            <ListItem dense onClick={this.onEdit(item.id)} alignItems="flex-start">
              <ListItemText
                primaryTypographyProps={{ style: { fontSize: 12 } }}
                primary={`${item.name} (${item.rol || "sin rol"})`}
                secondary={item.email}
              />
            </ListItem>
          </div>
        );
      });
    }

    return items;
  }

  render() {
    const { classes } = this.props;

    return (
      <div elevation={4} className={classes.root}>
        <Typography variant="overline">Contactos</Typography>
        {this.renderCreate()}
        {this.renderList()}
      </div>
    );
  }
}

AlignItemsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlignItemsList);
