import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";

import Colibri from "assets/img/colibri.svg";
import Toolbar from "@material-ui/core/Toolbar";
import Snackbar from "components/snackbar";
import IconButton from "@material-ui/core/IconButton";
import LineLoader from "components/lineLoader";

import { connect } from "react-redux";
import ToolBarRouteSelector from "components/ToolBarRouteSelector";
import { simpleApi } from "redux/reducers/api";
import { alert } from "redux/reducers/header";
import Support from "components/Support/Support";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({

  logo: {
    width: "48px",
    left: 13,
    top: 0,
    padding: 0
  },

  logoName: {
    marginLeft: 0,
    marginRight: 12,
    paddingTop: 4,
    borderRight: "0.5px solid #ddd",
    paddingRight: 22,
    [theme.breakpoints.down("md")]: {
      display: "none",
      marginRight: 0,
      fontSize: 12
    }
  },
  root: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1,
    textTransform: "capitalize",
    flexGrow: 1,
    width: "100%"
  },

  flex: {
    flexGrow: 1
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0,
    padding: 6,
    position: "relative",
    top: 0,
    left: -10,
    [theme.breakpoints.down("md")]: {
      left: -10,
      top: -2
    }
  },
  hide: {
    display: "none"
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  tabLabel: {
    color: grey[50]
  },
  tab: {
    width: "100%"
  },
  tabIndicator: {
    borderColor: theme.palette.primary[600]
  },
  divider: {
    height: 20,
    width: 1,
    marginRight: 10,
    marginLeft: 15,
    backgroundColor: theme.palette.grey[100]
  },
  iconBar: {
    position: "absolute",
    right: 8,
    top: 3,
    marginLeft: 0
  },
  link: {
    color: "#fff",
    "&a:visited": {
      color: "#fff !important"
    }
  },
  home: {
    position: "absolute",
    right: 55,
    top: 0,
    marginLeft: 0,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      marginRight: 0,
      fontSize: 12
    }
  }
});

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user
  };
};

class AppToolbar extends React.Component {
  state = {
    value: 0
  };

  handleChange = value => {
    var props = this.props;
    var parts = props.location.pathname.split("/");
    parts.pop();
    parts.push(value);
    this.props.history.push(parts.join("/"));
  };

  getTabIndex = () => {
    var index = null;
    Object.keys(this.props.tabs).forEach((tabKey, counter) => {
      if (index == null && this.props.location.pathname.indexOf(tabKey) > -1) index = counter;
    });
    return index || 0;
  };

  handleSoporte = () => {
    this.props
      .dispatch(
        simpleApi("https://devops.efactura.io/api/support/video", {
          path: window.location.href,
          user: this.props.user,
          link: window.FS.getCurrentSessionURL(true)
        })
      )
      .then(() => {
        return this.props.dispatch(alert("Se envio el video a soporte para su analisis.", "info"));
      })
      .catch(() => {
        return this.props.dispatch(
          alert(
            "No se pudo enviar el momento, favor fijese en el tiempo ( hora minuto y segundo ) y adjuntelo al reporte de error en soporte."
          )
        );
      });
  };

  renderIconBar(positionRight) {
    const { classes } = this.props;
    return (
      <div className={classes.iconBar}>
        <Grid container>
          <Grid item>
            <Support color="primary" user={this.props.user} dispatch={this.props.dispatch} />
          </Grid>
          <Grid item>
            <Avatar
              src={(this.props.user && this.props.user.avatar) || "/img/default-avatar.png"}
              className={classes.avatar}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    var isAction = this.props.location.pathname.split("/").length > 3;
    var rootposition = isAction ? "fixed" : "relative";

    return (
      <div className={classes.root} style={{position: rootposition}}>
        <AppBar position="static" color="primary">
          <Snackbar />
          <Toolbar variant="dense">
            {isAction ? (
              <Fragment>
                <IconButton color="inherit" aria-label="Open drawer" className={classes.menuButton}>
                  <img className={classes.logo} src={Colibri} />
                </IconButton>
                {this.renderIconBar(true)}
              </Fragment>
            ) : (
              <Fragment>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.props.handleDrawerOpen}
                  className={classes.menuButton}>
                  <img className={classes.logo} src={Colibri} />
                </IconButton>

                <Typography color="inherit" className={classes.logoName} noWrap>
                  {this.props.user ? this.props.user.account : ""}
                </Typography>
                <div style={{ paddingTop: 10 }}>
                  <ToolBarRouteSelector
                    location={this.props.location}
                    routes={this.props.routes}
                    onChange={this.handleChange}
                  />
                </div>
                {this.renderIconBar()}
              </Fragment>
            )}
          </Toolbar>
          <LineLoader />
        </AppBar>
      </div>
    );
  }
}

AppToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(AppToolbar));
