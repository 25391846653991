import React from "react";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import numeral from "numeral";

const styles = theme => {
  return {
    link: {
      color: theme.palette.primary[900],
      textTransform: "capitalize"
    },
    textField: {
      margin: 10
    }
  };
};

const mapStateToProps = (store, props) => {
  return {};
};

class PrecioFormatter extends React.Component {
  state = {
    cliente: { saldos: [], loading: true },
    open: false,
    item: null
  };

  componentDidMount() {
    var item = this.props.row["_" + this.props.column.key];
    console.log(this.props.column.key, item, this.props.row);

    if (item) this.setState({ item: item, grupoId: item.grupoId });
  }

  onSave = () => {
    this.props
      .dispatch(
        simpleApi("priceList/singleUpdate", {
          id: this.props.row.id,
          precio: this.state.item.precio,
          descuento: this.state.item.descuento,
          grupoId: this.state.item.grupoId
        })
      )
      .then(cliente => {
        this.props.handleUpdate({
          cellKey: this.props.column.key,
          updated: { [this.props.column.key]: "", ["_" + this.props.column.key]: this.state.item },
          fromRowId: this.props.row.id
        });
        this.handleClose();
      });
  };

  onShowModal = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleComplete = fileKey => {
    this.setState({ open: false });
    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: fileKey },
      fromRowId: this.props.row.id
    });
  };

  onChangeValue = key => {
    return e => {
      this.setState({
        item: { ...this.state.item, [key]: parseFloat(e.currentTarget.value || e.currentTarget.dataset.key) }
      });
    };
  };

  renderModal() {
    const { classes } = this.props;
    if (this.state.open == false) return;
    var row = this.props.row;
    var item = this.state.item || { precio: 0, descuento: 0 };
    return (
      <div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          maxWidth={"md"}
          fullWidth
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">Editar Precio Directo</DialogTitle>

          <DialogContent>
            <Typography variant="body1" style={{ marginBottom: 30 }} gutterBottom>
              Los cambios se aplican inmediatamente.
            </Typography>

            <Grid container>
              <Grid item xs={6}>
                <div className={classes.textField}>
                  <TextField
                    className={classes.input}
                    fullWidth
                    autoFocus
                    onChange={this.onChangeValue("precio")}
                    value={item.precio}
                    label="Precio"
                    type="number"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.textField}>
                  <TextField
                    className={classes.input}
                    fullWidth
                    onChange={this.onChangeValue("descuento")}
                    value={item.descuento}
                    label="Descuento"
                    type="number"
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSave} color="primary">
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  renderIcon() {
    return this.props.value;
  }

  getValue() {
    if (!this.state.item) return "-";
    var item = this.state.item;

    var precioConDescuento = item.precio - (item.descuento / 100) * item.precio;
    var utilidad = (precioConDescuento - item.costo) / precioConDescuento;
    var utilidadString;
    if (utilidad > 99) utilidadString = "";
    else utilidadString = parseInt(utilidad * 10000) / 100 + "% c/";

    var value =
      utilidadString + numeral(item.precio).format("0,0.00") + " - " + (item.descuento || "0") + "%";
    return value;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.renderModal()}
        <a onClick={this.onShowModal} className={classes.link}>
          {this.getValue()}
        </a>
      </div>
    );
  }
}

const PrecioFormatterStyle = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(PrecioFormatter)
);

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <PrecioFormatterStyle
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
