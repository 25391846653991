import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { onCreateForm, onUpdateItem, selectFormById } from "redux/reducers/form";
import { selectDataByType, onSave } from "redux/reducers/data";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";

import { connect } from "react-redux";

import moment from "moment";

import grey from "@material-ui/core/colors/grey";

import Form from "./form";

export const styles = theme => ({
  root: {},
  main: {
    margin: theme.spacing(6),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),

    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      margin: 0
    }
  },
  "$dialog main": {
    margin: theme.spacing(1)
  },
  toolbar: {
    margin: 0,
    marginTop: "10px",
    marginBottom: 0,
    position: "fixed",
    zIndex: theme.zIndex.drawer + 2,
    top: -23,
    left: 0,
    width: "95%"
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary[800],
    border: "1px solid #fff"
  },
  icon: {
    color: "#fff"
  },
  dialog: {
    backgroundColor: grey[100]
  },
  title: {
    top: -5,
    left: 85,
    position: "relative",
    color: "#fff"
  }
});

export function makeCreate(transformOnChange, typeMap) {
  var Create = class Any extends BaseCreate {
    _componentDidMount() {
      if (!this.props.form) {
        this.props.dispatch(
          onCreateForm({
            id: this.props.formId,
            type: this.props.metadataType,
            transformOnChange: transformOnChange
          })
        );
      }

      if (this.props.metadata) this.getDefault(this.props);
      else this.props.dispatch(onLoadMetaData(this.props.metadataType, null, { source: "create" }));
    }

    getTypeMap() {
      return typeMap || {};
    }
  };

  const mapStateToProps = (state, ownProps) => {
    return {
      form: selectFormById(state, ownProps.formId),
      metadata: selectMetadataByType(state, ownProps.metadataType),
      dataRows: selectDataByType(state, ownProps.metadataType)
    };
  };

  const ConnectedCreate = connect(mapStateToProps)(Create);

  return withStyles(styles, { withTheme: true })(ConnectedCreate);
}

class BaseCreate extends Form {
  constructor() {
    super();
    this.state = {
      dialogOpen: false,
      loadingMetadata: false
    };
  }

  _componentDidMount() {
    if (!this.props.form) {
      this.props.dispatch(onCreateForm({ id: this.props.formId, type: this.props.metadataType }));
    }

    if (this.props.metadata) this.getDefault(this.props);
    else this.props.dispatch(onLoadMetaData(this.props.metadataType, null, { source: "create" }));
  }

  componentDidMount() {
    return this._componentDidMount();
  }

  _componentWillReceiveProps(nextProps) {
    if ((!this.props.metadata && nextProps.metadata) || (!this.props.form && nextProps.form)) {
      this.getDefault(nextProps);
    }
  }

  getDefault(props) {
    if (!props.form || !props.metadata) return;

    var defaultItem = { ...props.form.item };
    Object.keys(props.metadata.properties).forEach(key => {
      var property = props.metadata.properties[key];
      if (
        defaultItem[key] == null &&
        (property.default || property.default == 0 || property.default == false)
      ) {
        if (property.default == "TODAY") defaultItem[key] = moment().format("YYYY-MM-DD");
        else defaultItem[key] = property.default;
      }
    });
    props.dispatch(onUpdateItem(props.formId, defaultItem, props.metadata));
  }

  componentWillReceiveProps(nextProps) {
    return this._componentWillReceiveProps(nextProps);
  }

  onSave = () => {
    var item = {};
    var keys = Object.keys(this.props.form.item);
    keys.forEach(key => {
      if (key.indexOf("__") == 0) return;
      item[key] = this.props.form.item[key];
    });

    return this.props
      .dispatch(onSave(this.props.metadataType, item))
      .then(result => {
        if (this.props.history)
          this.props.history.push(`/${this.props.metadata.namespace}/${this.props.metadataType}`);
        else return this.props.onClose(result, this.onFieldChange.bind(this));
        return result;
      })
      .catch(function(e) {
        console.log(e);
      });
  };
}

export default BaseCreate;
