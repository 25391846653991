import React, { Fragment } from "react";
import ClickNHold from "react-click-n-hold";
import withStyles from "@material-ui/core/styles/withStyles";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TableIcon from "@material-ui/icons/CallMade";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { showSmartView } from "redux/reducers/ui/smartView";
const mapStateToProps = (store, props) => {
  return {};
};

const style = {
  idText: {
    width: "100%",
    textAlign: "center"
  },
  icon: {
    "&:first-child": {
      marginRight: 4
    },

    "&:hover": {
      backgroundColor: "#dedede",
      borderRadius: 5
    }
  }
};
class LinkFormatter extends React.Component {
  viewItem = id => {
    return this.props.dispatch(
      showSmartView({ type: this.props.column.metadataType, id: id, item: this.props.row })
    );
  };

  onClick = e => {
    var url =
      "/" +
      this.props.tableProps.metadata.namespace +
      "/" +
      this.props.column.metadataType +
      "/edit/" +
      this.props.row.id;
    this.props.tableProps.history.push(url);
  };

  onIconClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  clickView = e => {
    e.stopPropagation();
    e.preventDefault();
    this.viewItem(this.props.value);
  };

  clickEdit = e => {
    e.stopPropagation();
    e.preventDefault();
    this.onClick();
  };

  clickNHold = e => {
    this.viewItem(this.props.value);
  };
  render() {
    var classes = this.props.classes;
    var value = this.props.value;
    var defaultValue = "";
    if (!value || value.length == 0) value = defaultValue;

    var hasEdit = false;

    var hasView = false;

    this.props.tableProps.metadata.actions.map(action => {
      if (action.title == "Editar") {
        hasEdit = true;
        defaultValue = "";
      } else if (action.title == "Ver") {
        hasView = true;
      }
    });

    return (
      <div className="idCell">
        <ClickNHold
          time={1} // Time to keep pressing. Default is 2
          onClickNHold={this.clickNHold}>
          <Fragment>
            <div style={{ marginTop: 10 }} className="buttons">
              {hasEdit ? (
                <EditIcon onClick={this.clickEdit} fontSize="small" className={classes.icon} />
              ) : null}

              {hasView ? (
                <TableIcon onClick={this.clickView} fontSize="small" className={classes.icon} />
              ) : null}
            </div>
            <Typography style={{ fontSize: value > 10000 ? 9 : 11 }}>
              <Link onClick={this.onClick}>{value}</Link>
            </Typography>
          </Fragment>
        </ClickNHold>
      </div>
    );
  }
}

var LinkFormatterStyle = withStyles(style)(LinkFormatter);

export default function(column, reload, getRow, handleUpdate, tableProps) {
  let cls = class extends React.Component {
    render() {
      return (
        <LinkFormatterStyle
          {...this.props}
          handleUpdate={handleUpdate}
          tableProps={tableProps}
          dispatch={tableProps.dispatch}
          reload={reload}
          getRow={getRow}
          column={column}
        />
      );
    }
  };
  return cls;
}
