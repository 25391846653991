import React, { lazy } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from "@material-ui/core/AppBar";

import CoreButton from "@material-ui/core/Button";

// core components

import Button from "components/CustomButtons/Button.jsx";

import { connect } from "react-redux";

// material-ui components

import { simpleApi } from "redux/reducers/api";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import PureForm from "./PureForm";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    }
  };
};

const mapStateToProps = (store, props) => {
  return {};
};

class SmartForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(this.props.formId, name, value, extra);
  };

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onKeyPress = e => {};

  onSave = e => {
    this.props.onSave(this.props.form.item);
  };

  renderBackIcon() {
    var icon = <ArrowBack />;
    if (this.props.modal) icon = <CloseIcon />;
    return (
      <Button onClick={this.props.onGoBack} justIcon round>
        {icon}
      </Button>
    );
  }

  renderSaveIcon() {
    return (
      <Tooltip
        id="tooltip-top"
        title="Guardar"
        placement="top"
        classes={{ tooltip: this.props.classes.tooltip }}>
        <CoreButton onClick={this.onSave} justIcon size="lg" round color="success">
          <Check />
        </CoreButton>
      </Tooltip>
    );
  }

  renderError() {
    if (this.props.form.error && this.props.form.error.errorType == "AJAX_API_ERROR") {
      return <SnackbarContent message={this.props.form.error.error.label} close place="tr" color="danger" />;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderError()}
        <div style={{ width: "calc(100%)" }}>
          <PureForm {...this.props} onFieldChange={this.onFieldChange} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartForm));
