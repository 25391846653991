import "./App.css";
import React, { Suspense, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import RouteBase from "views/base/route";
import SideBar from "./components/Sidebar";
import Login from "components/Login";
import AppHome from "./views/home";
import { connect } from "react-redux";
import { AppMap, getMenu } from "./AppMap";
import Notifier from "./components/Notifier";

import { initMetadata } from "redux/reducers/metadata";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1
  },
  sidebar: {
    zIndex: theme.zIndex.drawer - 2
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    width: "102vw",
    backgroundColor: theme.palette.background.default
  }
});

const mapStateToProps = (store, props) => {
  return {
    authenticated: store.user.authenticated
  };
};

class AppLogin extends React.Component {
  render() {
    if (this.props.authenticated) return null;
    return <Login />;
  }
}

let ConnectedAppLogin = connect(mapStateToProps)(AppLogin);

class App extends React.Component {
  state = {
    open: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <SideBar
          className={classes.sidebar}
          items={getMenu()}
          open={this.state.open}
          onClose={this.handleDrawerClose}
        />
        <main className={classes.content}>
          <Switch>
            <Route
              path="/:type"
              render={props => {
                return (
                  <Fragment>
                    <RouteBase
                      handleDrawerOpen={this.handleDrawerOpen}
                      namespace={props.match.params.type}
                      menu={AppMap[props.match.params.type]}
                      operationMap={AppMap[props.match.params.type]}
                      location={props.location}
                      history={props.history}
                    />
                    <ConnectedAppLogin />
                  </Fragment>
                );
              }}
            />
            <Route
              path="/"
              render={() => {
                return <AppHome />;
              }}
            />
          </Switch>
        </main>
        <Notifier />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
