import moment from "moment";

const onFacturaCxPFieldChange = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };

  if (name == "total") return form.item;
  else if (name == "proveedorId") {
    delta = {
      ...delta,
      fechaProgramacion: moment()
        .add(extra.plazoPago || 0, "days")
        .format("YYYY-MM-DD"),
      fechaVencimiento: moment()
        .add(extra.plazoPago || 0, "days")
        .format("YYYY-MM-DD")
    };
  } else if (["subTotal", "descuento", "impuesto"].indexOf(name) > -1) {
    if (!delta.subTotal) delta.subTotal = 0;
    if (!delta.impuesto) delta.impuesto = 0;
    if (!delta.descuento) delta.descuento = 0;
    delta.total = delta.subTotal + delta.impuesto - delta.descuento;
  }
  return delta;
};

export default onFacturaCxPFieldChange;
