import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
} from "assets/jss/material-dashboard-pro-react.jsx";
const cardIconStyle = {
  cardAction: {
    
      
      position: "absolute",
      right: -5,
      top: 3,
      "& button": {
        marginRight: "10px"
      },
 
      "& svg": {
        width: "24px",
        height: "24px",
        textAlign: "center",
        lineHeight: "20px",
        margin: "0px 4px 0px",
        marginTop: "-3px"
      }
    
  }
};

export default cardIconStyle;
