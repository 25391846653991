import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";

import { Link } from "react-router-dom";

import TabletMac from "@material-ui/icons/TabletMac";
import LocationCity from "@material-ui/icons/LocationCity";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Camera from "@material-ui/icons/Camera";
import MoveToInbox from "@material-ui/icons/MoveToInbox";
import Payment from "@material-ui/icons/Payment";
import Exit from "@material-ui/icons/ExitToApp";
import Visibility from "@material-ui/icons/Visibility";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Settings from "@material-ui/icons/Settings";
import CardHeader from "components/Card/CardHeader.jsx";
import Colibri from "assets/img/colibri.svg";
import { connect } from "react-redux";
import { onLogout } from "redux/reducers/user";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const iconMap = {
  TabletMac: TabletMac,
  LocationCity: LocationCity,
  AccountBalance: AccountBalance,
  MoveToInbox: MoveToInbox,
  Payment: Payment,
  AttachMoney: AttachMoney,
  Settings: Settings,
  Visibility: Visibility,
  Camera: Camera
};

const styles = theme => ({
  list: {
    width: 250,
    opacity: ".53",
    color: "#fff",
    height: "100%",
    opacity: ".93",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    transition: "all 300ms linear"
  },
  background: {},

  logo: {
    width: "65%",
    padding: "15% 5%",
    paddingRight: 0
  },

  bgImage: {
    backgroundSize: "100%",
    boxShadow: "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)"
  },
  root: {
    backgroundColor: "#544ecc",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%23b3dfff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23a8d1f2' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%239ec2e6' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%2393b4d9' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%238aa6cc' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%238098bf' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23778bb2' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%236f7da5' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23667098' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%235e638b' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%2356567d' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%234e4a70' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23463d63' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%233f3156' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23372649' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%232f1a3d' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23280f30' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23210024' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },

  sidebarRoot: {},
  subheader: {
    background: "rgba(255,255,255,0.1)",
    color: "#fff",
    position: "relative",
    textDecoration: "none",
    fontSize: 16,
    textShadow: "1px 2px 2px #999"
  },
  item: {
    background: "rgba(255,255,255,0.2)",
    color: "#fff",
    position: "relative",
    textDecoration: "none",
    textShadow: "0.1px 1px 1px #999"
  },
  text: {
    color: "#fff",
    fontWeight: 400,
    fontSize: 17,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  icon: {
    fill: "#fff"
  }
});

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user
  };
};

class Menu extends React.Component {
  state = {
    open: ""
  };

  onOpen = e => {
    var id = e.currentTarget.dataset.id;
    var open = id;
    if (this.state.open == id) open = "";
    this.setState({ open: open });
  };

  onClose = e => {
    this.setState({ open: "" });
  };

  onLogout = () => {
    this.props.dispatch(onLogout());
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <List
          subheader={
            <ListSubheader inset={false} classes={{ root: classes.subheader }} component="div">
              {this.props.user ? this.props.user.name : ""}
            </ListSubheader>
          }
          className={classes.list}>
          {this.props.items.map(item => {
            var Icon = iconMap[item.icon];
            var routeKeys = Object.keys(item.routes);
            return (
              <Fragment>
                <ListItem
                  data-id={item.id}
                  onClick={this.onOpen}
                  className={classes.item}
                  key={item.id}
                  button>
                  <ListItemIcon>
                    <Icon className={classes.icon} />
                  </ListItemIcon>

                  <ListItemText
                    className={classes.text}
                    primary={item.title}
                    classes={{ primary: classes.text }}
                  />

                  {this.state.open == item.id ? (
                    <ExpandLess onClick={this.onClose} />
                  ) : (
                    <ExpandMore data-id={item.id} />
                  )}
                </ListItem>

                <Collapse in={this.state.open == item.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {routeKeys.map(subItem => {
                      return (
                        <ListItem onClick={this.props.onClose} button className={classes.nested}>
                          <Link to={"/" + item.id + "/" + subItem}>
                            <ListItemText
                              className={classes.text}
                              primary={item.routes[subItem].title}
                              inset
                              classes={{ primary: classes.text }}
                            />
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
          <ListItem onClick={this.props.onClose} className={classes.item} button>
            <ListItemIcon>
              <Exit />
            </ListItemIcon>
            <ListItemText
              className={classes.text}
              primary={"Salir"}
              classes={{ primary: classes.text }}
              onClick={this.onLogout}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Menu));
