import Ajax from "../../helpers/ajax";
import Finance from "../../helpers/finance";

//import { setPrecioAndDescuento } from "../helpers/precios";
import moment from "moment";
import { alert } from "redux/reducers/header";
import { loadSmartView } from "../ui/smartView";

var init = {
  view: "HOME",
  pedidosById: {},
  recibosById: {},
  clientesById: {},
  productosById: {},
  gruposById: {},
  transportesById: {},
  priceListsById: {},
  departamentosById: [],
  categoriasByDepartamento: [],
  gruposByCategoria: [],
  selectedClienteId: null,
  selectedPedidoId: null,
  selectedDocumentoId: null,
  selectedCategoriaId: null,
  selectedGrupoId: null,
  selectedReciboId: null
};

export function initVisitTablet() {
  return function(dispatch, getState) {
    try {
      var pedidos = JSON.parse(window.localStorage.getItem("pedidos") || "[]");
      var recibos = JSON.parse(window.localStorage.getItem("recibos") || "[]");
      var clientes = JSON.parse(window.localStorage.getItem("clientes") || "[]");
      var productos = JSON.parse(window.localStorage.getItem("productos") || "[]");
      var transportes = JSON.parse(window.localStorage.getItem("transportes") || "[]");

      pedidos.forEach(pedido => {
        init.pedidosById[pedido.id] = pedido;
      });

      clientes.forEach(cliente => {
        init.clientesById[cliente.id] = cliente;
      });

      init = catalogProductos(init, productos);

      try {
        productos.forEach(producto => {
          producto.precios.forEach(precio => {
            init.priceListsById[precio.grupoId] = precio;
          });
        });
      } catch (e) {}

      recibos.forEach(recibo => {
        init.recibosById[recibo.id] = recibo;
      });

      transportes.forEach(transporte => {
        init.transportesById[transporte.id] = transporte;
      });

      dispatch({ type: "VISITA_UPDATE", payload: init });
      return dispatch(pullFromServer());
    } catch (e) {
      console.log(e);
    }
  };
}

function catalogProductos(wrapper, results) {
  if (!results) return wrapper;
  try {
    var categoriasByDepartamento = {};
    var gruposByCategoria = {};
    var byId = {};
    var productos = [];
    var departamentos = [];
    var departamentosMap = {};
    var productosById = {};

    results.forEach(result => {
      if (!result.productos) return;
      byId[result.id] = result;
      productos = productos.concat(result.productos.filter(producto => producto != null));

      if (!departamentosMap[result.productoDepartamentoName]) {
        departamentosMap[result.productoDepartamentoName] = true;
        departamentos.push({
          name: result.productoDepartamentoName,
          id: result.productoDepartamentoId,
          orden: result.productoDepartamentoOrden
        });
      }

      var grupoByCategoria = gruposByCategoria[result.productoCategoriaId] || [];
      var grupos = grupoByCategoria.filter(item => {
        return item.id === result.productoGrupoId;
      });

      if (grupos.length === 0) {
        grupoByCategoria.push({
          id: result.id,
          name: result.name,
          tipos: result.tipos,
          descripcion: result.descripcion,
          departamentoName: result.productoDepartamentoName,
          photoUrl: result.photoUrl
        });
      }
      var categoriaByDepartamento = categoriasByDepartamento[result.productoDepartamentoName] || [];
      var categorias = categoriaByDepartamento.filter(item => {
        return item.id === result.productoCategoriaId;
      });
      if (categorias.length === 0)
        categoriaByDepartamento.push({
          id: result.productoCategoriaId,
          name: result.productoCategoriaName,
          orden: result.productoCategoriaOrden,
          descripcion: result.productoCategoriaDescripcion
        });

      gruposByCategoria[result.productoCategoriaId] = grupoByCategoria;
      categoriasByDepartamento[result.productoDepartamentoName] = categoriaByDepartamento;
    });

    var departamentosById = departamentos.reduce(function(map, obj) {
      map[obj.id] = obj;
      return map;
    }, {});

    var productosById = productos.reduce(function(map, obj) {
      map[obj.id] = obj;
      return map;
    }, {});

    return {
      ...wrapper,
      departamentosById: departamentosById,
      gruposById: byId,
      productosById: productosById,
      categoriasByDepartamento: categoriasByDepartamento,
      gruposByCategoria: gruposByCategoria
    };
  } catch (e) {
    console.log(e);
    return wrapper;
  }
}

export function pullFromServer() {
  return async function(dispatch, getState) {
    if (!window.navigator.onLine) return Promise.resolve({});

    try {
      var loadPromises = [
        Ajax.post(dispatch, "cliente/forTablet"),
        Ajax.post(dispatch, "productoGrupo/queryWithProductos"),
        Ajax.post(dispatch, "transporte/query")
      ];
      var promiseResponse = await Promise.all(loadPromises);
      var init = { clientesById: {}, transportesById: {} };
      promiseResponse[0].forEach(cliente => {
        init.clientesById[cliente.id] = cliente;
      });
      promiseResponse[2].forEach(transporte => {
        init.transportesById[transporte.id] = transporte;
      });

      init = catalogProductos(init, promiseResponse[1]);
      dispatch({ type: "VISITA_UPDATE", payload: init });
    } catch (e) {
      console.log(e);
      //Handle Load Error
    }
  };
}

export function onVisitaEnviarPedido(pedidoId) {
  return function(dispatch, getState) {
    var state = getState();

    var pedido = getVisitaById(getState(), "pedidos", pedidoId);
    var cliente = getVisitaById(getState(), "clientes", pedido.clienteId);

    var originalId = pedido.id;
    var pedidoLinea = pedido.pedidoLinea;
    var newPedido = { ...pedido, pedidoLinea: null, ordenLinea: pedidoLinea };
    newPedido.estado = "por aprobar";
    newPedido.autoAplicar = true;

    newPedido.cedula = cliente.cedula;
    newPedido.emails = cliente.correoDocumentosElectronicos;
    newPedido.transporteId = pedido.transporteId || cliente.transporteId || 1;
    newPedido.plazo = cliente.creditoPlazo || 0;

    newPedido.especial = false;
    delete newPedido.id;

    newPedido.ordenLinea.forEach(linea => {
      delete linea.id;
      delete linea.descuentos;
      delete linea.precios;
    });

    return Ajax.post(dispatch, "orden/create", { ...newPedido })
      .then(function(result) {
        dispatch(onDeletePedido(originalId));
        dispatch(alert("El pedido se envio con exito", "success", true));
      })
      .catch(function(apiError) {
        dispatch(alert("Ocurrio un error enviando la orden"));
      });
  };
}

export function onVisitaBackHome() {
  return async function(dispatch, getState) {
    dispatch({
      type: "VISITA_UPDATE",
      payload: { view: "HOME", selectedPedidoId: null, selectedClienteId: null }
    });
  };
}

export function onVisitaBackToPedido() {
  return async function(dispatch, getState) {
    dispatch({
      type: "VISITA_UPDATE",
      payload: { view: "PEDIDO" }
    });
  };
}

export function onVisitaNewPedido() {
  return async function(dispatch, getState) {
    var state = getState();
    if (!state.visitaTablet.selectedClienteId) return dispatch(alert("Debe escoger un cliente primero"));

    var cliente = getVisitaSelected(state, "cliente");

    let pedido = {
      id: "L" + parseInt(Math.random() * 1000000),
      clienteId: cliente.id,
      tipo: "pedido",
      fuente: "vendedor",
      plazo: cliente.creditoPlazo,
      cedula: cliente.cedula,
      emails: cliente.correoDocumentosElectronicos,
      pedidoLinea: [],
      __clienteId: cliente.name,
      fecha: moment().format("YYYY-MM-DD"),
      transporteId: cliente.transporteId,
      __transporteId: cliente.__transporteId
    };

    dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: { ...state.visitaTablet.pedidosById, [pedido.id]: pedido }
      }
    });
    dispatch(onVisitSetSelected("pedido", pedido.id));
  };
}

export function onUpdatePedido(pedido, pedidoItems) {
  return async function(dispatch, getState) {
    var state = getState();

    if (!pedidoItems) pedidoItems = pedido.pedidoLinea;

    pedido = {
      ...pedido,
      pedidoLinea: pedidoItems,
      ...Finance.ordenTotal(pedidoItems)
    };

    var newById = { ...state.visitaTablet.pedidosById, [pedido.id]: pedido };

    return dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: newById,
        view: state.visitaTablet.view == "PEDIDO" ? "PEDIDO_SAVE" : "HOME",
        selectedPedidoId: state.visitaTablet.view == "PEDIDO" ? state.visitaTablet.selectedPedidoId : null
      }
    });
  };
}

export function onDeletePedido(pedidoId) {
  return async function(dispatch, getState) {
    var newById = { ...getState().visitaTablet.pedidosById };
    delete newById[pedidoId];

    dispatch({
      type: "VISITA_UPDATE",
      payload: {
        pedidosById: newById,
        view: "HOME",
        selectedPedidoId: null,
        selectedClienteId: null,
        selectedGrupoId: null
      }
    });
  };
}

export function onClienteSetSelected(type, id) {
  return function(dispatch, getState) {
    dispatch({
      type: "VISIT_SET_SELECTED",
      payload: {
        type: type,
        id: id
      }
    });

    if (id)
      dispatch(
        loadSmartView({
          item: getState().visitaTablet.clientesById[id],
          type: type,
          id: id
        })
      );

    if (type == "pedido") {
      var pedido = getVisitaById(getState(), "pedidos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "cliente",
          id: pedido.clienteId
        }
      });
      dispatch({
        type: "VISITA_UPDATE",
        payload: { view: "PEDIDO" }
      });
    }

    if (type == "departamento") {
      var departamentos = getVisitaById(getState(), "departamentos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "grupo",
          id: -1
        }
      });
    }
  };
}

export function onVisitSetSelected(type, id) {
  return function(dispatch, getState) {
    dispatch({
      type: "VISIT_SET_SELECTED",
      payload: {
        type: type,
        id: id
      }
    });

    if (type == "pedido") {
      var pedido = getVisitaById(getState(), "pedidos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "cliente",
          id: pedido.clienteId
        }
      });
      dispatch({
        type: "VISITA_UPDATE",
        payload: { view: "PEDIDO" }
      });
    }

    if (type == "departamento") {
      var departamentos = getVisitaById(getState(), "departamentos", id);
      dispatch({
        type: "VISIT_SET_SELECTED",
        payload: {
          type: "grupo",
          id: -1
        }
      });
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "VISITA_UPDATE": {
      Object.keys(action.payload).forEach(key => {
        if (
          [
            "pedidosById",
            "priceListsById",
            "clientesById",
            "productosById",
            "recibosById",
            "departamentosById"
          ].indexOf(key) > -1
        ) {
          window.localStorage.setItem(
            key.replace("ById", ""),
            JSON.stringify(Object.values(action.payload[key] || []))
          );
        }
      });
      return { ...state, ...action.payload };
    }

    case "VISIT_SET_SELECTED": {
      return {
        ...state,
        ["selected" +
        action.payload.type.charAt(0).toUpperCase() +
        action.payload.type.slice(1) +
        "Id"]: action.payload.id
      };
    }

    default: {
      return state;
    }
  }
};

export function getVisitaAll(state, type, clienteId) {
  var typeById = state.visitaTablet[`${type}ById`];
  var array = Object.values(typeById);

  if (array.length > 0 && array[0].hasOwnProperty("orden")) array.sort(sortBy("orden"));
  else if (array.length > 0 && array[0].hasOwnProperty("name")) array.sort(sortBy("name"));
  else if (array.length > 0 && array[0].hasOwnProperty("id")) array.sort(sortBy("id"));
  return array;
}

export function getVisitaById(state, type, id) {
  var typeById = state.visitaTablet[`${type}ById`];
  return typeById[id];
}

export function getVisitaSelected(state, type = "") {
  var selectedId = state.visitaTablet[`selected${type.charAt(0).toUpperCase()}${type.slice(1)}Id`];

  if (!selectedId) return null;
  return state.visitaTablet[`${type}sById`][selectedId];
}

export function getVisitaByIdFn(state) {
  return (type, id) => {
    return state.visitaTablet[`${type}sById`][id];
  };
}

export function getCategoriasByDepartamentoFn(store) {
  return departamentoName => {
    var categorias = store.visitaTablet.categoriasByDepartamento[departamentoName] || [];
    var array = categorias.map(categoria => {
      categoria.grupos = store.visitaTablet.gruposByCategoria[categoria.id];
      if (categoria.grupos && categoria.grupos.length > 0) categoria.grupos.sort(sortBy("name"));
      return categoria;
    });
    if (array.length > 0) array.sort(sortBy("orden"));
    return array;
  };
}

function sortBy(field) {
  return (a, b) => {
    if (field == "orden") {
      if (!a.orden || a.orden == 0) a.orden = 100000;
      if (!b.orden || b.orden == 0) b.orden = 100000;
    }

    if (a[field] > b[field]) return 1;
    if (b[field] > a[field]) return -1;
    else return 0;
  };
}
