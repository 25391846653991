import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import Tooltip from "chartist-plugin-tooltips";
import "chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css";

import Chartist from "chartist";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { emailsSubscriptionChart, simpleBarChart } from "./variables.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import "chartist/dist/chartist.css";
class CardChart extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card style={{ marginTop: 50 }} chart>
        <CardHeader color="info">
          <ChartistGraph
            className="ct-chart-white-colors"
            data={this.props.data}
            type="Bar"
            options={{
              ...simpleBarChart.options,
              plugins: [Chartist.plugins.tooltip()]
            }}
            responsiveOptions={simpleBarChart.responsiveOptions}
            listener={simpleBarChart.animation}
          />
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>{this.props.title}</h4>
          <p className={classes.cardCategory}>{this.props.description}</p>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(chartsStyle)(CardChart);

/*

 </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={simpleBarChart.data}
                type="Bar"
                options={simpleBarChart.options}
                responsiveOptions={simpleBarChart.responsiveOptions}
                listener={simpleBarChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Simple Bar Chart</h4>
              <p className={classes.cardCategory}>Bar Chart</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    */
