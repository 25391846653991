import React, { Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { simpleApi } from "redux/reducers/api";

import CircularProgress from "@material-ui/core/CircularProgress";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Grid from "@material-ui/core/Grid";

import { Typography, Box } from "@material-ui/core";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Notas from "components/Nota";
import Contactos from "components/Contacto";
import SideBar from "./SideBar";
import Metric from "components/Graphs/Metric";
import Bar from "components/Graphs/Bar";
import Pie from "components/Graphs/Pie";
import { Link } from "@material-ui/core";
import { showFastCreate } from "redux/reducers/ui/fastCreate";
import RelatedList from "components/RelatedList";
import Task from "components/Task";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      flexGrow: 1,
      display: "flex",

      overflow: "hidden",
      width: "100%",
      minWidth: 320
    },

    gridCard: {
      marginBottom: 20,
      marginTop: 10
    },
    subheader: {
      width: "100%"
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },

    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff"
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0
    },

    noCardHeaderRoot: {
      paddingLeft: 0,
      paddingBottom: 0,
      marginBottom: 0
    },
    cardContent: {},
    link: {
      cursor: "pointer",
      color: theme.palette.primary.dark,
      textTransform: "capitalize"
    },
    fieldWrapper: {
      borderBottom: "4px dotted rgba(240,240,250,0.6)",
      marginBottom: 15,
      paddingBottom: 15,

      marginRight: 15,
      minWidth: 100
    },
    table: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 300,
      overflow: "scroll",
      marginBottom: 20
    },
    title: {
      marginBottom: 20,
      color: "rgba(120,140,140,1)"
    },
    relatedListWrapper: {
      padding: 10,
      minHeight: 300,
      maxHeight: 500,
      overflow: "scroll",
      border: "1px solid #ddd"
    },
    metricsWrapper: {
      marginBottom: 30
    }
  };
};

class PureForm extends React.Component {
  constructor(props) {
    super(props);
  }

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(name, value, extra);
  };

  renderInfo(normalFields, fullFields) {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.root}>
          {normalFields.map(field => {
            var column = this.props.metadata.properties[field];
            return this.renderInput(column, field, 3);
          })}
        </Grid>

        <Grid container className={classes.root}>
          {fullFields.map(field => {
            var column = this.props.metadata.properties[field];

            return this.renderInput(column, field, 12);
          })}
        </Grid>
      </Fragment>
    );
  }

  renderTasks() {
    return null;
    return (
      <Task
        user={this.props.user}
        onProgress={this.props.onProgressTask}
        dispatch={this.props.dispatch}
        onCreate={this.props.onCreateTask}
        items={this.props.tasks}
      />
    );
  }

  renderLists(relatedFields) {
    const { classes } = this.props;

    return (relatedFields || []).map(relatedField => {
      var props = this.props;

      var listValues = {};
      if (this.props.lists) listValues = this.props.lists[relatedField.key] || {};

      return (
        <div className={classes.relatedListWrapper}>
          <RelatedList
            dispatch={this.props.dispatch}
            loadList={this.props.loadList}
            listValues={listValues}
            relatedField={relatedField}
            item={this.props.item}
            showSubView={this.props.showSubView}
            metadata={this.props.metadata}
          />
        </div>
      );
    });
  }

  renderMetrics() {
    const { classes } = this.props;

    if (!this.props.metadata.view || !this.props.metadata.view.metrics) return null;
    var metrics = this.props.metadata.view.metrics;

    return (
      <Grid className={classes.metricsWrapper} container spacing={3}>
        {metrics.map(metric => {
          if (metric.type == "METRIC")
            return (
              <Grid item xs={4}>
                <Metric metric={metric} value={this.props.metrics[metric.key]} />
              </Grid>
            );
          else if (metric.type == "PIE")
            return (
              <Grid item xs={4}>
                <Pie metric={metric} value={this.props.metrics[metric.key]} />
              </Grid>
            );
          else if (metric.type == "BAR")
            return (
              <Grid item xs={4}>
                <Bar metric={metric} value={this.props.metrics[metric.key]} />
              </Grid>
            );
          else return null;
        })}
      </Grid>
    );
  }

  renderContactos() {
    if (this.props.metadata.key != "cliente") return;
    return (
      <Contactos
        dispatch={this.props.dispatch}
        renderer={item => {
          return item.message;
        }}
        onEdit={this.props.onEditContacto}
        onCreate={this.props.onCreateContacto}
        items={this.props.contactos || []}
      />
    );
  }

  renderInnerField(field, innerValue, xs = 4) {
    const { classes } = this.props;

    if (innerValue == null) return null;
    return (
      <Grid item xs={xs}>
        {field}: {JSON.stringify(innerValue)}
      </Grid>
    );
  }

  onCreateFromRelatedList = relatedList => {
    this.props.dispatch(
      showFastCreate(
        {
          metadataType: relatedList.table,
          operation: "create",

          onCreateComplete: createdObject => {}
        },
        { name: "", clienteId: this.props.item.id }
      )
    );
  };

  renderJsonTable(column, value) {
    const { classes } = this.props;

    var fields = column.fields || Object.keys(value);
    if (Array.isArray(value)) {
      fields = column.fields || Object.keys(value[0]);
      var innerValues = [];
      value = (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map(field => {
                return <TableCell key={field}>{field}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(item => {
              return (
                <TableRow key={item.id}>
                  {fields.map(field => {
                    var value = item[field];
                    if (field.indexOf("__") > -1 && field.indexOf("Id") > -1) {
                      value = (
                        <a
                          className={classes.link}
                          onClick={() => {
                            this.props.showSubView(
                              field.replace("__", "").replace("Id", ""),
                              item[field.replace("__", "")]
                            );
                          }}>
                          {item[field]}
                        </a>
                      );
                    }

                    return (
                      <TableCell component="th" scope="row">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      value = (
        <Grid container>
          {fields.map(field => {
            return this.renderInnerField(field, value[field]);
          })}
        </Grid>
      );
    }
    return value;
  }

  renderInput = (column, key, size) => {
    const { classes } = this.props;
    var key = column.key;

    var value = this.props.item[key];
    if (column.element == "autocomplete")
      value = (
        <a
          className={classes.link}
          onClick={() => {
            this.props.showSubView(column.metadataType, this.props.item[key]);
          }}>
          {this.props.item[`__${key}`]}
        </a>
      );
    if (column.type == "boolean") value = value == 1 ? "si" : "no";

    if (column.isJSON && value != null) value = this.renderJsonTable(column, value);

    if (value == null) return;
    return (
      <Grid className={classes.fieldWrapper} item>
        <Typography variant="caption">{column.title}</Typography>
        <div>{value}</div>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    var normalFields = this.props.metadata.table.fields;
    var fullFields = [];
    var relatedFields = [];
    if (this.props.metadata.view) {
      normalFields = this.props.metadata.view.fields || this.props.metadata.table.fields;
      relatedFields = this.props.metadata.view.relatedFields || [];
      fullFields = this.props.metadata.view.fullFields || [];
    }

    return (
      <div>
        {this.props.metadata.layout ? (
          <Grid container style={{ height: "88vh" }}>
            <Grid
              item
              xs={12}
              md={8}
              lg={9}
              style={{
                height: "100%",
                overflowY: "scroll"
              }}>
              <Box p={2}>
                {this.renderInfo(normalFields, fullFields)}
                {this.renderMetrics()}

                <SideBar color="primary" names={relatedFields.map(relatedField => relatedField.title)}>
                  {this.renderLists(relatedFields)}
                </SideBar>
              </Box>
            </Grid>
            <Grid
              md={4}
              lg={3}
              xs={12}
              item
              style={{
                height: "100%",
                overflowY: "scroll",
                borderLeft: "1px solid #ddd",
                backgroundColor: "#fafafa"
              }}>
              <Box p={1}>
                {this.renderContactos()}

                <Notas
                  tablet={this.props.tablet}
                  dispatch={this.props.dispatch}
                  renderer={item => {
                    return item.message;
                  }}
                  onCreate={this.props.onCreateNote}
                  items={this.props.notes}
                  favoriteItem={this.props.favoriteItem}
                />

                {this.renderTasks()}
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {this.props.form && this.props.form.loadStatus == "PENDING" ? (
          <div style={{ margin: 20 }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PureForm);

/*

<ItemList
                dispatch={this.props.dispatch}
                renderer={item => {
                  var delta = JSON.parse(item.delta);
                  var changes = Object.keys(delta)
                    .filter(key => {
                      return (
                        [
                          "fechaIngreso",
                          "createdBy",
                          "updatedBy",
                          "createdAt",
                          "updatedAt",
                          "lineaPagoDocumento",
                          "movimientoInventario",
                          "ordenLinea",
                          "lineaPagoCxp"
                        ].indexOf(key) == -1
                      );
                    })
                    .map(key => {
                      return (
                        <div>
                          {key}: {delta[key]}
                        </div>
                      );
                    });
                  return changes;
                }}
                items={this.props.audits}
                onMount={() => {
                  return this.props.loadAudits();
                }}
              />
*/
