import Ajax from "../helpers/ajax";

import { createSelector } from "reselect";

const init = { byType: {}, lastQueryByType: {}, orderByType: {} };

export function onClearData(metadataType) {
  return {
    type: "DATA_CLEAR",
    payload: {
      metadataType: metadataType
    }
  };
}

export function onReloadData(metaDataType) {
  return function(dispatch, getState) {
    var state = getState();
    var lastQuery = state.data.lastQueryByType[metaDataType];
    return dispatch(onLoadData.apply(null, lastQuery));
  };
}

export function onLoadData(metaDataType, filters, queryParams) {
  if ((!filters || filters.length == 0) && !queryParams) queryParams = { inProgress: true };

  var lastQuery = [metaDataType, filters, queryParams];
  if (queryParams && queryParams.keepLastQuery) lastQuery = null;

  return function(dispatch, getState) {
    var state = getState();

    dispatch(onLoadDataLoading(metaDataType, [], lastQuery));

    return Ajax.post(dispatch, `${metaDataType}/query`, { filters: filters, ...queryParams })
      .then(function(results) {
        return dispatch(onLoadDataSuccess(metaDataType, results, lastQuery));
      })
      .catch(function(apiError) {
        if (apiError.status == 404) alert("Error de integracion, el objeto no existe");
        console.log(apiError);
      });
  };
}

export function onLoadDataById(metaDataType, id) {
  return onLoadData(metaDataType, [[`${metaDataType}.id`, "=", id]]);
}

export function registerLastQuery(metaDataType, filters, queryParams) {
  return {
    type: "DATA_REGISTER_LAST_QUERY",
    payload: [metaDataType, filters, queryParams]
  };
}

export function onLoadDataSuccess(metadataType, results, lastQuery, append) {
  var transformToKeys = {};
  //if(append) transformToKeys=
  var order = [];
  results.forEach(function(result) {
    transformToKeys[result.id] = result;
    order.push(result.id);
  });

  return {
    type: "DATA_LOAD_SUCCESS",
    payload: {
      resultById: transformToKeys,
      order: order,
      metadataType: metadataType,
      lastQuery: lastQuery
    }
  };
}

export function onLoadDataLoading(metadataType, results, lastQuery) {
  var transformToKeys = {};

  var order = [];
  results.forEach(function(result) {
    transformToKeys[result.id] = result;
    order.push(result.id);
  });

  return {
    type: "DATA_LOAD_LOADING",
    payload: {
      resultById: transformToKeys,
      order: order,
      metadataType: metadataType,
      lastQuery: lastQuery
    }
  };
}

export function onSave(type, item) {
  return function(dispatch, getState) {
    var state = getState();
    var route = "create";
    var oldState;

    if (parseInt(item.id) >= 1) {
      route = "update";
      oldState = state.data.byType[type][item.id];
      item.updatedAt = oldState.updatedAt;
    }

    return Ajax.post(dispatch, `${type}/${route}`, item).then(function(result) {
      dispatch(onSaveSuccess(type, result));
      return result;
    });
  };
}

export function onSaveSuccess(type, result) {
  return {
    type: "DATA_SAVE_SUCCESS",
    payload: {
      item: result,
      type: type
    }
  };
}

export function onUpdateSuccess(type, result) {
  return {
    type: "DATA_UPDATE_SUCCESS",
    payload: {
      item: result,
      type: type
    }
  };
}

export function onAction(type, action, ids) {
  return function(dispatch, getState) {
    var state = getState();

    return Ajax.post(dispatch, `${type}/${action}`, { ids: ids }).then(results => {
      dispatch(onLoadData.apply(null, state.data.lastQueryByType[type]));
      return results;
    });
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "DATA_LOAD_SUCCESS": {
      var payload = action.payload;
      var result;
      var lastQueryByType = state.lastQueryByType;
      if (payload.lastQuery) {
        lastQueryByType = {
          ...state.lastQueryByType,
          [payload.metadataType]: payload.lastQuery
        };
      }

      result = {
        ...state,
        byType: {
          ...state.byType,
          [payload.metadataType]: payload.resultById
        },
        lastQueryByType: lastQueryByType,
        orderByType: {
          ...state.orderByType,
          [payload.metadataType]: payload.order
        }
      };
      return result;
    }

    case "DATA_LOAD_LOADING": {
      var payload = action.payload;
      var result;
      var lastQueryByType = state.lastQueryByType;

      result = {
        ...state,
        byType: {
          ...state.byType,
          [payload.metadataType]: {}
        }
      };
      return result;
    }

    case "DATA_SAVE_SUCCESS": {
      var payload = action.payload;
      var result;
      result = {
        ...state,
        byType: {
          ...state.byType,
          [action.payload.type]: {
            ...state.byType[action.payload.type],
            [action.payload.item.id]: action.payload.item
          }
        }
      };
      return result;
    }

    case "DATA_UPDATE_SUCCESS": {
      var payload = action.payload;
      var originalTypeArray = state.byType[action.payload.type] || {};
      var originalItem = originalTypeArray[action.payload.item.id];
      if (!originalItem) return state;

      var result;
      result = {
        ...state,
        byType: {
          ...state.byType,
          [action.payload.type]: {
            ...originalTypeArray,
            [action.payload.item.id]: { ...originalItem, ...action.payload.item }
          }
        }
      };
      return result;
    }

    case "DATA_ACTION_SUCCESS": {
      var payload = action.payload;
      var result;
    }

    case "DATA_REGISTER_LAST_QUERY": {
      state = {
        ...state,
        lastQueryByType: {
          ...state.lastQueryByType,
          [action.payload[0]]: action.payload
        }
      };
      return state;
    }

    case "DATA_CLEAR": {
      return {
        ...state,
        byType: {
          ...state.byType,
          [action.payload.metadataType]: null
        }
      };
    }
  }
  return state;
};

export function selectDataByTypeAndId(state, type, id) {
  if (!state.data.byType[type]) return null;
  return state.data.byType[type][id];
}

export function selectDataByTypeAndIdFn(state) {
  return (type, id) => {
    return selectDataByTypeAndId(state, type, id);
  };
}

export function selectDataByType(state, type) {
  if (!state.data.byType[type]) return null;
  var values = Object.values(state.data.byType[type]);
  var order = state.data.orderByType[type];

  return mapOrder(values, order, "id");
}

const getItemsByType = (state, props) => {
  if (!state.data.byType[props.metadataType]) return state.data.byType[props.metadataType] || {};
  var values = Object.values(state.data.byType[props.metadataType]);
  var order = state.data.orderByType[props.metadataType];
  return mapOrder(values, order, "id");
};

export const reSelectDataByType = createSelector(getItemsByType, items => Object.values(items));

function mapOrder(array, order, key) {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
