import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";



import { connect } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleTable from "components/Table/Table.jsx";
import Table from "views/base/table";


import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit } from "redux/reducers/table";


const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  bold: {
    fontWeight: 800
  },
})

class boletasFamiliaSelect extends React.Component {

  state = {
    familia: "",
  }


  onPrintClick = (e) => {
    this.props.dispatch(onAction("producto", "boletas", {
      familia: this.state.familia
    }))
      .then((res) => {
        window.open(res.url,"_blank");
        this.props.onCloseModal();
      })
      .catch((err) => {
        return this.props.dispatch(alert("Error al generar boletas de inventario." + err, "error", true))
      })

  }

  handleFamiliaChange = (e) => {
    this.setState({ familia: e.currentTarget.value })
  }

  render() {
    const { classes } = this.props;

    return <div><DialogContentText>
      Digitacio de familia a imprimir.
    </DialogContentText>

      <TextField
        className={classes.textField}
        autoFocus
        value={this.state.familia}
        onChange={this.handleFamiliaChange}

        id="name"
        label="Familia"
        type="text"
      />
      <DialogActions>
        <Button onClick={this.props.onCloseModal.bind(this)} color="primary">
          Cancelar
          </Button>
        <Button onClick={this.onPrintClick.bind(this)} color="primary">
          Imprimir
      </Button>
      </DialogActions>
    </div >

  }
}

export default withStyles(allStyles, { withTheme: true })(boletasFamiliaSelect);


