import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";

const styles = {
  root: {
    flexGrow: 1
  }
};

class IconTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.onChange(event.currentTarget.dataset.key, event.currentTarget.dataset.type);
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper square className={classes.root}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary">
          {(this.props.attachments || []).map(file => {
            return <Tab data-key={file.key} key={file.fileName} label={file.type} data-type={file.type} />;
          })}
        </Tabs>
      </Paper>
    );
  }
}

IconTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IconTabs);
