import Ajv from "ajv";
import { alert } from "./header";

const initState = {
  byId: {}
};

export function onUpdateItem(formId, item, metadata) {
  return {
    type: "UPDATE_ITEM",
    payload: {
      formId: formId,
      item: { ...item },
      metadata: metadata
    }
  };
}

export function onCreateForm(formDetails, item = {}) {
  if (!formDetails.pageTitle) {
    formDetails.pageTitle = formDetails.type;
  }

  return {
    type: "CREATE_FORM",
    payload: { ...formDetails, item: item, fieldStatus: {}, updatedKeys: [] }
  };
}

export function onFieldChange(formId, key, value, extra) {
  return function(dispatch, getState) {
    var state = getState();
    var form = state.form.byId[formId];
    var newItem = form.item;
    if (form.transformOnChange) newItem = form.transformOnChange(form, key, value, extra);
    else newItem = { ...form.item, [key]: value };

    if (newItem._errorInTransform) return dispatch(alert(newItem.message));
    var status = true;

    if (value == null) delete newItem[key];
    else status = validate(state.metadata.byType[form.type], newItem, form.type).fields;

    dispatch({
      type: "FORM_FIELD_CHANGE",
      payload: { formId: formId, item: newItem, fieldStatus: status, key: key }
    });

    return Promise.resolve(newItem);
  };
}

export function onFieldsChange(formId, keyPair) {
  return function(dispatch, getState) {
    var state = getState();
    var form = state.form.byId[formId];
    var newItem = { ...form.item, ...keyPair };

    var status = validate(state.metadata.byType[form.type], newItem, form.type).fields;

    dispatch({
      type: "FORM_FIELDS_CHANGE",
      payload: { formId: formId, item: newItem, fieldStatus: status, keys: Object.keys(keyPair) }
    });

    return Promise.resolve(newItem);
  };
}

export function onArrayFieldChange(formId, key, arrayValue) {
  return function(dispatch, getState) {
    var state = getState();
    var form = state.form.byId[formId];
    var newItem = { ...form.item, [key]: arrayValue };
    var primaryMetadata = state.metadata.byType[form.type];

    var primaryColumn = primaryMetadata.propertiesByKey[key];
    var secondaryMetadata = state.metadata.byType[key];

    var status = newItem[key].map(itemInArray => {
      return validate(secondaryMetadata, itemInArray, key).fields;
    });

    var status = { ...form.fieldStatus, [key]: status };

    dispatch({
      type: "FORM_FIELD_CHANGE",
      payload: { formId: formId, item: newItem, fieldStatus: status }
    });

    return Promise.resolve(newItem);
  };
}

export default (state = initState, action) => {
  switch (action.type) {
    case "CREATE_FORM": {
      return { ...state, byId: { ...state.byId, [action.payload.id]: action.payload } };
    }

    case "UPDATE_ITEM": {
      var r = {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.formId]: {
            ...state.byId[action.payload.formId],
            item: action.payload.item,
            metadata: action.payload.metadata
          }
        }
      };
      return r;
    }

    case "FORM_FIELD_CHANGE": {
      var form = state.byId[action.payload.formId];

      var r = {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.formId]: {
            ...state.byId[action.payload.formId],
            item: action.payload.item,
            fieldStatus: action.payload.fieldStatus,
            updatedKeys: [...state.byId[action.payload.formId].updatedKeys, action.payload.key]
          }
        }
      };

      return r;
    }

    case "FORM_FIELDS_CHANGE": {
      var form = state.byId[action.payload.formId];

      var r = {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.formId]: {
            ...state.byId[action.payload.formId],
            item: action.payload.item,
            fieldStatus: action.payload.fieldStatus,
            updatedKeys: [...state.byId[action.payload.formId].updatedKeys].concat(action.payload.keys)
          }
        }
      };

      return r;
    }
  }
  return state;
};

export function selectFormById(state, id) {
  return state.form.byId[id];
}

var compiledMetadata = {};

function validate(metadata, item, type) {
  //return {};

  var ajv = new Ajv({ allErrors: true });

  var validateFn = compiledMetadata[type] || ajv.compile(metadata);
  compiledMetadata[type] = validateFn;

  var normalisedErrors = { fields: {}, errors: [] };
  var valid = validateFn(item);
  if (!valid) normalisedErrors = normaliseErrorMessages(validateFn.errors);
  normalisedErrors.errors = validateFn.errors;
  return normalisedErrors;
}

function normaliseErrorMessages(errors) {
  var fields = errors.reduce(function(acc, e) {
    if (e.keyword == "required") {
      acc[e.params.missingProperty] = [e.message.toUpperCase()[0] + e.message.slice(1)];
    } else acc[e.dataPath.slice(1)] = [e.message.toUpperCase()[0] + e.message.slice(1)];
    return acc;
  }, {});
  return { fields };
}
