import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FastCreate from "components/FastCreate/FastCreate";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByTypeAndId } from "redux/reducers/data";
import { hideFastCreate, showFastCreate } from "redux/reducers/ui/fastCreate";
import { onLoadMetaData } from "../../redux/reducers/metadata";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { AppBar } from "@material-ui/core";
import { onFieldsChange, onFieldChange } from "redux/reducers/form";
const styles = theme => ({
  titleRoot: {
    margin: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginLeft: 8
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing,
    top: 8,
    color: "#fff"
  }
});

const mapStateToProps = (store, props) => {
  return {
    dialogs: Object.values(store.fastCreate.dialogsByTypeAndId)
  };
};

class FastCreateContainer extends React.Component {
  state = {
    value: 0
  };

  componentsByTypeId = {};

  componentWillReceiveProps(nextProps) {
    Object.values(nextProps.dialogs || {}).forEach(dialog => {
      if (dialog == null) return;
      var dialogKey = this.getKey(dialog);
      if (!this.componentsByTypeId[dialogKey])
        this.componentsByTypeId[dialogKey] = this.props.getComponentFromPath(
          dialog.metadataType,
          dialog.operation
        );
    });
  }

  getKey(dialog) {
    return `${dialog.metadataType}-${dialog.formId}`;
  }

  showSubView = (type, id) => {
    this.props.dispatch(showFastCreate({ type: type, id: id }));
  };

  onCreateComplete = (dialog, createdResult) => {
    if (createdResult) {
      if (dialog.onCreateComplete) dialog.onCreateComplete(createdResult);

      if (dialog.parentFormId)
        this.props.dispatch(
          onFieldChange(dialog.parentFormId, dialog.assignToType, createdResult.id, createdResult)
        );
    }

    delete this.componentsByTypeId[this.getKey(dialog)];
    this.props.dispatch(hideFastCreate(dialog));
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    if (this.props.dialogs.length == 0) return null;
    return this.props.dialogs.map((dialog, index) => {
      if (!dialog) return null;
      var componentsByTypeId = this.componentsByTypeId;
      var key = this.getKey(dialog);
      var component = componentsByTypeId[key];

      return (
        <Dialog
          scroll="body"
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth={"lg"}
          fullWidth={true}
          open={true}
          onClose={() => {
            this.onCreateComplete(dialog);
          }}>
          <AppBar style={{ backgroundColor: "#544ecc" }} position="static" color="primary">
            <MuiDialogTitle disableTypography className={classes.titleRoot}>
              <Typography style={{ color: "#fff", marginTop: 15 }} variant="subtitle1">
                {dialog.operation.replace("create", "crear").replace("update", "editar")}{" "}
                {dialog.metadataType}
              </Typography>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={() => {
                  this.onCreateComplete(dialog);
                }}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
          </AppBar>
          <DialogContent style={{ marginTop: 30 }}>
            <div style={{ position: "relative" }}>
              <FastCreate dialog={dialog} onCreateComplete={this.onCreateComplete} component={component} />
            </div>
          </DialogContent>
        </Dialog>
      );
    });
  }
}

FastCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FastCreateContainer));
