import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { FormControl, Select, MenuItem, InputLabel, InputBase } from "@material-ui/core";

class NumberFormatter extends React.Component {
  handleChange = e => {
    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: e.target.value },
      fromRowId: this.props.row.id
    });
  };

  render() {
    if (this.props.column.editable) return this.renderEditor();
    var date = moment(this.props.value);
    if (!date.isValid()) date = "";
    else
      date = (
        <div>
          {date.format("MMM DD")}{" "}
          <span style={{ fontSize: 11 }}>'{date.format("YY H:mm").replace("0:00", "")}</span>
        </div>
      );

    return date;
  }

  renderEditor() {
    return (
      <InputBase
        style={{ fontSize: 9, width: "90%" }}
        type="date"
        value={this.props.value}
        onChange={this.handleChange}
        shrink={true}
      />
    );
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <NumberFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
