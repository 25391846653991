import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.jsx";

import { dismissAlert } from "redux/reducers/header";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};


const locationMap = {
  success: { vertical: "bottom", horizontal: "left" },
  warning: { vertical: "bottom", horizontal: "center" },
  error: { vertical: "top", horizontal: "center" },
  info: { vertical: "top", horizontal: "right" }
}

const styles1 = theme => ({
  root: {
    position: "fixed",
    zIndex: 1000000,
    top: 50,
    left: "30%"
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const mapStateToProps = (store, props) => {
  return {
    alert: store.header.alert
  }
}


class SnackBar extends React.Component {

  onClose = () => {
    this.props.dispatch(dismissAlert());
  }

  render() {
    const { classes } = this.props;
    if (!this.props.alert) return null;
    return <div className={classes.root}>
      <Snackbars
        place="tc"
        color={this.props.alert.type || "danger"}
        icon={AddAlert}
        message={this.props.alert.message}
        open={true}
        closeNotification={this.onClose}
        close
      />

    </div>

  }
}

export default withStyles(styles1, { withTheme: true })(connect(mapStateToProps)(SnackBar));