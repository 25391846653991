import { showFastCreate } from "./fastCreate";

const defaultState = {
  contactosByTypeAndId: {}
};

export function loadContactos(payload) {
  return {
    type: "LOAD_CONTACTOS",
    payload: payload
  };
}

export function editContacto(cliente, contactoId) {
  return function(dispatch, getState) {
    return dispatch(
      showFastCreate(
        {
          metadataType: "contacto",
          operation: "edit",
          itemId: contactoId,
          closeOnSuccess: true,
          onCreateComplete: createdObject => {
            dispatch({
              type: "UPDATE_CONTACTO",
              payload: { contacto: createdObject, type: "cliente", id: cliente.id }
            });
          }
        },
        { id: cliente.id }
      )
    );
  };
}

export function createContacto(cliente, contacto, callback) {
  return function(dispatch, getState) {
    return dispatch(
      showFastCreate(
        {
          metadataType: "contacto",
          operation: "create",
          closeOnSuccess: true,
          onCreateComplete: createdObject => {
            if (callback) callback(createdObject);
            dispatch({
              type: "ADD_CONTACTO",
              payload: { contacto: createdObject, type: "cliente", id: cliente.id }
            });
          }
        },
        { ...contacto, clienteId: cliente.id }
      )
    );
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_CONTACTOS":
      return {
        ...state,
        contactosByTypeAndId: {
          ...state.contactosByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.contactos
        }
      };

    case "ADD_CONTACTO":
      return {
        ...state,
        contactosByTypeAndId: {
          ...state.contactosByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: [
            ...(state.contactosByTypeAndId[`${action.payload.type}-${action.payload.id}`] || []),
            action.payload.contacto
          ]
        }
      };

    case "UPDATE_CONTACTO":
      var newArray = [...state.contactosByTypeAndId[`${action.payload.type}-${action.payload.id}`]].filter(
        item => item.id != action.payload.contacto.id
      );
      newArray.push(action.payload.contacto);
      return {
        ...state,
        contactosByTypeAndId: {
          ...state.contactosByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: newArray
        }
      };

    default:
      return state;
  }
};
