const defaultState = {
  auditsByTypeAndId: {}
};

export function loadAudits(payload) {
  return {
    type: "LOAD_AUDITS",
    payload: payload
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_AUDITS":
      return {
        ...state,
        auditsByTypeAndId: {
          ...state.auditsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.audits
        }
      };

    default:
      return state;
  }
};
