import { onLoadDataSuccess, onLoadData, registerLastQuery } from "./data";
import { throttle, debounce } from "throttle-debounce";

var initState = {
  filtersByType: {},
  groupsByType: {},
  currentViewId: null
};

function load(metadataType, filters, queryParams, dispatch) {
  dispatch(onLoadData(metadataType, filters, queryParams));
}

const dbLoad = debounce(1000, load);
//this.dispatchFilterDebounced =dbLoad;

export function onFilter(metadataType, filter) {
  return function(dispatch, getState) {
    const key = Object.keys(filter)[0];
    metadataType = filter[key].metadataType;

    var currentFiltersByType = getState().table.filtersByType[metadataType];
    var oldFilters = currentFiltersByType ? currentFiltersByType.filters : {};
    var limit = (currentFiltersByType || {}).limit || false;

    const newFilters = {
      ...oldFilters,
      [key]: filter[key]
    };

    var filters = [];
    var metadataType = "";
    Object.keys(newFilters).forEach(key => {
      if (!newFilters[key]) return;
      if (newFilters[key].metadataType) metadataType = newFilters[key].metadataType;

      if (newFilters[key].filterValue) filters.push(newFilters[key].filterValue);
    });

    var queryParams = {};
    //if (limit) queryParams.limitless = true;

    dispatch({
      type: "TABLE_FILTER",
      payload: { filters: newFilters, type: metadataType }
    });

    dbLoad(metadataType, filters, queryParams, dispatch);
  };
}

export function onResetFilters(metadataType) {
  return function(dispatch, getState) {
    var queryParams = {};

    dispatch({
      type: "TABLE_FILTER",
      payload: { filters: [], type: metadataType }
    });

    dbLoad(metadataType, [], queryParams, dispatch);
  };
}

export function changeLimit(metadataType, checked) {
  return function(dispatch, getState) {
    var currentFiltersByType = getState().table.filtersByType[metadataType];
    var newFilters = currentFiltersByType ? currentFiltersByType.filters : {};
    var filters = [];
    Object.keys(newFilters).forEach(key => {
      if (!newFilters[key]) return;

      if (newFilters[key].filterValue) filters.push(newFilters[key].filterValue);
    });

    dbLoad(metadataType, filters, { limitless: true }, dispatch);

    dispatch({
      type: "TABLE_FILTER_LIMIT",
      payload: {
        type: metadataType,
        checked: false
      }
    });
  };
}

export function changeGroup(metadataType, key) {
  return function(dispatch, getState) {
    var groups = [key];
    var filters = getState().table.filtersByType[metadataType];
    if (filters && filters.groups && filters.groups.indexOf(key) > -1) groups = [];

    dispatch({
      type: "TABLE_FILTER_GROUP",
      payload: {
        type: metadataType,
        groups: groups
      }
    });
  };
}

export function onViewWithId(id) {
  return {
    type: "VIEW_ITEM",
    payload: { id: id }
  };
}

export function onResetViewItem() {
  return {
    type: "VIEW_ITEM",
    payload: { id: null }
  };
}

export default (state = initState, action) => {
  switch (action.type) {
    case "VIEW_ITEM": {
      return {
        ...state,
        currentViewId: action.payload.id
      };
    }
    case "TABLE_FILTER_GROUP": {
      state = {
        ...state,
        filtersByType: {
          ...action.payload.filtersByType,
          [action.payload.type]: {
            filters: state.filtersByType[action.payload.type]
              ? state.filtersByType[action.payload.type].filters || {}
              : {},
            groups: action.payload.groups,
            limit: state.filtersByType[action.payload.type]
              ? state.filtersByType[action.payload.type].limit || true
              : true
          }
        }
      };
      return state;
    }

    case "TABLE_FILTER_LIMIT": {
      state = {
        ...state,
        filtersByType: {
          ...action.payload.filtersByType,
          [action.payload.type]: {
            filters: state.filtersByType[action.payload.type]
              ? state.filtersByType[action.payload.type].filters || {}
              : {},
            groups: state.filtersByType[action.payload.type]
              ? state.filtersByType[action.payload.type].groups || []
              : [],
            limit: action.payload.checked
          }
        }
      };
      return state;
    }

    case "TABLE_FILTER": {
      state = {
        ...state,
        filtersByType: {
          ...action.payload.filtersByType,
          [action.payload.type]: {
            filters: action.payload.filters,
            groups: state.filtersByType[action.payload.type]
              ? state.filtersByType[action.payload.type].groups || []
              : [],
            limit: true
          }
        }
      };
      return state;
    }
  }
  return state;
};

export function selectFilters(state, type) {
  if (!state.table.filtersByType[type]) return null;
  var filterObject = state.table.filtersByType[type].filters;

  var filters = [];
  Object.keys(filterObject).forEach(key => {
    if (!filterObject[key]) return;
    if (filterObject[key].filterValue) filters.push(filterObject[key].filterValue);
  });
  return filters;
}

export function selectFilterByKey(state, type, key) {
  if (!state.table.filtersByType[type]) return null;
  return state.table.filtersByType[type].filters[key];
}

export function selectGroupByKey(state, type, key) {
  if (!state.table.filtersByType[type]) return false;
  return state.table.filtersByType[type].groups.indexOf(key) > -1;
}

export function selectGroups(state, type) {
  if (!state.table.filtersByType[type]) return null;
  return state.table.filtersByType[type].groups;
}

export function selectLimit(state, type) {
  if (!state.table.filtersByType[type]) return false;
  return state.table.filtersByType[type].limit;
}

export function selectSingleGroup(state) {
  var groupField = null;

  var keys = Object.keys(state.table.filtersByType);
  console.log(keys);
  keys.forEach(type => {
    if (state.table.filtersByType[type].groups && state.table.filtersByType[type].groups.length > -1)
      groupField = state.table.filtersByType[type].groups[0];
  });

  return groupField;
}
