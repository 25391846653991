import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleChange(event) {
    props.onChange(props.item, "estado", event.target.value);
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="age-simple">Estado</InputLabel>
      <Select
        value={props.item.estado}
        onChange={handleChange}
        inputProps={{
          name: "age",
          id: "age-simple"
        }}>
        <MenuItem value={"pendiente"}>pendiente</MenuItem>
        <MenuItem value={"por empezar"}>por empezar</MenuItem>
        <MenuItem value={"trabajando"}>trabajando</MenuItem>
        <MenuItem value={"archivado"}>archivado</MenuItem>
      </Select>
    </FormControl>
  );
}
