const defaultState = {
  metricsByTypeAndId: {}
};

export function loadMetrics(payload) {
  return {
    type: "LOAD_METRICS",
    payload: payload
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_METRICS":
      return {
        ...state,
        metricsByTypeAndId: {
          ...state.metricsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.metrics
        }
      };

    default:
      return state;
  }
};
