import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";

import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";
import { alert } from "redux/reducers/header";
import { Divider } from "@material-ui/core";
const style = {};

class FormDialog extends React.Component {
  state = { open: false, text: "" };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChangeText = e => {
    this.setState({ text: e.currentTarget.value });
  };

  handleSoporte = () => {
    this.props
      .dispatch(
        simpleApi("https://devops.efactura.io/api/support/video", {
          path: window.location.href,
          user: this.props.user,
          text: this.state.text,
          link: window.FS.getCurrentSessionURL(true)
        })
      )
      .then(() => {
        return this.props.dispatch(alert("Se envio el video a soporte para su analisis.", "info"));
      })
      .then(() => {
        return this.handleClose();
      })
      .catch(() => {
        return this.props.dispatch(
          alert(
            "No se pudo enviar el momento, favor fijese en el tiempo ( hora minuto y segundo ) y adjuntelo al reporte de error en soporte."
          )
        );
      });
  };

  renderIconBar(positionRight) {
    const { classes } = this.props;
    return (
      <div className={classes.iconBar}>
        <IconButton onClick={this.handleClickOpen} color="inherit">
          <Help />
        </IconButton>
      </div>
    );
  }
  render() {
    const { open } = this.state;

    return (
      <div>
        {this.renderIconBar()}
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Soporte</DialogTitle>
          <DialogContent>
            <DialogContentText>
              El sistema graba "video" en vivo de su pantalla. Hemos guardado el momento en el tiempo,
              cuentenos que desea compartir con nosotros y el video se publicara con su comentario.
            </DialogContentText>
            <TextField
              value={this.state.text}
              onChange={this.onChangeText}
              autoFocus
              margin="dense"
              label="Comentario"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleSoporte} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style, { withTheme: true })(FormDialog);
