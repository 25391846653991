import React, { lazy } from "react";
import { makeTable } from "views/base/table";
import { makeEdit } from "views/base/edit";
import { makeCreate } from "views/base/create";
import onOrdenFieldChange from "custom/onChange/orden";
import onFacturaCompraFieldChange from "custom/onChange/facturaCompra";
import onProveedorFieldChange from "custom/onChange/proveedor";
import onFacturaCxPFieldChange from "custom/onChange/facturaCxP";
import onNotaFinancieraFieldChange from "custom/onChange/notaFinanciera";
import onPagoCxPFieldChange from "custom/onChange/pagoCxP";
import onPagoDocumentoFieldChange from "custom/onChange/pagoDocumento";
import onAccountFieldChangeAccount from "custom/onChange/account";
import onBoletaFieldChange from "custom/onChange/boleta";

import { OnLogin } from "custom/onAction/usuario";

import DemoModal from "custom/modals/demo";
import MovimientoDevolverModal from "custom/modals/movimiento";
import ContactoEmail from "custom/modals/contactoEmail";
import ImportarAsientoModal from "custom/modals/journal";
import FamiliaBoletaModal from "custom/modals/boletasFamiliaSelect";
import AccountImportModal from "custom/modals/account";

import PriceListModal from "custom/modals/priceList";
import RegistroRecibidoIngresarModal from "custom/modals/ingresar";
import SaldoModal from "custom/modals/saldo";

//import ClienteFormatter from "custom/formatters/clienteFormatter";
import PrecioFormatter from "custom/formatters/precioFormatter";

const touchDevice = "ontouchstart" in document.documentElement;

const Cierre = lazy(() => import("custom/apps/cierre"));
const Xero = lazy(() => import("custom/apps/xero"));
const Firma = lazy(() => import("custom/apps/firma"));
const Emails = lazy(() => import("custom/apps/emails"));

//const PagoCxpTable = lazy(() => import('custom/apps/pagoCxP/table'));
//const SaldoTable = lazy(() => import('custom/apps/saldoTable'));
//const VisitaTablet = lazy(() => import("custom/apps/mobile/oldVisitaTablet"));
const PedidoTablet = lazy(() => import("custom/apps/mobile/visitaTablet"));
//const ClientesTablet = lazy(() => import("custom/apps/mobile/clientesTablet"));

//const Orden = lazy(() => import("custom/apps/tablet"));

const Catalogo = lazy(() => import("custom/apps/catalogo"));
const Toma = lazy(() => import("custom/apps/inventarioToma"));

//const AccountTable = lazy(() => import("custom/apps/accountTable"));

//const JournalTable = lazy(() => import("custom/apps/journalTable"));
const BalanceTable = lazy(() => import("custom/apps/balanceTable"));

const ReporteFilter = lazy(() => import("custom/types/reporteFilter"));

const OrdenLinea = lazy(() => import("custom/types/ordenLinea"));
const LineaFacturaCompra = lazy(() => import("custom/types/lineaFacturaCompra"));
const MovimientoInventario = lazy(() => import("custom/types/movimientoInventario"));
const JournalItem = lazy(() => import("custom/types/journalItem"));

//const FacturaCxPTable = lazy(() => import('custom/apps/facturaCxPTable'));
//const GastoTable = lazy(() => import("custom/apps/gastoTable"));
//const PagoCxpCreate = lazy(() => import('custom/apps/pagoCxP/create'));

const ordenTypeMap = {
  ordenLinea: OrdenLinea
};

const facturaCompraTypeMap = {
  lineaFacturaCompra: LineaFacturaCompra
};

const reporteTypeMap = {
  filters: ReporteFilter
};

const boletaTypeMap = {
  movimientoInventario: MovimientoInventario
};

const JournalTypeMap = {
  journalItem: JournalItem
};

export const getMenu = () => {
  return Object.keys(AppMap).map(key => {
    return AppMap[key];
  });
};

export const AppMap = {
  home: {
    id: "home",
    default: "inicio",
    title: "Inicio",
    icon: "LocationCity",
    routes: {}
  },

  facturacion: {
    id: "facturacion",
    default: "orden",
    title: "Ventas",
    icon: "TabletMac",
    routes: {
      pedido: {
        title: "Para Mayoreo",
        operations: { table: PedidoTablet }
      },
      orden: {
        title: "Pedidos",
        operations: {
          create: makeCreate(onOrdenFieldChange, ordenTypeMap),
          edit: makeEdit(onOrdenFieldChange, ordenTypeMap),
          table: makeTable(null, {}) //ClienteFormatter: ClienteFormatter })
        }
      }
    }
  },
  analisis: {
    id: "analisis",
    title: "Archivo",
    icon: "LocationCity",
    routes: {
      cliente: { title: "Clientes" },
      clienteStats: { title: "Mayoreo" },
      documento: {
        title: "Facturas"
      },
      movimientoInventario: {
        title: "Productos en Factura",
        operations: { table: makeTable({ Devolver: MovimientoDevolverModal }) }
      },

      comisionHistorico: {
        title: "Comisiones"
      }
    }
  },

  credito: {
    id: "credito",
    default: "saldo",
    title: "Credito y Cobro",
    icon: "AccountBalance",
    routes: {
      saldo: { title: "Saldos", operations: { table: makeTable({ Pagar: SaldoModal }) } },
      pagoDocumento: {
        title: "Recibos",
        operations: {
          create: makeCreate(onPagoDocumentoFieldChange),
          edit: makeEdit(onPagoDocumentoFieldChange)
        }
      },
      lineaPagoDocumento: { title: "Facturas en Recibos" },
      nota: { 
        title: "Nota Financiera",
        operations: {
          create: makeCreate(onNotaFinancieraFieldChange), 
          edit: makeEdit(onNotaFinancieraFieldChange)
        } 
      },
      cheque: { title: "Cheque" },
      cobroEncargado: { title: "Encargados de Cobro" }
    }
  },
  inventario: {
    id: "inventario",
    default: "boleta",
    title: "Productos",
    icon: "MoveToInbox",
    routes: {
      producto: {
        title: "Mercaderia y Servicios",
        operations: {
          table: makeTable({ Boletas: FamiliaBoletaModal })
        }
      },
      boleta: {
        title: "Movimientos",
        operations: {
          create: makeCreate(onBoletaFieldChange, boletaTypeMap),
          edit: makeEdit(onBoletaFieldChange, boletaTypeMap)
        }
      },
      toma: { title: "Tomar Inventario", operations: { table: Toma } },
      inventarioToma: { title: "Tomas de Inventario" },
      despacho: {
        title: "Despacho"
      },
      grupoProducto: {
        title: "Grupo Producto"
      },

      importacion: { title: "Importacion" }
    }
  },
  mercadeo: {
    id: "mercadeo",
    default: "productoDepartamento",
    title: "Mercadeo",
    icon: "Camera",
    routes: {
      cliente: { title: "Clientes" },
      contacto: {
        title: "Contactos",
        operations: { table: makeTable({ Email: ContactoEmail }) }
      },

      catalogo: { title: "Catalogos", operations: { table: Catalogo } },
      productoDepartamento: { title: "Departamentos" },
      productoCategoria: { title: "Categorias" },
      productoOferta: { title: "Ofertas" },
      productoGrupo: { title: "Grupos" },
      productoGrupoList: { title: "Productos" },
      tag: { title: "Tags" }
    }
  },
  cxp: {
    id: "cxp",
    default: "facturaCxP",
    title: "Tesoreria",
    icon: "Payment",
    routes: {
      bank: { title: "Bancos" },

      registroRecibido: {
        title: "Recibido",
        operations: { table: makeTable({ Ingresar: RegistroRecibidoIngresarModal }) }
      },
      gasto: { title: "Gasto" },

      facturaCxP: {
        title: "Facturas",
        operations: { create: makeCreate(onFacturaCxPFieldChange), edit: makeEdit(onFacturaCxPFieldChange) }
      },
      proveedor: { title: "Proveedor", operations: { create: makeCreate(onProveedorFieldChange) } },
      proveedorContacto: { title: "Contactos" },
      pagoCxP: {
        title: "Pago",
        operations: {
          create: makeCreate(onPagoCxPFieldChange),
          edit: makeEdit(onPagoCxPFieldChange)
        }
      },
      proveedor: { title: "Proveedor", operations: { create: makeCreate(onProveedorFieldChange) } },
      facturaCompra: {
        title: "Factura de Compra",
        operations: {
          create: makeCreate(onFacturaCompraFieldChange, facturaCompraTypeMap),
          edit: makeEdit(onFacturaCompraFieldChange, facturaCompraTypeMap),
          table: makeTable(null, {})
        }
      }
    }
  },
  venta: {
    id: "venta",
    default: "orden",
    title: "Configuarión Ventas",
    icon: "LocationCity",
    routes: {
      priceList: {
        title: "Price List App",
        operations: { table: makeTable({ Cambiar: PriceListModal }, { PrecioFormatter: PrecioFormatter }) }
      },
      grupo: { title: "Price Lists" },
      clienteGrupo: { title: "Clientes en PriceList" },
      descuentoCliente: { title: "Precios por Cliente" },
      clienteGrupoComercial: { title: "Grupo Comercial" },
      productoDescuentoVolumen: { title: "Descuento por Volumen" },
      productoCombo: { title: "Combos" },
      transporte: { title: "Transporte" },
      consecutivo: { title: "Consecutivo" },
      segmento: { title: "Segmento" },
      canton: { title: "Cantón" },
      distrito: { title: "Distrito" }
    }
  },
  /*
  tesoreria: {
    id: "tesoreria",
    default: "proveedor",
    title: "Tesoreria",
    icon: "Payment",
    api: "tesoreria",
    routes: {
      documento: {
        title: "Documento",
        operations: { table: makeTable({ Ingresar: RegistroRecibidoIngresarModal }) }
      },
      proveedor: { title: "Proveedor", operations: { create: makeCreate(onProveedorFieldChange) } },
      gasto: { title: "Gasto" },
      saldo: { title: "Saldo" },
      pago: {
        title: "Pago",
        operations: {
          create: makeCreate(onPagoFieldChange),
          edit: makeEdit(onPagoFieldChange)
        }
      },
      ordenCompra: { title: "Orden Compra" },
      cuentaGasto: { title: "Cuenta Gasto" }
    }
  },
  */
  contabilidad: {
    id: "contabilidad",
    default: "account",
    title: "Contabilidad",
    icon: "AttachMoney",
    routes: {
      account: {
        title: "Cuenta",
        operations: {
          table: makeTable({ Importar: AccountImportModal }),

          create: makeCreate(onAccountFieldChangeAccount),
          edit: makeEdit(onAccountFieldChangeAccount)
        }
      },
      journal: {
        title: "Asientos",
        operations: {
          table: makeTable({ Importar: ImportarAsientoModal }),

          create: makeCreate(null, JournalTypeMap),
          edit: makeEdit(null, JournalTypeMap)
        }
      },
      mayorizacion: { title: "Mayorizacion" },
      registroCuentaContable: { title: "Balance", operations: { table: BalanceTable } },

      activo: {
        title: "Activo"
      }
    }
  },
  auditoria: {
    id: "auditoria",
    default: "registro",
    title: "Auditoria",
    icon: "Visibility",
    routes: {
      xero: { title: "Xero", operations: { table: Xero } },

      cierre: { title: "Cierre", operations: { table: Cierre } },
      reporte: {
        title: "Reporte",
        operations: {
          create: makeCreate(null, reporteTypeMap),
          edit: makeEdit(null, reporteTypeMap)
        }
      },
      registro: { title: "Registro" },
      costoHistoricoValor: { title: "Valor" },
      emailRecord: { title: "Emails" },
      costoHistorico: { title: "Costos" },
      verProducto: { title: "Visualización Productos" },
      inventarioHistorico: { title: "Inventario Historico" },
      saldoHistory: { title: "Saldo Historico" },
      registroContinuo: { title: "Registro Continuo" },
      priceListHistory: { title: "PriceList Historico" }
    }
  },
  organizacion: {
    id: "organizacion",
    default: "usuario",
    title: "Organización",
    icon: "Settings",
    routes: {
      okr: { title: "Objetivos OKR" },
      project: { title: "Proyectos" },
      funcion: { title: "Funciónes" },
      task: { title: "Tareas" }
    }
  },
  administracion: {
    id: "administracion",
    default: "usuario",
    title: "Administración",
    icon: "Settings",
    api: "login",
    port: 4001,
    routes: {
      usuario: { title: "Usuarios", operations: { table: makeTable(null, null, { Login: OnLogin }) } },
      profile: { title: "Perfil" },
      firmaDigital: { title: "Configuración", operations: { table: Firma } }
    }
  }
};
