import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import classnames from "classnames";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/icons/NotificationsNone";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";

import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import NotificationsActive from "@material-ui/icons/NotificationsActive";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Chip from "@material-ui/core/Chip";

import Paper from "@material-ui/core/Paper";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";

import { selectSocial, selectOptions, setOptions } from "redux/reducers/feed";
import { onLoadDocumentos, selectDocuments } from "redux/reducers/electronico";
import { subscribeToStandardChannel } from "redux/reducers/socket";
import { loadAnalysis, selectDataByType } from "redux/reducers/analysis";
import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { debug } from "util";

import LazyChart from "components/LazyChart/index";
import numeral from "numeral";
import CardActionArea from "@material-ui/core/CardActionArea";

import Button from "@material-ui/core/Button";

const styles = {
  ...extendedTablesStyle,

  root: {
    position: "fixed",
    right: 0,
    top: 65,
    bottom: 0,
    overflowY: "scroll",
    backgroundColor: "rgba(240,240,240,1)",
    zIndex: 1000,
    borderLeft: "5px solid #ccc",
    padding: 8
  },
  margin1: {
    margin: 5
  },
  capitalize: {
    textTransform: "capitalize"
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    data: selectDataByType(state, "cliente")
  };
};

class Analisis extends React.Component {
  componentDidMount() {
    if (this.props.ids.length > 0)
      this.props.dispatch(loadAnalysis("cliente", this.props.ids.map(item => item.id)));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ids.length > 0 && this.props.ids !== nextProps.ids)
      this.props.dispatch(loadAnalysis("cliente", nextProps.ids.map(item => item.id)));
  }

  renderTable(cols, arrayValues = []) {
    const { classes } = this.props;

    return (
      <Table
        tableHead={cols}
        tableData={arrayValues.map(item => {
          var values = [];
          var keys = Object.keys(item);
          keys.forEach(key => {
            if (cols.indexOf(key) == -1) return;

            values.push(item[key]);
          });
          return values;
        })}
        customCellClasses={[classes.left, classes.left, classes.right]}
        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
        customClassesForCells={[0, 4, 5]}
        customHeadCellClasses={[classes.left, classes.left, classes.right]}
        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
        customHeadClassesForCells={[0, 4, 5]}
      />
    );
  }

  render() {
    const { classes } = this.props;

    var ventas = { labels: [], series: [[]] };
    this.props.data.ventas.forEach(venta => {
      ventas.labels.push(venta.mes);
      ventas.series[0].push(numeral(venta.total / 1000000).format("0.00"));
    });

    var saldos = { labels: [], series: [[]] };
    this.props.data.saldos.forEach(saldo => {
      saldos.labels.push(saldo.grupo);
      saldos.series[0].push(numeral(saldo.total / 1000000).format("0.00"));
    });

    var pagos = { labels: [], series: [[]] };
    this.props.data.pagos.forEach(pago => {
      pagos.labels.push(pago.mes);

      pagos.series[0].push(numeral(pago.total / 1000000).format("0.00"));
    });

    return (
      <div style={{ width: this.props.width }} className={classes.root}>
        <Typography className={classes.capitalize} variant="h6" gutterBottom>
          {this.props.data.title}
        </Typography>

        <Grid container>
          {(this.props.data.metrics || []).map(metric => {
            return (
              <Grid item xs="6">
                <div className={classes.margin1}>
                  <ImgMediaCard title={metric.label} value={metric.value} />
                </div>
              </Grid>
            );
          })}
        </Grid>

        <LazyChart title="Ventas" description="Por mes, ultimos meses. (MM)" data={ventas} />

        <LazyChart title="Saldos" description="Saldos agrupados por plazo (30,45,60,90+)" data={saldos} />

        <LazyChart title="Pagos Atrasados" description="Pagos a mas de 50 dias. (MM)" data={pagos} />
      </div>
    );
  }
}

const ConnectedAnalysis = connect(mapStateToProps)(Analisis);

export default withStyles(styles, { withTheme: true })(ConnectedAnalysis);

function ImgMediaCard(props) {
  const classes = {};
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h6">{props.value}</Typography>
          <Typography gutterBottom variant="p">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
