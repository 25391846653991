import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { onLoadDataById, selectDataByTypeAndId, onLoadDataSuccess } from "redux/reducers/data";
import { simpleApi } from "../api";
import { loadTasks } from "./task";
import { loadNotes } from "./note";
import { loadAudits } from "./audit";
import { loadContactos } from "./contacto";
import { loadMetrics } from "./metric";

const defaultState = {
  viewsByTypeAndId: {},
  itemsByTypeAndId: {}
};

export function loadSmartView(payload) {
  return function(dispatch, getState) {
    var state = getState();

    if (payload.item)
      dispatch({
        type: "UPDATE_VIEW_ITEM",
        payload: {
          id: payload.id,
          type: payload.type,
          item: payload.item
        }
      });

    dispatch(simpleApi("smartView/query", payload, false)).then(result => {
      dispatch(loadNotes({ ...payload, notes: result.notes }));
      dispatch(loadTasks({ ...payload, tasks: result.tasks }));
      dispatch(loadAudits({ ...payload, audits: result.audits }));
      dispatch(loadMetrics({ ...payload, metrics: result.metrics }));
      if (result.contactos) dispatch(loadContactos({ ...payload, contactos: result.contactos }));

      dispatch({
        type: "UPDATE_VIEW_ITEM",
        payload: {
          id: payload.id,
          type: payload.type,
          item: result.item
        }
      });

      if (!selectMetadataByType(state, payload.type)) dispatch(onLoadMetaData(payload.type, null));
    });
  };
}

export function showSmartView(payload) {
  return function(dispatch, getState) {
    var state = getState();
    dispatch(loadSmartView(payload));

    dispatch({
      type: "ENQUEUE_VIEW",
      payload: { ...payload }
    });
  };
}

export const hideSmartView = payload => ({
  type: "REMOVE_VIEW",
  payload
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case "UPDATE_VIEW_ITEM":
      return {
        ...state,
        itemsByTypeAndId: {
          ...state.itemsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.item
        }
      };

    case "ENQUEUE_VIEW":
      return {
        ...state,
        viewsByTypeAndId: {
          ...state.viewsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload
        }
      };

    case "REMOVE_VIEW":
      return {
        ...state,
        viewsByTypeAndId: {
          ...state.viewsByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: null
        }
      };

    default:
      return state;
  }
};
