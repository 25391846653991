import { enqueueSnackbar } from "./ui/alerts";

var init = {
  serverErrorsById: {},
  showProgressBar: false,
  alert: null,
  alertQueue: [],
  notifications: []
};

export function dismissAlert() {
  return {
    type: "HEADER_ALERT_DISMISS"
  };
}

export function updateServiceWorker(registration) {
  return function(dispatch, getState) {
    /*
    dispatch(
      notify({
        message: "Hay una nueva version",
        onAccept: () => {
          registration.waiting.postMessage("skipWaiting");
        },
        onCancel: () => {
          dispatch(dismissNotification());
        }
      })
    );
    */
  };
}

export function alert(message, type = "error", autoHide = false) {
  return function(dispatch, getState) {
    return dispatch(
      enqueueSnackbar({
        message: message,
        options: {
          variant: type
        }
      })
    );
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "AJAX_HTTP_ERROR": {
      return {
        ...state,
        count: state.count - 1,
        //notifications: state.notifications.concat(parseError(action.error, "")),
        loading: 0
      };
      break;
    }
    case "AJAX_API_ERROR": {
      return {
        ...state,
        count: state.count - 1,
        //notifications: state.notifications.concat(parseError(action.error, "")),
        loading: 0
      };
      break;
    }
    case "AJAX_SERVER_ERROR": {
      return {
        ...state,
        count: state.count - 1,
        //notifications: state.notifications.concat(parseError(action.error, "")),
        loading: 0
      };
      break;
    }
    case "AJAX_HTTP_ERROR_IN_LOGIN": {
      return {
        ...state,
        count: state.count - 1,
        //notifications: state.notifications.concat(parseError(action.error, "")),
        loading: 0
      };
      break;
    }
    case "AJAX_START": {
      return {
        ...state,
        showProgressBar: true
      };
      break;
    }

    case "AJAX_COMPLETE": {
      return {
        ...state,
        showProgressBar: false
      };
      break;
    }

    case "AJAX_IDLE": {
      return {
        ...state,
        loading: 0
      };
      break;
    }
  }
  return state;
};

export function selectAllServerErrors(state) {
  return Object.keys(state.header.serverErrorsById).map(function(key) {
    var error = state.header.serverErrorsById[key];
    error.id = key;
    return error;
  });
}
