import Blue from "@material-ui/core/colors/blue";
import Grey from "@material-ui/core/colors/grey";

export default {
  textInput: {
    fontWeight: 300,
    fontSize: "11px",
    width: "100%"
  },
  formControl: {
    marginLeft: 0,
    minWidth: "100%"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: `${Grey[300]} !important`,
      borderBottomWidth: "1px !important"
    },
    "&:after": {
      borderBottomColor: Blue[400]
    }
  },
  iconButton: {
    position: "absolute",
    right: -7,
    paddingTop: 4,
    top: 0
  },
  input: {
    paddingTop: 2,
    paddingBottom: 4
  },
  inputRoot: {
    fontSize: "12px",
    fontWeight: 300,
    paddingTop: 0
  },
  svg: {
    height: 20
  },
  switch: {
    height: 22
  }
};
