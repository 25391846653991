import React, { Fragment } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from "@material-ui/core/AppBar";

import CoreButton from "@material-ui/core/Button";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Hidden from "@material-ui/core/Hidden";

import { connect } from "react-redux";
import { throttle, debounce } from "throttle-debounce";
import { Switch, Route, Redirect } from "react-router-dom";

// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { onUpdateItem, selectFormById } from "redux/reducers/form";

import { onLoadDataById, selectDataByTypeAndId } from "redux/reducers/data";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";

import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import CircularProgress from "@material-ui/core/CircularProgress";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Grid from "@material-ui/core/Grid";

import Avatar from "@material-ui/core/Avatar";
import blue from "@material-ui/core/colors/blue";

import Tooltip from "@material-ui/core/Tooltip";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import Integer from "components/SmartForm/elements/integer";
import String from "components/SmartForm/elements/string";
import Number from "components/SmartForm/elements/number";
import Lookup from "components/SmartForm/elements/lookup";
import Multiselect from "components/SmartForm/elements/multiselect";
import MultiselectDynamic from "components/SmartForm/elements/multiselectDynamic";

import Select from "components/SmartForm/elements/select";
import CsvSelect from "components/SmartForm/elements/csvSelect";
import Boolean from "components/SmartForm/elements/boolean";
import JsonList from "components/SmartForm/elements/jsonList";
import BillingSelector from "components/SmartForm/elements/billingSelector";
import Date from "components/SmartForm/elements/date";
import MultiFileUpLoader from "components/SmartForm/elements/multiFileUploader";
import Files from "components/SmartForm/elements/files/index";
import SelectDynamic from "components/SmartForm/elements/selectDynamic";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Autocomplete from "components/SmartForm/elements/autocomplete";
import Textarea from "components/SmartForm/elements/textarea";
import Task from "components/SmartForm/elements/task";

import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import { Paper } from "@material-ui/core";

const typeMap = {
  integer: Integer,
  textarea: Textarea,
  string: String,
  number: Number,
  lookup: Lookup,
  select: Select,
  csvSelect: CsvSelect,
  multiFileUpLoader: MultiFileUpLoader,
  billingSelector: BillingSelector,
  selectDynamic: SelectDynamic,
  multiselectDynamic: MultiselectDynamic,
  autocomplete: Autocomplete,
  date: Date,
  files: Files,
  boolean: Boolean,
  multiselect: Multiselect,
  jsonList: JsonList,
  task: Task
};

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      flexGrow: 1,
      display: "flex",

      overflow: "hidden",
      width: "100%",
      minWidth: 320,
      marginTop: 50
    },

    gridCard: {
      marginBottom: 10,
      marginTop: 10
    },
    subheader: {
      width: "100%"
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },

    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff"
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0
    },

    noCardHeaderRoot: {
      paddingLeft: 0,
      paddingBottom: 0,
      marginBottom: 0
    },
    cardContent: {}
  };
};

class PureForm extends React.Component {
  constructor(props) {
    super(props);
  }

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  };

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(name, value, extra);
  };

  renderInputs() {
    let { classes } = this.props;
    if (this.props.metadata.layout) {
      return <Grid container>{this.props.isPure ? this.renderNoCards() : this.renderCards()}</Grid>;
    }
  }

  renderPending() {
    if (this.props.form && this.props.form.loadStatus == "PENDING")
      return (
        <div style={{ margin: 20 }}>
          <CircularProgress />
        </div>
      );
  }

  renderNoCards() {
    let { classes } = this.props;

    return this.props.metadata.layout.map(layoutItem => {
      if (layoutItem.list && !this.props.form.item.id) return null;
      return (
        <Fragment>
          <CardHeader
            classes={{ root: classes.noCardHeaderRoot }}
            avatar={
              <Avatar classes={{ root: classes.avatarRoot }} aria-label="Recipe" className={classes.avatar}>
                {layoutItem.title}
              </Avatar>
            }
          />
          {this.renderFormContents({ ...layoutItem, cols: 8 })}
        </Fragment>
      );
    });
  }

  renderCards() {
    let { classes } = this.props;
    return (
      <div className={classes.gridListRoot}>
        <Grid
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          container
          spacing={3}
          className={classes.gridList}>
          {this.props.metadata.layout.map(layoutItem => {
            if (layoutItem.list && !this.props.form.item.id) return null;

            return (
              <Grid
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,

                  borderBottom: "7px dotted rgba(240,240,250,0.6)",
                  marginBottom: 20,
                  paddingBottom: 30
                }}
                item
                key={layoutItem.title}
                xs={12}
                md={layoutItem.columns}>
                <div className={classes.gridCard}>
                  <Typography
                    style={{ color: deepPurple[500], marginBottom: 10, textTransform: "capitalize" }}>
                    {layoutItem.title}
                  </Typography>
                  <Grid spacing={3} container>
                    {this.renderFormContents(layoutItem)}
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  renderFormContents(layoutItem) {
    function getSize(cols) {
      if (layoutItem.fields.length == 1) return 12;
      else if (layoutItem.fieldSize) return layoutItem.fieldSize;
      else if (layoutItem.columns && layoutItem.columns <= 3) return 12;
      else if (layoutItem.columns && layoutItem.columns == 4) return 6;
      else if (layoutItem.columns && layoutItem.columns <= 8) return 4;

      return 3;
    }

    return layoutItem.fields.map(key => {
      var column = this.props.metadata.propertiesByKey[key];
      return this.renderInput(column, getSize(layoutItem.cols), key);
    });
  }

  renderInput = (column, size = 4, key) => {
    if (!column) {
      console.error("No se encontro una columna " + key + ", revise el JSON SCHEMA");
      return null;
    }

    var BaseElement = typeMap[column.element || column.type];
    if (this.props.typeMap && this.props.typeMap[column.key]) BaseElement = this.props.typeMap[column.key];

    if (!BaseElement) BaseElement = typeMap["string"];
    const elementId = Math.random();
    if (!column.ui) column.ui = {};
    if (!column.ui) column.ui = {};
    if (!column.subType) column.subType = column.type;
    column.key = key;
    return (
      <Grid style={{ paddingBottom: 0, paddingTop: 0 }} item key={column.key} md={size} xs={12}>
        <div style={{ marginRight: 10 }}>
          <BaseElement
            key={column.key}
            formId={this.props.formId}
            metadata={this.props.metadata}
            metadataType={this.props.metadataType}
            dispatch={this.props.dispatch}
            dispatchSimpleApi={this.dispatchSimpleApi}
            elementId={column.key}
            onChange={this.onFieldChange}
            item={this.props.form ? this.props.form.item : this.props.item}
            column={column}
            fieldStatus={this.props.form ? this.props.form.fieldStatus || {} : {}}
            onShowModal={this.props.onShowModal}
            onFastCreate={this.props.onFastCreate}
          />
        </div>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper style={{ padding: 15, minWidth: 320, width: "calc(100%)" }}>
          {this.renderInputs()}
          {this.renderPending()}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PureForm);
