import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";

const onProveedorFieldChange = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };

  if (name == "tipoCedula") {
    if (value == "Internacional") delta.moneda = "USD";
    else delta.moneda = "CRC";
  }
  return delta;
};
export default onProveedorFieldChange;
