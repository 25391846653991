import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";



import { connect } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleTable from "components/Table/Table.jsx";
import Table from "views/base/table";


import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit } from "redux/reducers/table";


const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  bold: {
    fontWeight: 800
  },
})

class MovimientoDevolverModal extends React.Component {

  state = {
    devolucionBoleta: "",
    devolucionReferencia: "",
    devolucionItems: {}
  }


  onDevolverClick = (e) => {
    this.props.dispatch(onAction("movimientoInventario", "devolver", {
      boleta: this.state.devolucionBoleta,
      descripcion: this.state.devolucionReferencia,
      items: Object.keys(this.state.devolucionItems).map((key) => {
        return this.state.devolucionItems[key]
      })
    }))
      .then((res) => {
        this.props.onCloseModal();
      })
      .catch((res) => {
        this.props.onCloseModal();
      })

  }

  handleReferenciaChange = (e) => {
    this.setState({ devolucionReferencia: e.currentTarget.value })
  }

  handleBoletaChange = (e) => {
    this.setState({ devolucionBoleta: e.currentTarget.value })
  }

  handleCantidadChange = (e) => {
    var item = e.currentTarget.dataset.item;
    var movimiento = JSON.parse(item);
    var devolucionItems = this.state.devolucionItems || {};
    devolucionItems[movimiento.productoId] = { id: movimiento.id, cantidad: parseFloat(e.currentTarget.value), documentoId: movimiento.documentoId };
    this.setState({ devolucionItems: devolucionItems })
  }

  renderDevolucionTable() {
    const { classes } = this.props;

    var items = this.props.items;
    if (!items) return;

    var headerKeys = ["Producto", "Cantidad"];
    var lists = items.map((item) => {
      return [item.__productoId, <TextField
        className={classes.textField}
        autoFocus
        inputProps={{
          "data-item": JSON.stringify(item)
        }}
        onChange={this.handleCantidadChange}
        value={this.state.devolucionItems && this.state.devolucionItems[item.productoId] ? this.state.devolucionItems[item.productoId].cantidad : 0}
        id="name"
        step="0.00001"
        type="number"
      />]


    })

    return <SimpleTable
      tableData={lists}
    />

  }


  render() {
    const { classes } = this.props;

    return <div><DialogContentText>
      Devolución de productos contra factura de cliente.
    </DialogContentText>

      <TextField
        className={classes.textField}
        autoFocus
        value={this.state.devolucionBoleta}
        onChange={this.handleBoletaChange}

        id="name"
        label="Numero de Boleta"
        type="text"
      />

      <TextField
        className={classes.textField}
        autoFocus
        value={this.state.devolucionReferencia}
        onChange={this.handleReferenciaChange}
        fullWidth
        id="name"
        label="Explicación"
        type="text"
      />

      {this.renderDevolucionTable()}

      <DialogActions>
        <Button onClick={this.props.onCloseModal.bind(this)} color="primary">
          Cancelar
          </Button>
        <Button onClick={this.onDevolverClick.bind(this)} color="primary">
          Devolver
      </Button>
      </DialogActions>

    </div >

  }
}

export default withStyles(allStyles, { withTheme: true })(MovimientoDevolverModal);


