import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import AutoComplete from "components/SmartForm/elements/autocomplete";
import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";

const mapStateToProps = (store, props) => {
  return {};
};
class AutoCompleteFormatter extends React.Component {
  render() {
    return <div>{this.props.row["__" + this.props.column.key] || ""}</div>;
  }
}

export default function(column, reload, getRow, handleUpdate, parentProps) {
  let cls = class extends React.Component {
    render() {
      return (
        <AutoCompleteFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          dispatch={parentProps.dispatch}
          parentProps={parentProps}
          onFastCreate={parentProps.onFastCreate}
          column={column}
          {...this.props}
        />
      );
    }
  };

  return cls;
}
