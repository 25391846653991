import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import groupArray from "group-array";
import numeral from "numeral";
const colors = ["#333", "rgba(220,220,220)", "rgba(245,245,245)", "rgba(250,250,250)"];

const styles = {
  table: {
    display: "flex",

    pageBreakInside: "avoid"
  },
  tableColumnHeader: {
    display: "flex",
    marginTop: 10,
    pageBreakInside: "avoid"
  },

  tableTotal: {
    display: "flex",
    backgroundColor: "#dedede",
    pageBreakInside: "avoid"
  },
  tableHeaderWrapper: {
    pageBreakInside: "avoid",
    border: "2px solid rgba(235,233,235)"
  },
  tableHeaderWrapper0: {
    borderTopWidth: 3,
    borderTopColor: "rgba(100,100,100)",
    backgroundColor: colors[0],
    color: "#fff",
    paddingLeft: 5,
    fontWeight: 600
  },
  tableHeaderWrapper1: {
    borderTopWidth: 3,
    borderTopColor: "rgba(200,200,200)",
    backgroundColor: colors[1]
  },
  tableHeaderWrapper2: {
    borderTop: "none",
    borderWidth: 1.5,
    backgroundColor: colors[2]
  },
  tableHeaderWrapper3: {
    borderWidth: 1,
    borderTop: "none",
    backgroundColor: colors[3]
  },
  tableHeaderWrapper4: {
    borderWidth: 0.6,
    borderTop: "none",
    backgroundColor: colors[3]
  },
  tableHeader: {
    display: "flex",
    pageBreakInside: "avoid"
  },
  headerCell: {
    flexGrow: 1,
    border: "1px solid #ddd",
    padding: 5,
    pageBreakInside: "avoid"
  },
  rowHeaderCell: {
    flexGrow: 1,
    border: "none",
    padding: 5,
    paddingTop: 2,
    paddingBottom: 0
  },
  rowHeaderCell_number: {
    textAlign: "right"
  },
  headerCell_number: {
    textAlign: "right"
  },
  headerCell_integer: {
    textAlign: "right"
  },
  rowCell: {
    flexGrow: 1,
    border: "1px solid #eee",
    padding: 5,
    pageBreakInside: "avoid"
  },
  rowCell_number: {
    textAlign: "right"
  },
  rowCell_integer: {
    textAlign: "right"
  }
};

class Reporte extends React.Component {
  state = {
    colMap: {},
    colWidth: 10
  };

  componentDidMount() {
    const cols = this.props.printColumns.length;
    var colMap = {};
    this.props.printColumns.forEach(item => {
      colMap[item] = this.props.metadata.properties[item];
    });

    this.props.printColumns.sort((a, b) => {
      const fields = this.props.metadata.table.fields;
      try {
        var colA = colMap[a];
        var colB = colMap[b];
        var indexA = fields.indexOf(a);
        var indexB = fields.indexOf(b);

        if (indexA == -1) indexA = 100;
        else if (colA.type == "number") indexA = 1000;
        if (indexB == -1) indexB = 100;
        else if (colB.type == "number") indexB = 1000;

        if (indexA > indexB) return 1;
        else if (indexA <= indexB) return -1;
        else return 0;
      } catch (e) {
        return 1;
      }
    });

    var eqSize = 100 / cols;
    this.setState({
      colWidth: eqSize,
      colMap: colMap
    });
  }

  adjustWidthByType(column) {
    if (column.element == "date") return this.state.colWidth * 0.8 + "%";
    return this.state.colWidth + "%";
  }

  renderHeaderCell(column) {
    if (!column) return null;
    return <div>{column.title}</div>;
  }

  renderRowCell(column, item, key) {
    if (!column) return null;
    var value = (item[key] || "") + "";
    if (column.type == "number") value = numeral(value).format("0,0.00");
    if (column.key == "consecutivo") {
      var index = 0;
      var lastChar = "0";
      value = value.replace("001", "").replace("00001", "");
      value = value.substring(2);
      value.split("").forEach((char, loopIndex) => {
        if (char != "0" && lastChar == "0" && index == 0) index = loopIndex;
      });

      value = value.length == 20 ? value.substring(index) : value;
    }
    return <div>{value}</div>;
  }

  renderColumnHeader(shouldBreak) {
    const { classes } = this.props;
    var breakClass = shouldBreak ? classes.break : "";
    return (
      <div style={{ display: "tableHeaderGroup" }} className={classes.tableColumnHeader}>
        {this.props.printColumns.map(key => {
          if (key.indexOf("__") == 0) key = (key + "").replace("__", "");
          var column = this.state.colMap[key] || { type: "string" };

          return (
            <div
              style={{ width: this.adjustWidthByType(column) }}
              className={[classes.headerCell, classes[`headerCell_${column.element || column.type}`]].join(
                " "
              )}>
              {this.renderHeaderCell(column)}
            </div>
          );
        })}
      </div>
    );
  }

  renderHeader(header) {
    const { classes } = this.props;

    var keys = Object.keys(header.value || {});
    var sum = keys.filter(item => item.indexOf("sums_") == 0 || item == "sum");
    var sums = {};
    if (sum && sum[0]) sums = header.value[sum[0]];

    var level = header.level;

    var cells = this.props.printColumns.map((key, index) => {
      var column = this.state.colMap[key] || { type: "string" };

      if (sums[key])
        return (
          <div
            style={{ width: this.adjustWidthByType(column) }}
            className={[
              classes.rowHeaderCell,
              classes[`rowHeaderCell_${column.element || column.type}`]
            ].join(" ")}>
            {numeral(sums[key]).format("0,0.00")}
          </div>
        );
      else
        return (
          <div
            style={{ width: this.adjustWidthByType(column) }}
            className={[
              classes.rowHeaderCell,
              classes[`rowHeaderCell_${column.element || column.type}`]
            ].join(" ")}
          />
        );
    });
    var name = (header.name || "-") + "";
    name = name.replace("__", "").replace("Id", "");

    return (
      <div className={[classes.tableHeaderWrapper, classes["tableHeaderWrapper" + level]].join(" ")}>
        <div
          style={{
            paddingLeft: level * 20
          }}
          className={[classes.rowHeaderCell].join(" ")}>
          {name}: {header.key}
        </div>

        <div className={classes.tableHeader}>{cells}</div>
      </div>
    );
  }

  renderTotal(total) {
    const { classes } = this.props;

    return this.props.printColumns.map((key, index) => {
      //if (key.indexOf("__") == 0) key = key.replace("__", "");
      var column = this.state.colMap[key] || { type: "string" };

      if (index == 0)
        return (
          <div
            style={{ width: this.adjustWidthByType(column) }}
            className={[classes.rowCell, classes[`rowCell_${column.element || column.type}`]].join(" ")}>
            Total {total.key}
          </div>
        );

      if (total.value[key])
        return (
          <div
            style={{ width: this.adjustWidthByType(column) }}
            className={[classes.rowCell, classes[`rowCell_${column.element || column.type}`]].join(" ")}>
            {numeral(total.value[key]).format("0,0.00")}
          </div>
        );
      else
        return (
          <div
            style={{ width: this.adjustWidthByType(column) }}
            className={[classes.rowCell, classes[`rowCell_${column.element || column.type}`]].join(" ")}
          />
        );
    });
  }

  renderLine(row) {
    const { classes } = this.props;
    if (!this.props.showLines) return;
    return (
      <div className={classes.table}>
        {this.props.printColumns.map(key => {
          var column = this.state.colMap[key] || { type: "string" };

          if (!column.select && key.indexOf("Id") > -1) key = "__" + key;

          return (
            <div
              style={{ width: this.adjustWidthByType(column) }}
              className={[classes.rowCell, classes[`rowCell_${column.element || column.type}`]].join(" ")}>
              {this.renderRowCell(column, row, key)}
            </div>
          );
        })}
      </div>
    );
  }

  renderRows(rows) {
    const { classes } = this.props;
    var groupNames = this.props.groupBy.map(key => {
      if (key.indexOf("Id") > -1 && rows.length > 0 && rows[0][`__${key}`]) return `__${key}`;
      else return key;
    });

    var sumFields = this.props.sumFields;
    var groupRows = {};
    if (!rows || rows.length == 0) return null;
    var groupRows;
    if (groupNames && groupNames.length > 0) groupRows = { reporte: groupArray(rows, groupNames) };
    else groupRows = { reporte: rows };

    function recurse(groups, parentKey = "") {
      var keys = Object.keys(groups);
      keys.sort((a, b) => {
        if (a == b) return 0;
        else if (a > b) return 1;
        else return -1;
      });
      var sums = {};
      let sumFieldKeys = Object.values(sumFields);
      sumFieldKeys.forEach(sumField => {
        sums[sumField] = 0;
      });

      keys
        .filter(key => key.indexOf("sums_") != 0)
        .forEach(key => {
          var sumsKey = `sums_${key}`;

          if (Array.isArray(groups[key])) {
            let localSum = {};
            sumFieldKeys.forEach(sumField => {
              localSum[sumField] = 0;
            });

            groups[key].forEach(row => {
              sumFieldKeys.forEach(sumField => {
                sums[sumField] += row[sumField];
                localSum[sumField] += row[sumField];
              });
            });

            groups[key].sum = localSum;
          } else {
            if (!groups[key][sumsKey]) {
              groups[key][sumsKey] = {};
              sumFieldKeys.forEach(sumField => {
                groups[key][sumsKey][sumField] = 0;
              });
            }
            var recurseResult = recurse(groups[key], key);

            sumFieldKeys.forEach(sumField => {
              groups[key][sumsKey][sumField] += recurseResult[sumField];
              sums[sumField] += recurseResult[sumField];
            });
          }
        });
      return sums;
    }

    groupRows.sums = recurse(groupRows);

    var lines = [];
    function toLine(groupRows, lines, level) {
      if (level == 1 && Array.isArray(groupRows)) {
        lines.push({
          type: "header",
          value: groupRows,
          name: groupNames[level - 1],
          key: "Reporte",
          level: level
        });
        lines.push({ type: "lines", value: groupRows, key: "Reporte", level: level });
        return lines;
      }
      var keys = Object.keys(groupRows);
      keys.forEach(key => {
        var value = groupRows[key];

        if (key.indexOf("sums_") == 0) return;
        else if (key.indexOf("sums") == 0) return;
        else if (Array.isArray(value)) {
          lines.push({ type: "header", value: value, name: groupNames[level - 1], key: key, level: level });
          lines.push({ type: "lines", value: value, key: key, level: level });
        } else {
          lines.push({ type: "header", name: groupNames[level - 1], value: value, key: key, level: level });
          toLine(value, lines, level + 1);
        }
      });
    }
    toLine(groupRows.reporte, lines, 1);

    lines.push({
      type: "header",
      value: { sum: groupRows.sums },
      name: "Total",
      key: "Total",
      level: 0
    });
    var reportLines = lines.map((item, index) => {
      if (item.type == "header") return this.renderHeader(item);
      else if (item.type == "total")
        return <div className={classes.tableTotal}>{this.renderTotal(item)}</div>;
      else if (item.type == "lines") return item.value.map(line => this.renderLine(line));
      return null;
    });

    return reportLines;
  }

  render() {
    var rowParts = [];

    return (
      <div>
        {this.renderColumnHeader(false)}
        {this.renderRows(this.props.rows)}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Reporte);
