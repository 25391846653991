import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "views/base/table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import { connect } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { changeLimit, selectLimit } from "redux/reducers/table";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { container } from "assets/jss/material-dashboard-pro-react.jsx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { func } from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { alert } from "redux/reducers/header";
import { simpleApi } from "redux/reducers/api";
import Switch from "@material-ui/core/Switch";

import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const IGNORE_FIELDS = ["marca", "grupo", "producto", "id", "costo", "precio", "descuento"];

const allStyles = theme => ({
  ...styles,
  ...extendedTablesStyle,
  bold: {
    fontWeight: 800
  },
  textField: {
    marginBottom: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  q: {
    marginTop: 30,
    marginBottom: 7
  },
  chip: {
    margin: 10
  }
});

class PriceListModal extends React.Component {
  state = {
    devolucionBoleta: "",
    devolucionReferencia: "",
    equiparar: false,
    devolucionItems: {},
    selectedKeys: [],
    step: 0,
    grupos: [],
    selectedLista: null
  };

  componentDidMount() {
    if (this.state.grupos.length == 0)
      this.props.dispatch(simpleApi("grupo/allWithName", {})).then(res => {
        var groupMap = {};
        res.forEach(group => {
          groupMap[group.name] = group;
        });
        this.setState({ grupos: res, groupMap: groupMap });
      });
  }

  onSave = e => {
    this.props
      .dispatch(
        onAction("priceList", "save", {
          precios: this.state.precios
        })
      )
      .then(res => {
        this.props.onCloseModal();
      })
      .catch(res => {
        this.props.onCloseModal();
      });
  };

  onChipClick = groupName => {
    return () => {
      this.setState({ selectedLista: groupName });
      this.onStep(this.state.selectedAction)();
    };
  };

  onChangeValue = key => {
    return e => {
      this.setState({ [key]: e.currentTarget.value || e.currentTarget.dataset.key });
    };
  };

  onStep = (toStep, fn = null, validate = []) => {
    return () => {
      var valid = true;
      if (validate.lenght == 0)
        this.setState({ porcentaje: null, utilidad: null, base: null, descuento: null });
      else {
        validate.forEach(key => {
          if (this.state[key] == null) {
            valid = false;
            this.props.dispatch(alert("Debe ingresar valores para " + validate.join(",")));
          }
        });
      }
      if (!valid) return;
      if (fn) this[fn]();
      this.setState({ step: toStep });
    };
  };

  calculateByUtilidad = () => {
    var precios = [];
    var maxPrecio = 0;
    var deltaMax = 0;
    var items = this.props.items.map(item => {
      var precio = {
        id: item.id,
        producto: item.producto,
        grupos: []
      };

      let selectedGroup = this.state.selectedLista;
      var precioItemBase = item["_" + selectedGroup];
      if (precioItemBase == null) {
        var newPrecioBase = {
          costo: item.costo,
          descuento: 0,
          grupoId: null,
          __grupoId: selectedGroup,
          precio: 0
        };
        item["_" + selectedGroup] = newPrecioBase;
        precioItemBase = newPrecioBase;
      }
      var precioBase = precioItemBase.precio;
      if (precioBase == null) return;

      var utilidad = parseFloat(this.state.utilidad);
      var descuento = parseFloat(this.state.descuento);
      var descuentoNumber = descuento / 100;
      var utilidadNumber = utilidad / 100;

      var precioConUtilidad = item.costo / (1 - utilidadNumber);

      var precioConDescuento = parseInt((precioConUtilidad / (1 - descuentoNumber)) * 100) / 100;
      var delta = 100 * ((precioConUtilidad - precioBase) / precioConUtilidad);

      if (precioConDescuento > maxPrecio) maxPrecio = precioConDescuento;
      if (delta > deltaMax) deltaMax = delta;

      precio.grupos.push({
        grupoId: this.state.groupMap[selectedGroup].id,
        name: selectedGroup,
        precio: precioConDescuento,
        precioDelta: delta,
        descuento: descuento
      });

      precios.push(precio);
    });

    if (this.state.equiparar) {
      precios.forEach(precioItem => {
        precioItem.grupos.forEach(precioItemGrupo => {
          precioItemGrupo.precio = maxPrecio;
          precioItemGrupo.delta = deltaMax;
        });
      });
    }

    this.setState({ precios: precios });
  };

  calculateByLista = () => {
    var precios = [];

    var items = this.props.items.map(item => {
      var precio = {
        id: item.id,
        producto: item.producto,
        grupos: []
      };

      let selectedGroup = this.state.selectedLista;

      var precioItemBase = item["_" + this.state.base];
      if (precioItemBase == null) return null;
      var precioBase = precioItemBase.precio;
      if (precioBase == null) return null;

      var descuento = parseFloat(this.state.descuento);

      precio.grupos.push({
        grupoId: this.state.groupMap[selectedGroup].id,
        name: selectedGroup,
        precio: precioBase,
        precioDelta: 0,
        descuento: descuento
      });

      if (precio.grupos.length > 0) precios.push(precio);
    });
    this.setState({ precios: precios });
  };

  renderStep0() {
    const { classes } = this.props;

    if (this.state.step != "0") return null;
    return (
      <div>
        <DialogTitle>Cambio de Precio</DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: 40 }} gutterBottom>
            Hay dos formas de cambiar los precios:
          </Typography>

          <Button
            variant="outlined"
            onClick={() => {
              this.onChangeValue("selectedAction")({ currentTarget: { value: "utilidad" } });
              this.onStep("1")();
            }}
            color="primary">
            Alcanzar una Utilidad
          </Button>
          <Typography variant="body1" style={{ marginBottom: 30 }} gutterBottom>
            Escoja una utilidad y un descuento.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              this.onChangeValue("selectedAction")({ currentTarget: { value: "lista" } });
              this.onStep("1")();
            }}
            color="primary">
            Basarse en otra lista y ajustar descuento.
          </Button>
          <Typography variant="body1" style={{ marginBottom: 30 }} gutterBottom>
            Se selecciona el precio de una lista, y se cambia solamente el descuento para otra lista.
          </Typography>
        </DialogContent>
      </div>
    );
  }

  renderStep1() {
    const { classes } = this.props;

    if (this.state.step != "1") return null;
    return (
      <div>
        <DialogTitle>Cambio de Precio</DialogTitle>

        <DialogContent>
          <Typography variant="body1" style={{ marginBottom: 40 }} gutterBottom>
            Escoja la lista que quiere modificar
          </Typography>

          {this.state.grupos.map(grupo => {
            let selected = this.state.selectedLista == grupo.name;
            return (
              <Chip
                key={grupo.name}
                label={grupo.name}
                onClick={this.onChipClick(grupo.name)}
                color={selected ? "primary" : "secondary"}
                className={classes.chip}
              />
            );
          })}
        </DialogContent>
      </div>
    );
  }

  renderUtilidad() {
    const { classes } = this.props;

    if (this.state.step != "utilidad") return null;
    return (
      <div>
        <DialogTitle>Cambio de Precio por Utilidad</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Escoja la utilidad y el sistema la asignara a los productos de la lista{" "}
            {this.state.selectedKeys.join(" ")}
            el precio correspondiente. El descuento es comercial y se suma al precio para que resulte en la
            utilidad indicada.
          </Typography>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <div className={classes.textField}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  autoFocus
                  value={this.state.utilidad || ""}
                  onChange={this.onChangeValue("utilidad")}
                  label="Utilidad"
                  type="number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.textField}>
                <TextField
                  className={classes.textField}
                  value={this.state.descuento || ""}
                  onChange={this.onChangeValue("descuento")}
                  fullWidth
                  label="Descuento"
                  type="number"
                />
              </div>
            </Grid>
          </Grid>

          <FormControl component="fieldset">
            <FormLabel component="legend">Igualar Precios</FormLabel>
            <Typography variant="body1">
              Se usa en caso de productos similares que deben tener el mismo precio. El sistema los iguala al
              precio mayor del grupo seleccionado.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.gilad}
                    onChange={this.onChangeValue("equiparar")}
                    value={true}
                  />
                }
                label="Al precio Mayor"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onStep("productos", "calculateByUtilidad", ["utilidad", "descuento"])}
            color="primary">
            Revisar Productos
          </Button>
        </DialogActions>
      </div>
    );
  }

  renderLista() {
    const { classes } = this.props;

    if (this.state.step != "lista") return null;
    return (
      <div>
        <DialogTitle>Cambio de Precio de {this.state.selectedLista}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginBottom: 30 }} gutterBottom>
            El sistema mantiene los precios de la lista <strong>seleccionada a continuacion</strong> y
            modifica los descuentos que usted ingrese. Escoja la lista base y el sistema la asignara a los
            productos de la lista
          </Typography>
          <div style={{ maxWidth: 400 }}>
            <div className={classes.textField}>
              <InputLabel>Lista Base</InputLabel>
              <Select
                className={classes.textField}
                onChange={this.onChangeValue("base")}
                value={this.state.base || ""}
                fullWidth
                inputProps={{}}>
                {this.state.grupos.map(grupo => {
                  return (
                    <MenuItem data-key={grupo.name} value={grupo.name}>
                      {grupo.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography variant="caption">La lista base es de donde se toman los precios</Typography>
            </div>

            <div className={classes.textField}>
              <TextField
                className={classes.textField}
                autoFocus
                onChange={this.onChangeValue("descuento")}
                value={this.state.descuento || ""}
                fullWidth
                label="Descuento"
                type="number"
              />
              <Typography variant="caption">El descuento que tendra la nueva lista</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onStep("productos", "calculateByLista", ["base", "descuento"])}
            color="primary">
            Revisar Productos
          </Button>
        </DialogActions>
      </div>
    );
  }

  renderProductos() {
    if (this.state.step != "productos") return null;
    return (
      <div>
        <ProductoTable
          onSave={this.onSave}
          precios={this.state.precios || []}
          selectedKeys={this.state.selectedKeys}
          {...this.props}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        {this.renderStep0()}
        {this.renderStep1()}
        {this.renderUtilidad()}
        {this.renderLista()}
        {this.renderProductos()}
      </div>
    );
  }
}

export default withStyles(allStyles, { withTheme: true })(PriceListModal);

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    overflowX: "auto",
    minWidth: 700,
    overflowY: "scroll",
    maxHeight: "60vh"
  }
});

function SimpleTable(props) {
  const { classes } = props;
  var grupos = [];
  if (props.precios.length > 0) grupos = props.precios[0].grupos || [];
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom>
        Nuevos Precios de {props.selectedKeys.join(" , ")}
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            {grupos.map(grupo => {
              return (
                <Fragment>
                  <TableCell>Precio {grupo.name} </TableCell>
                  {grupo.descuento != null ? <TableCell>Descuento {grupo.name} </TableCell> : null}
                </Fragment>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.precios.map(precio => (
            <TableRow key={precio.id}>
              <TableCell component="th" scope="row">
                {precio.producto}
              </TableCell>
              {precio.grupos.map(grupo => {
                return (
                  <Fragment>
                    <TableCell numeric>
                      {grupo.precio}
                      <small>
                        {grupo.precioDelta > 0 ? " +" : " "}
                        {parseInt(grupo.precioDelta * 100) / 100}%
                      </small>
                    </TableCell>
                    {grupo.descuento != null ? <TableCell>{grupo.descuento}%</TableCell> : null}
                  </Fragment>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="caption" gutterBottom>
        Los cambios quedan registrados en PriceList Historico.
      </Typography>
      <DialogActions>
        <Button onClick={props.onSave} color="primary">
          Aplicar Cambios
        </Button>
      </DialogActions>
    </div>
  );
}

let ProductoTable = withStyles(tableStyles)(SimpleTable);
