import { simpleApi } from "../api";
const defaultState = {
  notasByTypeAndId: {}
};

export function loadNotes(payload) {
  return {
    type: "LOAD_NOTES",
    payload: payload
  };
}

export function createNote(note, type, id) {
  return function(dispatch, getState) {
    var state = getState();

    return dispatch(simpleApi("notes/create", { ...note, type: type, id: id })).then(results => {
      return dispatch({
        type: "ADD_NOTE",
        payload: { note: results, type, id }
      });
    });
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_NOTES":
      return {
        ...state,
        notasByTypeAndId: {
          ...state.notasByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.notes
        }
      };

    case "ADD_NOTE":
      return {
        ...state,
        notasByTypeAndId: {
          ...state.notasByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: [
            ...(state.notasByTypeAndId[`${action.payload.type}-${action.payload.id}`] || []),
            action.payload.note
          ]
        }
      };

    default:
      return state;
  }
};
