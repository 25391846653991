import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#ddd"
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#8884d8"
  }
})(LinearProgress);

const styles = {
  card: {
    width: "100%",
    marginBottom: 12,
    position: "relative"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;

  var statKey = props.metric.statKey;
  if (statKey.indexOf(".") > -1) {
    statKey = statKey.split(".")[1];
  }

  var rawValue = props.value || [{ [statKey]: 0 }];
  var value = 0;

  try {
    value = rawValue[0][statKey];
  } catch (e) {}

  var coverage = 0;
  try {
    coverage = (value / props.metric.goal) * 100;
  } catch (e) {}

  if (props.metric.symbol == "(₡)") value = numeral(value).format("0,00 ac");
  return (
    <Card className={classes.card}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={coverage}
      />

      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.metric.title}
        </Typography>
        <Typography style={{ fontSize: "0.45rem" }} color="textSecondary" variant="caption">
          {props.metric.helpText}
        </Typography>
        <Typography variant="h5" component="h2">
          {value}
          {props.metric.sufix}
        </Typography>
        <Typography style={{ fontSize: "0.55rem", color: "#8884d8" }} variant="caption">
          {props.metric.symbol}
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
