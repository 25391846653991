import React from "react";
import PropTypes from "prop-types";
import { shapes } from "react-data-grid";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import { onFilter, selectFilterByKey, selectGroupByKey, changeGroup } from "redux/reducers/table";
import GridOff from "@material-ui/icons/GridOff";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import ViewAgenda from "@material-ui/icons/ViewAgenda";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import styles from "./FilterStyles";

const { ExcelColumn } = shapes;

const mapStateToProps = (store, props) => {
  return {
    filter: selectFilterByKey(store, props.column.metadataType, props.column.key),
    grouped: selectGroupByKey(store, props.column.metadataType, props.column.key)
  };
};

class TextFilter extends React.Component {
  handleChange = e => {
    if (this.props.column.groupOnly) return;

    let value = e.target.value;
    var key = this.props.column.key;

    var filterFormmatedValue = value;
    var filterComparator = "=";

    var filterValue = [`${this.props.metadataType}.${key}`, filterComparator, filterFormmatedValue];

    if (value.length == 0) filterValue = null && value == null;

    this.props.onChange({
      [this.props.column.key]: {
        metadataType: this.props.metadataType,
        column: this.props.column,
        textValue: value,
        filterValue: filterValue
      }
    });
  };

  onClick = () => {
    this.props.onChange({
      column: this.props.column,
      value: "GROUP"
    });
  };

  renderIconButton() {
    const { classes } = this.props;
    var icon;
    if (!this.props.grouped) icon = <ViewAgenda classes={{ root: classes.svg }} />;
    else icon = <GridOff />;

    return (
      <IconButton
        className={classes.iconButton}
        color={this.props.grouped ? "primary" : "default"}
        size="sm"
        onClick={this.onClick}>
        {icon}
      </IconButton>
    );
    return null;
  }

  render() {
    const { classes } = this.props;

    let columnStyle = {
      maxWidth: "100%"
    };

    let filterValue = this.props.filter || { textValue: "" };

    return (
      <div style={columnStyle}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={10}>
            <TextField
              value={filterValue.textValue}
              onChange={this.handleChange}
              className={classes.textInput}
              InputProps={{
                classes: {
                  input: classes.input,
                  underline: classes.underline,
                  root: classes.inputRoot
                }
              }}
            />
          </Grid>

          <Grid item xs={2}>
            {this.renderIconButton()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

TextFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn)
};

const TextWithStyles = withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TextFilter));

export default function(metadataType) {
  return class extends React.Component {
    render() {
      return <TextWithStyles metadataType={metadataType} {...this.props} />;
    }
  };
}
