import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import { onFilter, selectFilterByKey, selectGroupByKey, changeGroup } from "redux/reducers/table";
import GridOff from "@material-ui/icons/GridOff";
import IconButton from "@material-ui/core/IconButton";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import TextInput from "@material-ui/core/Input";

import MenuItem from "@material-ui/core/MenuItem";

const style = {};

class SelectFormatter extends React.Component {
  handleChange = e => {
    const value = e.target.value.filter(
      item => item && item.length > 0 && this.props.column.options.indexOf(item) > -1
    );

    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: value.join(",") },
      fromRowId: this.props.row.id
    });
  };

  render() {
    let { classes } = this.props;
    if (!this.props.column.editable) return this.props.value;
    return (
      <Select
        style={{ width: "100%" }}
        multiple
        value={this.props.value.split(",")}
        onChange={this.handleChange}
        inputProps={{}}
        input={
          <TextInput
            classes={{
              underline: classes.underline,
              root: classes.inputRoot
            }}
          />
        }>
        {this.props.column.options.map(item => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
}

const SelectFormatterWithStyles = withStyles(style)(SelectFormatter);

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <SelectFormatterWithStyles
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
