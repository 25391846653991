import Ajax from "../helpers/ajax";
import { onLoadDataSuccess, onLoadData, registerLastQuery } from "./data";
import { changeGroup } from "./table";
import { onWhoAmISuccess } from "./user";
import * as Sentry from "@sentry/browser";

import { selectFilters } from "./table";

const init = { byType: {} };

export function initMetadata() {
  return function(dispatch, getState) {
    try {
      dispatch(onLoadMetaData("cliente", null));
      var metadatas = JSON.parse(window.localStorage.getItem("metadata") || "{}");
      var keys = Object.keys(metadatas);

      keys.forEach(key => {
        if (key == "cliente") init.byType[key] = metadatas[key];
      });

      return Promise.resolve({});
    } catch (e) {
      console.log(e);
    }
  };
}

export function onLoadMetaData(metadataType, filters, queryParams) {
  return function(dispatch, getState) {
    var state = getState();
    var existingFilters = selectFilters(state, metadataType);
    if (existingFilters && queryParams.source == "table") {
      queryParams = null;
      filters = existingFilters;
    }

    if (state.metadata.byType[metadataType] && (filters || queryParams))
      return dispatch(onLoadData(metadataType, filters, queryParams));

    if (!queryParams || !queryParams.id) {
      dispatch(registerLastQuery(metadataType, filters, queryParams));
    }

    return Ajax.post(dispatch, `${metadataType}/metadata`, { filters: filters, ...queryParams })
      .then(function(results) {
        if (results.item) dispatch(onLoadDataSuccess(metadataType, [results.item]));
        else if (results.recent) dispatch(onLoadDataSuccess(metadataType, results.recent));
        delete results.item;
        delete results.recent;

        if (results.properties.estado) {
          dispatch(changeGroup(metadataType, "estado"));
        }

        dispatch(onLoadMetadataSuccess(metadataType, results, state));
        dispatch(onWhoAmISuccess(results));

        if (window.FS) window.FS.identify(results.user.id, results.user);

        Sentry.configureScope(scope => {
          if (window.FS) scope.setExtra("fullStory", window.FS.getCurrentSessionURL());
          scope.setUser({
            id: results.user.id,
            username: results.user.name,
            email: results.user.email
          });
        });

        return results;
      })
      .catch(function(apiError) {
        //if (apiError.status == 404) window.location = window.location.origin;
        //console.log(apiError);
      });
  };
}

export function onLoadMetadataSuccess(metadataType, results, state) {
  var keys = Object.keys(results.properties);
  results.propertiesByKey = {};
  keys.forEach(function(key) {
    var column = results.properties[key];
    results.propertiesByKey[key] = column;
  });

  return {
    type: "META_DATA_LOAD_SUCCESS",
    payload: {
      results: results,
      metadataType: metadataType
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "META_DATA_LOAD_SUCCESS": {
      var payload = action.payload;
      var newState = {
        ...state,
        byType: { ...state.byType, [payload.metadataType]: payload.results }
      };
      if (payload.metadataType == "cliente")
        window.localStorage.setItem("metadata", JSON.stringify(newState.byType));
      return newState;
    }
  }
  return state;
};

export function selectMetadataByType(state, type) {
  return state.metadata.byType[type];
}

export function selectMetadataByTypeFn(state) {
  return type => {
    return selectMetadataByType(state, type);
  };
}
