import React, { Fragment } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FileUpload from "./uploadInput";
import Paper from "@material-ui/core/Paper";

import InputLabel from "@material-ui/core/InputLabel";

import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import CloudUpload from "@material-ui/icons/CloudUpload";
import CloudDownload from "@material-ui/icons/CloudDownload";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Close } from "@material-ui/icons";
import { Link } from "@material-ui/core";

const styles = {
  root: {
    marginTop: 20
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

// Custom Formatter component
class FileFormatter extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleComplete = (fileKey, fullPath) => {
    this.setState({ open: false });
    this.props.onComplete(this.props.fileKey, fileKey, fullPath);
  };

  handleClickRemove = () => {
    this.setState({ open: false });
    this.props.onComplete(this.props.fileKey, "", "");
  };

  handleDownLoad = path => {
    return () => {
      window.open(path);
    };
  };

  renderModal() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">Subir Archivo</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" />

            <FileUpload
              metadata={this.props.metadata}
              accept={this.props.accept}
              fileKey={this.props.fileKey}
              route={this.props.route}
              onComplete={this.handleComplete}
              onClose={this.handleClose}
              label="Cargar"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  renderIcon() {
    return this.props.value;
  }

  renderArchivoAdjunto() {
    var file = "";
    var last = this.props.metadata[this.props.fileKey].split("/").pop();
    const { classes } = this.props;
    return (
      <Fragment>
        <IconButton
          color="primary"
          onClick={this.handleDownLoad(this.props.metadata[this.props.fileKey])}
          className={classes.iconButton}
          aria-label="Directions">
          <CloudDownload />
        </IconButton>
        <Divider style={{ marginRight: 6 }} className={classes.divider} />
        <Link
          style={{ maxWidth: "70%" }}
          onClick={this.handleDownLoad(this.props.metadata[this.props.fileKey])}>
          {last}
        </Link>
        <Divider style={{ marginLeft: 14 }} className={classes.divider} />
        <IconButton
          style={{ alignSelf: "flex-end" }}
          color="primary"
          onClick={this.handleClickRemove}
          className={classes.iconButton}
          aria-label="Directions">
          <Close />
        </IconButton>
      </Fragment>
    );
  }

  renderUpload() {
    const { classes } = this.props;
    return (
      <Fragment>
        <InputBase
          defaultValue={"Suba un archivo"}
          disabled={true}
          className={classes.input}
          value={this.props.metadata[this.props.fileKey]}
        />

        <Divider className={classes.divider} />
        <IconButton
          color="primary"
          onClick={this.handleClickOpen}
          className={classes.iconButton}
          aria-label="Directions">
          <CloudUpload />
        </IconButton>
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderModal()}

        <InputLabel style={{ marginBottom: 5 }}>{this.props.label}</InputLabel>
        <Paper className={classes.paper} elevation={0}>
          {this.props.metadata[this.props.fileKey] ? this.renderArchivoAdjunto() : this.renderUpload()}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FileFormatter);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
