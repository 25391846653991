import { simpleApi } from "../api";
import { createSelector } from "reselect";

const defaultState = {
  tasksByTypeAndId: {},
  allTasksById: []
};

export function loadTasks(payload) {
  return {
    type: "LOAD_TASKS",
    payload: payload
  };
}

export function queryTasks(task) {
  return function(dispatch, getState) {
    var state = getState();

    return dispatch(simpleApi("task/query", {})).then(results => {
      var allTasksById = state.task.allTasksById;
      results.forEach(result => {
        allTasksById[result.id] = result;
      });

      return dispatch({
        type: "LOAD_ALL_TASKS",
        payload: { allTasksById: allTasksById }
      });
    });
  };
}

export function queryTasksByType(type, id) {
  return function(dispatch, getState) {
    var state = getState();

    return dispatch(simpleApi("task/query", { filters: [["accountTypeId", "=", `${type}-${id}`]] })).then(
      results => {
        return dispatch({
          type: "LOAD_TASKS",
          payload: { type: type, id: id, tasks: results }
        });
      }
    );
  };
}

export function createTask(task) {
  return function(dispatch, getState) {
    var state = getState();

    return dispatch(simpleApi("task/" + (task.id ? "update" : "create"), task, true)).then(result => {
      var allTasksById = state.task.allTasksById;
      allTasksById[result.id] = result;
      if (result.progress == 100) delete allTasksById[result.id];

      dispatch({
        type: "LOAD_ALL_TASKS",
        payload: { allTasksById: allTasksById }
      });

      return dispatch({
        type: "ADD_TASKS",
        payload: { task: result }
      });
    });
  };
}

export function deleteTask(task, type, id) {
  return function(dispatch, getState) {
    var state = getState();

    return dispatch(simpleApi("task/destroy", { task, type: type, id: id })).then(result => {
      var allTasksById = state.task.allTasksById;
      delete allTasksById[result.id];

      dispatch({
        type: "LOAD_ALL_TASKS",
        payload: { allTasksById: allTasksById }
      });

      return dispatch({
        type: "REMOVE_TASK",
        payload: { task, type, id }
      });
    });
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_TASKS":
      return {
        ...state,
        tasksByTypeAndId: {
          ...state.tasksByTypeAndId,
          [`${action.payload.type}-${action.payload.id}`]: action.payload.tasks
        }
      };

    case "LOAD_ALL_TASKS":
      return {
        ...state,
        allTasksById: { ...action.payload.allTasksById }
      };

    case "ADD_TASKS":
      var id = `${action.payload.type}-${action.payload.task.id}`;
      var list = state.tasksByTypeAndId[id];
      var currentArray = (list || []).filter(item => {
        if (item.id == action.payload.task.id) return false;
        return true;
      });

      return {
        ...state,
        tasksByTypeAndId: {
          ...state.tasksByTypeAndId,
          [id]: [...currentArray, action.payload.task]
        }
      };

    case "REMOVE_TASK":
      var id = `${action.payload.task.type}-${action.payload.task.id}`;
      var list = state.tasksByTypeAndId[id];
      var currentArray = (list || []).filter(item => {
        if (item.id == action.payload.task.id) return false;
        return true;
      });

      return {
        ...state,
        tasksByTypeAndId: {
          ...state.tasksByTypeAndId,
          [id]: [...currentArray]
        }
      };

    default:
      return state;
  }
};

const getAllTasks = (state, props) => {
  return state.task.allTasksById;
};

export const selectAllTasks = createSelector(getAllTasks, items => Object.values(items));
