import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { fade } from "@material-ui/core/styles/colorManipulator";
import InputBase from "@material-ui/core/InputBase";
import { Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 5,
    position: "relative",
    top: -5,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      position: "absolute",
      right: 10,

      top: 2
    }
  },
  formControl: {
    minWidth: 170,

    [theme.breakpoints.down("md")]: {
      minWidth: 120,
      marginRight: 50
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menu: {
    color: theme.palette.secondary[50],
    marginTop: 0
  },
  icon: {
    color: theme.palette.primary[100],
    top: 4
  },
  selectRoot: {
    borderBottom: "1px solid #333"
  },
  homeIcon: {
    marginTop: 5,
    marginRight: 14
  }
});

class SimpleSelect extends React.Component {
  state = {
    age: "",
    name: "hai",
    labelWidth: 0
  };

  componentDidMount() {}

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderMenu() {
    const { classes } = this.props;

    var routes = Object.keys(this.props.routes);
    if (!routes || routes.length == 0) return null;
    return (
      <FormControl className={classes.formControl}>
        <Select
          classes={{
            icon: classes.icon,
            root: classes.selectRoot
          }}
          autoWidth
          className={classes.menu}
          value={this.props.location.pathname.split("/").pop()}
          onChange={e => this.props.onChange(e.target.value)}
          input={<BootstrapInput name="age" id="age-customized-select" />}>
          {routes.map(tabKey => {
            return <MenuItem value={tabKey.split("/").join("")}>{this.props.routes[tabKey].title}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <a className={classes.homeIcon} href="/home">
          <HomeIcon className={classes.icon} color="primary" />
        </a>

        {this.renderMenu()}
      </form>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    minHeight: 10,
    position: "relative",
    borderBottom: "1px solid " + theme.palette.secondary[100],
    fontSize: 13,
    width: "auto",
    padding: "6px 10px 2px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 0,
      borderColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,0)"
    }
  }
}))(InputBase);

export default withStyles(styles)(SimpleSelect);

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}
