//import Pusher from "pusher-js";
import { addToSocial } from "redux/reducers/feed";
import { onUpdateSuccess } from "redux/reducers/data";
const DEFAULT_CHANNELS = ["social"];
const actionMap = {
  social: addToSocial
};

var socket;
try {
  //  socket = new Pusher(process.env.REACT_APP_PUSHER_key, {
  //  cluster: "us2",
  //    forceTLS: true
  //  });
  //Pusher.logToConsole = process.env.NODE_ENV != "production";
} catch (e) {}

const init = { channels: {} };
let channels = {};

export function subscribeToMessage(channelName, event) {
  return function(dispatch, getState) {
    var state = getState();
    const channel = channels[channelName];
    channel.bind(event, function(data) {
      console.log(data);
      dispatch(addToSocial(data));

      if (data._options && data._options.subscribe) {
        channel.unbind(event);
        subscribeToChannel(data._options.subscribe.channel);
        subscribeToMessage(data._options.subscribe.channel, data._options.subscribe.event)(
          dispatch,
          getState
        );
      }
    });
  };
}

export function subscribeToChannel(name) {
  if (!socket) return null;
  const channel = socket.subscribe(name);
  channels[name] = channel;
  return channel;
}

export function subscribeToDefaultChannels() {
  return function(dispatch, getState) {
    if (!socket) return Promise.resolve({});
    DEFAULT_CHANNELS.forEach(channelName => {
      const channel = subscribeToChannel(channelName);
      channel.bind("general", function(data) {
        data.createdBy = data.createdBy || "";
        data.source = data.source || "Sistema";
        const action = actionMap[channelName];
        dispatch(action(data));
      });
    });

    return {
      type: "SUBSCRIBE"
    };
  };
}

export function subscribeToStandardChannel(metadataType) {
  if (channels[metadataType]) return { type: "SUBSCRIBE" };

  return function(dispatch, getState) {
    if (!socket) return Promise.resolve({});
    const channel = subscribeToChannel(metadataType);
    channel.bind("general", function(data) {
      dispatch(onUpdateSuccess(metadataType, data));
    });
    return {
      type: "SUBSCRIBE"
    };
  };
}

export function subscribeToExternalChannel(metadataType, action) {
  if (channels[metadataType]) return { type: "SUBSCRIBE" };

  return function(dispatch, getState) {
    if (!socket) return Promise.resolve({});
    const channel = subscribeToChannel(metadataType);
    channel.bind("general", function(data) {
      dispatch(action(data));
    });

    return {
      type: "SUBSCRIBE"
    };
  };
}

export function unSubscribeToStandardChannel(metadataType) {
  if (!socket) return { type: "IGNORE" };
  socket.unsubscribe(metadataType);
  delete channels[metadataType];
  return {
    type: "UNSUBSCRIBE"
  };
}

export default (state = init, action) => {
  return state;
};

export function getChannel(state, channelName) {
  return state.socket.channels[channelName];
}

export function selectDataByTypeAndId(state, type, id) {
  if (!state.data.byType[type]) return null;
  return state.data.byType[type][id];
}

export function selectDataByType(state, type) {
  if (!state.data.byType[type]) return null;
  var values = Object.values(state.data.byType[type]);
  var order = state.data.orderByType[type];

  return mapOrder(values, order, "id");
}

function mapOrder(array, order, key) {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
/*()
var channel = window.pusher.subscribe(action.subscribe);
channel.bind(JSON.stringify(ids), data => {
  channel.unbind(JSON.stringify(ids));
  this.props.dispatch(alert(data.message));
  if (data.subscribe) {
    window.pusher.subscribe(action.subscribe).bind(data.subscribe, data => {
      this.props.dispatch(alert(data.message));
    });
  }
});

*/
