import React from "react";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import TextField from "@material-ui/core/TextField";

import Colibri from "assets/img/colibri.svg";

import * as Sentry from "@sentry/browser";

const style = {
  ...loginPageStyle,
  ...pagesStyle,
  cardLogin: {
    backgroundColor: "rgba(255,255,255,0.7)"
  },
  content: {
    paddingTop: 50,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#544ecc",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%23b3dfff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23a8d1f2' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%239ec2e6' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%2393b4d9' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%238aa6cc' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%238098bf' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23778bb2' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%236f7da5' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23667098' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%235e638b' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%2356567d' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%234e4a70' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23463d63' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%233f3156' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23372649' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%232f1a3d' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23280f30' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23210024' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={[(this.state.cardAnimaton, classes.cardLogin)].join(" ")}>
                  <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                    <img className={classes.logo} src={Colibri} />
                  </CardHeader>
                  <CardBody>Ocurrio un error imprevisto y no se puede continuar</CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button onClick={() => Sentry.showReportDialog({})} color="rose" simple size="lg" block>
                      Reportar Error
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(ErrorBoundary);
