import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";

const styles = {
  card: {
    width: "100%"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.metric.title}
        </Typography>
        <PieChartComponent data={props.value || []} groupBy={props.metric.groupBy} />
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);

class PieChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ width: this.myRef.current.offsetWidth });
  }

  render() {
    var data = this.props.data.map(groupItem => {
      var name = "N/A";
      return {
        value: parseInt(groupItem[this.props.groupBy.sumFields] * 100) / 100,
        name: this.props.groupBy.dateGroup
          ? groupItem[this.props.groupBy.dateGroup.split("-")[0]]
          : groupItem[this.props.groupBy.groupBy[0]] || "N/A"
      };
    });
    return (
      <div ref={this.myRef}>
        <PieChart width={this.state.width || 0} height={100} data={data}>
          <Pie
            dataKey="value"
            nameKey="name"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={"47%"}
            cy={"115%"}
            outerRadius={80}
            fill="#8884d8"
            label={data => numeral(data.payload.value).format("(₡ 0.00 a)")}
          />
          <Tooltip formatter={value => numeral(value).format("(₡ 0.00 a)")} />
        </PieChart>
      </div>
    );
  }
}
