import moment from "moment";

const onNotaFinancieraFieldChange = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };
  if (name == "totalComprobante") return form.item;
  else if (["subTotal", "impuesto"].indexOf(name) > -1) {
    if (!delta.subTotal) delta.subTotal = 0;
    delta.impuesto = delta.subTotal * 0.13;
    delta.totalComprobante = delta.subTotal + delta.impuesto;
  }
  return delta;
};

export default onNotaFinancieraFieldChange;
