import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { hideFastCreate, showFastCreate } from "redux/reducers/ui/fastCreate";
import { onFieldChange } from "redux/reducers/form";

const styles = theme => ({
  titleRoot: {
    margin: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginLeft: 8
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing,
    top: 8,
    color: "#fff"
  }
});

const mapStateToProps = (store, props) => {
  return {};
};

class FastCreateContainer extends React.Component {
  state = {
    value: 0
  };

  showSubView = (type, id) => {
    this.props.dispatch(showFastCreate({ type: type, id: id }));
  };

  render() {
    const { classes } = this.props;
    var Component = this.props.component;

    return (
      <Component
        id={this.props.dialog.itemId}
        formId={this.props.dialog.formId}
        metadataType={this.props.dialog.metadataType}
        onClose={(createdResult, componentOnChange) => {
          this.props.onCreateComplete(this.props.dialog, createdResult, componentOnChange);
        }}
      />
    );
  }
}

FastCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FastCreateContainer));
