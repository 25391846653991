import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class FormDialog extends React.Component {
  state = {
    open: false,
    email: "",
    mensaje: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChange = type => {
    return e => {
      return this.setState({ [type]: e.currentTarget.value });
    };
  };

  validate = type => {
    if (!this.state[type] || this.state[type].length < 2) {
      alert("Debe completar el campo " + type);
      return false;
    }
    return true;
  };

  onSend = () => {
    if (!this.validate("mensaje")) return;
    if (!this.validate("email")) return;

    return this.props.onSend(this.state);
  };

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"> Contacto eFactura</DialogTitle>
          <DialogContent>
            <DialogContentText>Ingrese sus datos y nos pondremos en contacto con usted</DialogContentText>
            <br />

            <TextField
              value={this.state.cedula}
              onChange={this.onChange("mensaje")}
              margin="dense"
              label="Mensaje"
              type="text"
              multiline
              rowsMax="5"
              fullWidth
            />
            <TextField
              value={this.state.email}
              onChange={this.onChange("email")}
              margin="dense"
              label="Correo Electronico"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.onSend} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
