import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#ddd"
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#8884d8"
  }
})(LinearProgress);

const styles = {
  card: {
    width: "100%",
    marginBottom: 12,
    position: "relative"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;

  var metrics = props.metrics;

  var metricMeta = metrics
    .filter(metric => {
      return props.metric.keys.indexOf(metric.key) > -1;
    })
    .map(metric => {
      var sumFields = metric.groupBy.sumFields;
      if (sumFields.indexOf(".") > -1) {
        sumFields = sumFields.split(".")[1];
      }

      metric.value =
        props.values[metric.key] && props.values[metric.key][0] ? props.values[metric.key][0][sumFields] : "";
      return metric;
    });

  props.metric.keys.forEach(key => {
    if (key.indexOf("item.") == 0)
      metricMeta.push({ key: key, type: "ITEM", value: props.item[key.replace("item.", "")] });
  });

  metricMeta.sort((a, b) => {
    if (props.metric.keys.indexOf(a.key) > props.metric.keys.indexOf(b.key)) return 1;
    else return -1;
  });

  var index = 0;
  var coverage = 0;
  try {
    index = parseInt((metricMeta[0].value / metricMeta[1].value) * 10000) / 100;
    coverage = (index / props.metric.goal) * 100;
  } catch (e) {}

  if (coverage > 100) coverage = 100;

  return (
    <Card className={classes.card}>
      {props.metric.goal ? (
        <BorderLinearProgress
          className={classes.margin}
          variant="determinate"
          color="secondary"
          value={coverage}
        />
      ) : null}
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.metric.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {index}%
        </Typography>

        <Typography style={{ fontSize: "0.45rem" }} color="textSecondary" variant="caption">
          {props.metric.help}
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
