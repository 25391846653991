import { onCreateForm } from "../form";

const defaultState = {
  dialogsByTypeAndId: {}
};

/*
metadataType: metadataType,
        operation: operation,
        formId: formId || Math.random(),
        assignToType: assignToType,
        closeOnSuccess: closeOnSuccess
        */
export function showFastCreate(payload, defaults) {
  return function(dispatch, getState) {
    var formId = (payload.parentFormId || "-") + ":" + parseInt(Math.random() * 10000);

    dispatch(onCreateForm({ id: formId, type: payload.metadataType }, defaults || {}));

    dispatch({
      type: "ENQUEUE_DIALOG",
      payload: { ...payload, formId: formId }
    });
  };
}

export const hideFastCreate = payload => ({
  type: "REMOVE_DIALOG",
  payload
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case "ENQUEUE_DIALOG":
      return {
        ...state,
        dialogsByTypeAndId: {
          ...state.dialogsByTypeAndId,
          [`${action.payload.metadataType}-${action.payload.formId}`]: action.payload
        }
      };

    case "REMOVE_DIALOG":
      return {
        ...state,
        dialogsByTypeAndId: {
          ...state.dialogsByTypeAndId,
          [`${action.payload.metadataType}-${action.payload.formId}`]: null
        }
      };

    default:
      return state;
  }
};
