import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const styles = theme => ({});

const mapStateToProps = (store, props) => {
  return {};
};

class FastCreateContainer extends React.Component {
  state = {};

  render() {
    const { classes } = this.props;

    return "opk";
  }
}

FastCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FastCreateContainer));
