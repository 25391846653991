import React, { Fragment } from "react";
import Menu from "@material-ui/core/Menu";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DragIndicator from "@material-ui/icons/DragIndicator";
import TouchApp from "@material-ui/icons/TouchApp";

import Add from "@material-ui/icons/Add";
import SettingsBack from "@material-ui/icons/SettingsBackupRestore";
import TableIcon from "@material-ui/icons/TableChart";

import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Folder from "@material-ui/icons/Folder";
import SelectAll from "@material-ui/icons/SelectAll";
import Info from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneOutline from "@material-ui/icons/DoneOutline";
import RateReview from "@material-ui/icons/RateReview";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";

import ThumbsUp from "@material-ui/icons/ThumbUp";
import Email from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloudDownload from "@material-ui/icons/NetworkCheck";
import Refresh from "@material-ui/icons/Refresh";
import CancelPresentation from "@material-ui/icons/CancelPresentation";

import Badge from "@material-ui/core/Badge";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Hidden from "@material-ui/core/Hidden";
import { Tooltip, Divider } from "@material-ui/core";

export const styles = theme => {
  return {
    root: {
      marginRight: 5,
      marginTop: 6,
      marginBottom: 2
    },
    buttonRoot: {
      position: "absolute",
      top: 60,
      left: 10,
      zIndex: 1000,
      [theme.breakpoints.down("md")]: {
        top: 56
      }
    },
    actions: {
      backgroundColor: "rgba(255,255,255,0.8)"
    },
    actionsClosed: {
      backgroundColor: "inherit"
    },
    button: {
      backgroundColor: theme.palette.secondary[600]
    },
    standardButton: {
      padding: "4px 2px",
      marginRight: 7,
      marginLeft: 7,
      marginTop: 6,
      minWidth: 50,
      marginBottom: 6
    },
    icon: {
      color: "#fff",
      fill: "#fff"
    },
    popper: {
      zIndex: 1999
    },
    menuItem: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& $primary, & $icon": {
          color: theme.palette.common.white
        }
      }
    },
    primary: {},
    icon: {}
  };
};

const ITEM_HEIGHT = 48;

class LongMenu extends React.Component {
  state = {
    anchorEl: null,
    anchorEl1: null,
    anchorEl3: null,
    anchorEl4: null,
    anchorEla: null,
    items: [],
    open: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItems.length > 0) this.setState({ open: true, items: nextProps.selectedItems });
    else this.setState({ open: false, items: nextProps.selectedItems });
  }

  handleClose = name => {
    return () => {
      this.setState({ [name]: null });
    };
  };

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleItemClick = e => {
    var action = JSON.parse(e.currentTarget.dataset.action);

    if (action.type == "modal") {
      this.setState({
        open: false
      });
      this.props.handleModalClick(action);
    } else this.props.handleItemClick(action);
    this.setState({
      open: false
    });
    return false;
  };

  handleModalClick = e => {
    this.props.handleModalClick(e.currentTarget.dataset.id);
  };

  handlePrint = e => {
    this.props.onPrint();
  };

  handlePivot = e => {
    this.props.onPivot();
  };

  handlePrintReport = report => {
    return () => {
      this.props.onPrint(report);
    };
  };

  handleGraph = e => {
    this.props.onGraph();
  };

  handleSelectAllClick = e => {
    this.props.handleSelectAllClick();
  };

  handleRefreshClick = e => {
    this.props.handleRefreshClick();
  };

  handleResetClick = e => {
    this.props.handleResetClick();
  };

  handleCreateClick = e => {
    this.props.handleItemClick({ title: "Crear" });
  };

  handleBatchClick = e => {
    this.props.handleBatchClick();
  };

  handleMenuClick = name => {
    return event => {
      this.setState({ [name]: event.currentTarget });
    };
  };

  handleMenuClose = name => {
    return () => {
      this.setState({ [name]: null });
    };
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClosea = event => {
    if (this.anchorEla.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  renderMoreOptions() {
    const { anchorEla, open } = this.state;

    const { classes } = this.props;

    const iconActions = {
      Aplicar: DoneOutline,
      Aprobar: ThumbsUp,
      Email: Email,
      Imprimir: PrintIcon,
      Borrar: DeleteIcon,
      Esperar: HourglassEmpty,
      Proforma: RateReview,
      Info: Info,
      Reintentar: Refresh,
      Anular: CancelPresentation
    };

    if (!this.props.actions) return null;

    var estados = [];
    this.state.items.forEach(item => {
      if (item && item.estado) {
        var parsedEstado = item.estado.replace("por ", "").toLowerCase();
        if (estados.indexOf(parsedEstado) == -1) estados.push(parsedEstado);
      }
    });

    var finalActions = this.props.actions.filter(action => {
      if (["Crear", "Editar", "Analizar", "Ver"].indexOf(action.title) > -1) return false;

      if (estados.indexOf("aplicar") > -1 && action.title == "Aprobar") return false;
      if (estados.indexOf("aprobar") > -1 && action.title.title == "Aplicar") return false;
      if (estados.indexOf("imprimir") > -1 && (action == "Aprobar" || action.title == "Aplicar"))
        return false;

      if (estados.indexOf("archivado") > -1 && ["Aplicar", "Aprobar", "Borrar"].indexOf(action.title) > -1)
        return false;
      if (estados.indexOf("por archivar") > -1 && ["Aplicar", "Aprobar", "Borrar"].indexOf(action.title) > -1)
        return false;

      return true;
    });
    //if (finalActions.length == 0 && (this.props.modals || []).length == 0) return null;

    var Icon = TouchApp;
    if (this.state.items && this.state.items.length == 1) Icon = MoreVertIcon;
    else if (this.state.items && this.state.items.length > 1) Icon = DragIndicator;

    return (
      <Fragment>
        {this.props.actions.map(option => {
          if (option.title == "Crear") {
            return (
              <Button
                variant="contained"
                style={{ marginRight: 20 }}
                key={"crearButton"}
                className={classes.standardButton}
                color="primary"
                onClick={this.handleCreateClick}>
                <Add />
              </Button>
            );
          }
          return null;
        })}

        <Hidden mdUp>
          <Button
            variant="outlined"
            className={classes.standardButton}
            buttonRef={node => {
              this.anchorEla = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            color="primary">
            <Icon />
          </Button>
          <Popper
            className={classes.popper}
            placement="right-start"
            open={open}
            anchorEl={this.anchorEla}
            transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === "bottom" ? "right top" : "right top" }}>
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      {finalActions.map((action, index) => {
                        var ButtonIcon = iconActions[action.icon] || Info;

                        return (
                          <MenuItem
                            key={action.title || index}
                            onClick={this.handleItemClick}
                            data-action={JSON.stringify(action)}>
                            <ListItemIcon className={classes.icon}>
                              <ButtonIcon />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.primary }}
                              inset
                              primary={action.title}
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Hidden>

        <Hidden smDown>
          {finalActions.map((action, index) => {
            var ButtonIcon = iconActions[action.icon] || Info;

            return (
              <Tooltip title={action.title}>
                <Button
                  key={action.title || index}
                  onClick={this.handleItemClick}
                  data-action={JSON.stringify(action)}
                  variant="outlined"
                  className={classes.standardButton}
                  color="primary">
                  <ButtonIcon />
                </Button>
              </Tooltip>
            );
          })}
        </Hidden>
      </Fragment>
    );
  }

  render() {
    const { anchorEl, anchorEl1, anchorEl3, anchorEl4 } = this.state;
    const { classes } = this.props;
    const Icon = this.props.icon || MoreVertIcon;
    let menuHeight = ITEM_HEIGHT * this.props.actions.length;
    if (menuHeight > ITEM_HEIGHT * 7) menuHeight = ITEM_HEIGHT * 6;
    var rows = this.props.dataRows || [];
    return (
      <Grid item>
        <Grid direction="row" justify="space-between" container>
          <Grid item>
            <Grid container>
              <Grid item>{this.renderMoreOptions()}</Grid>
              <Grid item>
                <Grid
                  style={{
                    marginRight: 20,
                    marginLeft: 10,
                    paddingLeft: 10,
                    marginTop: 13,
                    borderLeft: "1px solid #ddd"
                  }}
                  item>
                  {!this.props.limit ? (
                    <Badge
                      classes={{ badge: classes.badge }}
                      max="99999"
                      badgeContent={rows.length}
                      color="primary">
                      <CloudDownload className={classes.badgeButton} fontSize="small" />
                    </Badge>
                  ) : (
                    <IconButton
                      onClick={this.props.handleLimitChange}
                      color="secondary"
                      className={classes.warningButton}>
                      <Badge
                        classes={{ badge: classes.badge }}
                        max="99999"
                        badgeContent={rows.length + "" + this.props.metadata.count}
                        color="primary">
                        <CloudDownload className={classes.badgeButton} fontSize="small" />
                      </Badge>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Hidden mdDown>
              <Button
                variant="outlined"
                className={classes.standardButton}
                color="default"
                aria-label="More"
                aria-owns={anchorEl4 ? "long-menu4" : null}
                aria-haspopup="true"
                onClick={this.handleSelectAllClick}>
              <SelectAll />
              </Button>
              <Button
                variant="outlined"
                className={classes.standardButton}
                color="default"
                aria-label="More"
                aria-owns={anchorEl4 ? "long-menu4" : null}
                aria-haspopup="true"
                onClick={this.handleMenuClick("anchorEl4")}>
                <Folder />
              </Button>
              <Menu
                id="long-menu4"
                anchorEl={anchorEl4}
                open={Boolean(anchorEl4)}
                onClose={this.handleMenuClose("anchorEl4")}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 8,
                    width: 400
                  }
                }}>
                <MenuItem onClick={this.handlePrint}>Nuevo Reporte</MenuItem>
                <MenuItem onClick={this.handlePivot}>Nuevo Análisis</MenuItem>

                {this.props.reports.map(item => {
                  return <MenuItem onClick={this.handlePrintReport(item)}>{item.name}</MenuItem>;
                })}
              </Menu>
            </Hidden>

            <Button
              variant="outlined"
              className={classes.standardButton}
              color="default"
              aria-label="Select All"
              aria-owns={anchorEl1 ? "long-menu1" : null}
              aria-haspopup="true"
              onClick={this.handleMenuClick("anchorEl1")}>
              <SettingsBack />
            </Button>
            <Menu
              variant="outlined"
              id="long-menu1"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={this.handleMenuClose("anchorEl1")}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 3,
                  width: 200
                }
              }}>
              <MenuItem onClick={this.handleRefreshClick}>Recargar</MenuItem>
              <MenuItem onClick={this.handleResetClick}>Reset Filtros</MenuItem>

              {this.props.batch ? <MenuItem onClick={this.handleBatchClick}>Batch</MenuItem> : null}
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LongMenu);
