import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";

import { connect } from "react-redux";
import SmartForm from "components/SmartForm/Form";

import Checkbox from "@material-ui/core/Checkbox";

import moment from "moment";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from "@material-ui/core/AppBar";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Hidden from "@material-ui/core/Hidden";
import { throttle, debounce } from "throttle-debounce";
import { Switch, Route, Redirect } from "react-router-dom";

// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import {
  onCreateForm,
  onFieldChange,
  onUpdateField,
  onUpdateItem,
  selectFormById
} from "redux/reducers/form";
import {
  onLoadData,
  onLoadDataById,
  selectDataByType,
  selectDataByTypeAndId,
  onSave,
  onAction
} from "redux/reducers/data";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import CircularProgress from "@material-ui/core/CircularProgress";
import CustomInput from "components/CustomInput/CustomInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CardText from "components/Card/CardText.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import Tooltip from "@material-ui/core/Tooltip";

import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import grey from "@material-ui/core/colors/grey";

import Form from "./form";

export const styles = theme => ({
  root: {},
  main: {
    margin: theme.spacing(6),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),

    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      margin: 0
    }
  },
  toolbar: {
    margin: 0,
    marginBottom: 0,
    position: "fixed",
    left: 0,
    width: "95%",
    zIndex: theme.zIndex.drawer + 2,
    top: -15,
    padding: 0
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary[800],
    border: "1px solid #fff"
  },
  icon: {
    color: "#fff"
  },
  dialog: {
    backgroundColor: grey[100]
  },
  title: {
    top: -5,
    left: 85,
    position: "relative",
    color: "#fff"
  }
});

export function makeEdit(transformOnChange, typeMap) {
  var Edit = class Any extends BaseEdit {
    _componentDidMount() {
      if (!this.props.form)
        this.props.dispatch(
          onCreateForm({
            id: this.props.formId,
            type: this.props.metadataType,
            transformOnChange: transformOnChange
          })
        );

      const itemLoaded = this.props.dataItem != null;
      const metadataLoaded = this.props.metadata != null;

      //Metadata and item are loaded let's display and reload the item
      if (itemLoaded) {
        this.props.dispatch(onUpdateItem(this.props.formId, this.props.dataItem, this.props.metadata));
      }

      this.props.dispatch(
        onLoadMetaData(this.props.metadataType, null, { id: this.props.id, source: "edit" })
      );
    }

    getTypeMap() {
      return typeMap || {};
    }
  };

  const mapStateToProps = (state, ownProps) => {
    return {
      form: selectFormById(state, ownProps.formId),
      metadata: selectMetadataByType(state, ownProps.metadataType),
      dataItem: selectDataByTypeAndId(state, ownProps.metadataType, ownProps.id)
    };
  };

  const ConnectedEdit = connect(mapStateToProps)(Edit);

  return withStyles(styles, { withTheme: true })(ConnectedEdit);
}

class BaseEdit extends Form {
  constructor() {
    super();
    this.state = {
      dialogOpen: false,
      loadingMetadata: false
    };
  }

  _componentDidMount() {
    if (!this.props.form)
      this.props.dispatch(onCreateForm({ id: this.props.formId, type: this.props.metadataType }));

    const itemLoaded = this.props.dataItem != null;
    const metadataLoaded = this.props.metadata != null;

    //Metadata and item are loaded let's display and reload the item
    if (itemLoaded) {
      this.props.dispatch(onUpdateItem(this.props.formId, this.props.dataItem, this.props.metadata));
    }

    this.props.dispatch(onLoadMetaData(this.props.metadataType, null, { id: this.props.id, source: "edit" }));
  }

  componentDidMount() {
    return this._componentDidMount();
  }

  _componentWillReceiveProps(nextProps) {
    if (!this.props.metadata && nextProps.metadata) {
      this.props.dispatch(onUpdateItem(this.props.formId, this.props.dataItem, this.props.metadata));
    }

    //this.props.dispatch(onLoadMetaData(nextProps.metadataType, null, { id: nextProps.id, source: "edit" }));

    if (nextProps.dataItem && nextProps.dataItem.id && this.props.dataItem !== nextProps.dataItem) {
      this.props.dispatch(onUpdateItem(nextProps.formId, nextProps.dataItem, this.props.metadata));
    }
  }

  componentWillReceiveProps(nextProps) {
    return this._componentWillReceiveProps(nextProps);
  }

  onSave = item => {
    var itemDelta = { id: this.props.form.item.id, updatedAt: this.props.form.item.updatedAt };

    this.props.form.updatedKeys.forEach(key => {
      itemDelta[key] = this.props.form.item[key];
    });

    return this.props
      .dispatch(onSave(this.props.metadataType, itemDelta))
      .then(result => {
        if (this.props.history)
          this.props.history.push(`/${this.props.metadata.namespace}/${this.props.metadataType}`);
        else return this.props.onClose(result);
        return result;
      })
      .catch(function(e) {
        console.log(e);
      });
  };

  renderModal() {
    const { classes } = this.props;
    if (!this.state.dialogOpen) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth="md"
        open={this.state.dialogOpen}
        onClose={this.onCloseModal}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <div>{this[`render${this.state.dialogName}Modal`]()}</div>
        </DialogContent>
      </Dialog>
    );
  }

  renderForm() {
    if (!this.props.form || !this.props.metadata) return null;

    return (
      <Grid container>
        <Grid item xs={12}>
          <SmartForm
            typeMap={this.getTypeMap()}
            onFieldChange={this.onFieldChange}
            form={this.props.form || {}}
            metadata={this.props.metadata || {}}
            dataItem={this.props.dataItem}
            onSave={this.onSave}
            onBack={this.onBack}
            formId={this.props.formId}
            metadataType={this.props.metadataType}
            id={this.props.id}
            showCreateModal={this.showCreateModal}
            onFastCreate={this.onFastCreate}
            onShowModal={this.onShowModal}
            onGoBack={this.props.onGoBack}
          />
        </Grid>
      </Grid>
    );
  }
}

export default BaseEdit;
