import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange, onArrayFieldChange } from "redux/reducers/form";

const onPagoCxPFieldChange = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };

  if (name == "lineaPagoCxP") {
    var monto = value.reduce((total, lineaPago) => total + lineaPago.monto, 0);
    delta.monto = monto;
  }
  return delta;
};

export default onPagoCxPFieldChange;
