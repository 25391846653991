import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";

class ClaveFormatter extends React.Component {

  render() {
    var index = 0;
    if (!this.props.value || this.props.value.length < 49) return this.props.value;

    var value = this.props.value.substring(50 - 19, 50 - 9);

    var lastChar = value[0];
    value.split("").forEach((char, loopIndex) => {
      if (char != "0" && lastChar == "0" && index == 0) index = loopIndex;
    })


    return (
      "..." + value.substring(index)
    )
  }
}

export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <ClaveFormatter handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}