import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";

const onBoletaFieldChange = (form, name, value, extra) => {
  var delta = { ...form.item, [name]: value };

  if (name == "tipo" && form.item.tipo)
    return { _errorInTransform: true, message: "no se puede cambiar de tipo" };

  return delta;
};

export default onBoletaFieldChange;
